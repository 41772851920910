import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Componenes CFDI's.
import { TitleGuard } from './Titles';

//Asset.
import pageGuard from './assets/img/guard.jpg';
import integrityGuard from './assets/icons/integrity-logo.svg';
import myCompanyGuard from './assets/icons/myCompany.svg';
import arrowIcon from './assets/icons/arrow-right.svg';

const CardGuard = ({ img, width, height, title, description, to }) => {
  return (
    <div className="services">
      <img src={img} alt="logo" width={width} height={height} className="img-fluid logo" />
      <div className="card">
        <h5 className="card__title">{title}</h5>
        <p>{description}</p>
        <a href={to} className="keep-reading">
          <FormattedMessage id="cfdis.guard-more" defaultMessage="Conocer más" />
          <img src={arrowIcon} alt="Arrow right Icon" className="icon" />
        </a>
      </div>
    </div>
  );
};
const Guard = () => {
  return (
    <section className="cfdis_guard">
      <Container>
        <TitleGuard />
        <img src={pageGuard} alt="" width={1080} height={600} className="img-fluid showcase" />
        <div className="cards-wrapper">
          <CardGuard
            img={integrityGuard}
            width={138}
            height={26}
            title={<FormattedMessage id="cfdis.guard-vault" defaultMessage="Bóveda Fiscal" />}
            description={
              <FormattedMessage
                id="cfdis.guard-vault-description"
                defaultMessage="A través de nuestra aplicación iNTEGRITY, su equipo podrá gestionar de manera fácil y segura los CFDI’s de nómina de sus empleados…"
              />
            }
            to={'/integrity'}
          />

          <CardGuard
            img={myCompanyGuard}
            width={138}
            height={28}
            title={<FormattedMessage id="cfdis.guard-payroll" defaultMessage="Mis Recibos de Nómina" />}
            description={
              <FormattedMessage
                id="cfdis.guard-payroll-description"
                defaultMessage="A través de nuestra aplicación de auto-servicio sus empleados podrán consultar todo sus recibos de nómina, así como información derivaba de su relación laboral…"
              />
            }
            to={'/my-company'}
          />
        </div>
      </Container>
    </section>
  );
};
export default Guard;
