// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';
// Components Base.
import { Footer, Contact } from '../components-base';

// Components Dicoma.
import Hero from './Hero';
import Welcome from './Welcome';
import Integration from './Integration';
import Rules from './Rules';
import Usability from './Usability';
import Users from './Users';

const PageIntegrity = () => {
  return (
    <>
      <Hero />
      <Welcome />
      <Integration />
      <Rules />
      <Usability />
      <Users />
      <Contact
        title={
          <FormattedMessage
            id="integrity.contact"
            defaultMessage="¿Desea proveer a su equipo con la mejor herramienta de gestión para cálculo de nóminas?"
          />
        }
      />
      <Footer />
    </>
  );
};

export default PageIntegrity;
