import React from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
//Asset.
import personalization from './assets/icons/personalization.svg';
import dispersion from './assets/icons/dispersion.svg';
import guard from './assets/icons/guard.svg';
const CardCfdis = ({ icon, title, description, to }) => {
  return (
    <Card>
      <img src={icon} alt="icon personalization" width={100} height={100} />
      <h3>{title}</h3>
      <p>{description}</p>
      <a className="fw-bold" href={to}>
        <FormattedMessage id="cfdis.bpo-more" defaultMessage="Leer Más" />
      </a>
    </Card>
  );
};
const Bpo = () => {
  return (
    <section className="cfdis_bpo">
      <Container>
        <Row>
          <p>
            <FormattedMessage
              id="cfdis.bpo"
              defaultMessage="Brindamos una solución integral para la gestión de los recibos de nómina de las empresas. Encargándonos desde la concepción misma del recibo, el timbrado, envío vía e-mail y resguardo de los cfdi’s. Dado que es compatible, pero independiente al proceso de la nómina, podemos ofrecer este servicio de forma individual."
            />
          </p>
        </Row>
        <Row className="gap-5">
          <Col>
            <CardCfdis
              icon={personalization}
              title={<FormattedMessage id="cfdis.bpo-personalization" defaultMessage="PERSONALIZACIÓN" />}
              to="#personalization"
              description={
                <FormattedMessage
                  id="cfdis.bpo-personalization-description"
                  defaultMessage="Podemos diseñar el recibo de nóminas ideal, con la información y elementos que se adapten a las necesidades específicas de su organización. Esto con el objetivo de…"
                />
              }
            />
          </Col>
          <Col>
            <CardCfdis
              icon={dispersion}
              title={<FormattedMessage id="cfdis.bpo-dispersion" defaultMessage="DISPERSIÓN" />}
              to="#dispersion"
              description={
                <FormattedMessage
                  id="cfdis.bpo-dispersion-description"
                  defaultMessage="Una vez que el cálculo de la nómina es correcto y que esta se autoriza y se paga a los empleados, se lleva a cabo el proceso de certificación (timbrado) y generación…"
                />
              }
            />
          </Col>
          <Col>
            <CardCfdis
              icon={guard}
              title={<FormattedMessage id="cfdis.bpo-guard" defaultMessage="RESGUARDO" />}
              to="#guard"
              description={
                <FormattedMessage
                  id="cfdis.bpo-guard-description"
                  defaultMessage="Almacenamos todos los CFDI’s generados nómina tras nómina en nuestra bóveda fiscal, a la cual tienen acceso los integrantes de su área de RRHH o nóminas y en la…"
                />
              }
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Bpo;
