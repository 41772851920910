// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Navbar } from '../components-base';

// Assets.
import { IconSLoopSvg, IconHandSvg, IconKeySvg, IconGearSvg, IconForkSvg } from './Assets';

const CardService = ({ icon, title, description, to }) => {
  return (
    <div className="card">
      <a href={to} className="link">
        <div className="card__body">
          {icon}
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </a>
    </div>
  );
};

const Hero = () => {
  return (
    <header className="home_hero position-relative">
      <div className="home_hero__bg"></div>
      <Navbar />
      <Container>
        <div className="text-center">
          <div className="d-flex flex-column align-items-center">
            <h1>
              <FormattedMessage id="home.hero-title" defaultMessage="Maquila de Nómina de Alto Rendimiento" />
            </h1>
            <h2>
              <FormattedMessage id="home.hero-subtitle" defaultMessage="Para compañías globales" />
              <span>
                <FormattedMessage id="home.hero-subtitle-bold" defaultMessage="en México" />
              </span>
            </h2>
          </div>
        </div>
        <div className="cards-wrapper">
          <CardService
            title={<FormattedMessage id="home.hero-card-payroll-title" defaultMessage="Maquila de Nómina" />}
            description={
              <FormattedMessage
                id="home.hero-card-payroll-description"
                defaultMessage="Procesamiento de nóminas de Alto Rendimiento"
              />
            }
            icon={<IconSLoopSvg />}
            to={'./payroll-maquila'}
          />
          <CardService
            title={<FormattedMessage id="home.hero-card-receipt-title" defaultMessage="Recibo de Nómina" />}
            description={
              <FormattedMessage
                id="home.hero-card-receipt-description"
                defaultMessage="Solución integral con múltiple valor agregado"
              />
            }
            icon={<IconHandSvg />}
            to={'./cfdis'}
          />
          <CardService
            title={<FormattedMessage id="home.hero-card-mycompany-title" defaultMessage="My[Company]®" />}
            description={
              <FormattedMessage
                id="home.hero-card-mycompany-description"
                defaultMessage="Atención personalizada a todos los empleados"
              />
            }
            icon={<IconKeySvg />}
            to={'./my-company'}
          />
          <CardService
            title={<FormattedMessage id="home.hero-card-dicoma-title" defaultMessage="DICOMA®" />}
            description={
              <FormattedMessage
                id="home.hero-card-dicoma-description"
                defaultMessage="Registro de consumos en el comedor de empleados"
              />
            }
            icon={<IconForkSvg />}
            to={'./dicoma'}
          />
          <CardService
            title={<FormattedMessage id="home.hero-card-software-title" defaultMessage="Software a la Medida" />}
            description={
              <FormattedMessage
                id="home.hero-card-software-description"
                defaultMessage="Automatización de procesos administrativos"
              />
            }
            icon={<IconGearSvg />}
            to={'./software'}
          />
        </div>
      </Container>
    </header>
  );
};

export default Hero;
