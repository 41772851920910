// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { GradientBg, ReturnToServices } from '../components-base';

// Components Payrroll.
import { TitleCalculation } from './Titles';

// Assets.
import { Check2Svg, CalculationImg, CsvSvg, PdfSvg, TxtSvg, XlsSvg } from './assets';

// const CardBasicReports = ({
//   icon1,
//   icon2,
//   title,
//   description,
//   to = "/",
//   children,
// }) => {
//   return (
//     <div className="card">
//       <div className="card__title">{title}</div>
//       <div className="card__description">{description}</div>
//       {children}
//       <div className="card__icons">
//         {icon1}
//         {icon2}
//       </div>
//     </div>
//   );
// };

const Calculation = () => {
  return (
    <section className="payroll_calculation">
      <GradientBg className="gradient">
        <Container>
          <TitleCalculation />
          <div className="results-validation margin">
            <div className="results-validation__text">
              <p className="results-validation__text--title">
                <Check2Svg />
                <span>
                  <FormattedMessage id="payroll.calculation-resultsvalidation" defaultMessage="Validación de Resultados" />
                </span>
              </p>
              <p className="results-validation__text--description">
                <FormattedMessage
                  id="payroll.calculation-resultsvalidation-description"
                  defaultMessage="Para evitar omisiones y asegurar su precisión, los resultados del procesamiento son validados y confirmados por ambos lados del equipo (nomi-cliente). Para ello, después de cada cálculo, el sistema genera y envía, de manera automática, una serie de *reportes básicos en formatos libremente manipulables por el usuario…"
                />
              </p>
              <p className="results-validation__text--asterisk">
                <FormattedMessage
                  id="payroll.calculation-resultsvalidation-asterisk"
                  defaultMessage="*Cualquier otro reporte específico, de acuerdo con las necesidades del cliente, puede definirse en la fase de implementación."
                />
              </p>
            </div>

            <div className="results-validation__img">
              <CalculationImg />
            </div>
          </div>

          <div className="basic-reports margin">
            <div className="margin">
              <FormattedMessage id="payroll.calculation-basicreports" defaultMessage="Reportes Basicos: " />
            </div>

            <div className="cards">
              <div className="card uno">
                <div className="card__title">
                  <FormattedMessage id="payroll.calculation-payrollresult" defaultMessage="1. Resultado de Nómina" />
                </div>
                <div className="card__description">
                  <FormattedMessage
                    id="payroll.calculation-payrollresult-description"
                    defaultMessage="Este reporte muestra el resultado del cálculo de los ingresos brutos y netos, con un desglose del período de percepciones y deducciones por empleado."
                  />
                </div>
                <div className="card__icons">
                  <XlsSvg />
                </div>
              </div>

              <div className="card dos">
                <div className="card__title">
                  <FormattedMessage id="payroll.calculation-payrolltab" defaultMessage="2. Tabular de Nómina" />
                </div>
                <div className="card__description">
                  <FormattedMessage
                    id="payroll.calculation-payrolltab-description"
                    defaultMessage="Es el mismo reporte que el primero (Resultados de Nómina) pero muestra la información en forma tabular, proporcionando una vista más detallada de cada concepto (deducciones y percepciones) por empleado."
                  />
                </div>
                <div className="card__icons">
                  <XlsSvg />
                </div>
              </div>

              <div className="card tres">
                <div className="card__title">
                  <FormattedMessage id="payroll.calculation-payrollnumbers" defaultMessage="3. Cifras de Nómina" />
                </div>
                <div className="card__description">
                  <FormattedMessage
                    id="payroll.calculation-payrollnumbers-description"
                    defaultMessage="Es un resumen con los importes totales de los conceptos del periodo, tanto de las deducciones como de las percepciones, que proporciona una idea rápida y general de cómo se integra el importe total de la nómina."
                  />
                </div>
                <div className="card__icons">
                  <PdfSvg />
                </div>
              </div>

              <div className="card cuatro">
                <div className="card__title">
                  <FormattedMessage id="payroll.calculation-payrolldetail" defaultMessage="4. Detalle de Nómina" />
                </div>
                <div className="card__description">
                  <FormattedMessage
                    id="payroll.calculation-payrolldetail-description"
                    defaultMessage="Un informe que proporciona una vista detallada de las percepciones y deducciones de cada empleado, tal y como aparecen en su nómina, a efecto de cumplir con los requisitos fiscales establecidos por la ley."
                  />
                </div>
                <div className="card__icons">
                  <PdfSvg />
                </div>
              </div>

              <div className="card cinco">
                <div className="card__title">
                  <FormattedMessage id="payroll.calculation-employeesnet" defaultMessage="5. Empleados Neto 0.00" />
                </div>
                <div className="card__description">
                  <FormattedMessage
                    id="payroll.calculation-employeesnet-description"
                    defaultMessage="Una lista de empleados que, por diferentes motivos como: bajas por enfermedad, permisos sin goce de sueldo o ausencias, no causaron ninguna percepción en ese periodo."
                  />
                </div>
                <div className="card__icons">
                  <XlsSvg />
                </div>
              </div>

              <div className="card transferencia">
                <div className="card__title">
                  <FormattedMessage id="payroll.calculation-datatransfer" defaultMessage="Archivos de Transferencia" />
                </div>
                <div className="card__description">
                  <div>
                    <FormattedMessage id="payroll.calculation-datatransfer-a" defaultMessage="A) Bancos (dispersión de nómina)" />
                  </div>
                  <div>
                    <FormattedMessage id="payroll.calculation-datatransfer-b" defaultMessage="B) Vales de Despensa" />
                  </div>
                  <div>
                    <FormattedMessage id="payroll.calculation-datatransfer-c" defaultMessage="C) Contabilidad" />
                  </div>
                  <div>
                    <div>
                      <FormattedMessage id="payroll.calculation-datatransfer-d" defaultMessage="D) Fondo de Ahorro" />
                    </div>
                    <li>
                      <FormattedMessage id="payroll.calculation-datatransfer-d-p1" defaultMessage="- Aportaciones" />
                    </li>
                    <li>
                      <FormattedMessage id="payroll.calculation-datatransfer-d-p2" defaultMessage="- Pagos de Prestamos" />
                    </li>
                  </div>
                  <div>
                    <FormattedMessage id="payroll.calculation-datatransfer-e" defaultMessage="E) Otras" />
                  </div>
                </div>
                <div className="card__icons">
                  <TxtSvg />
                  <XlsSvg />
                </div>
              </div>

              <div className="card timbrado">
                <div className="card__title">
                  <FormattedMessage id="payroll.calculation-ding" defaultMessage="Timbrado (CFDI’s)" />
                </div>
                <div className="card__description">
                  <p>
                    <FormattedMessage
                      id="payroll.calculation-ding-p1"
                      defaultMessage="Se envían los archivos de acuerdo al formato del pac del cliente."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="payroll.calculation-ding-p2"
                      defaultMessage="Se recibe info del pac y se hace confronta inmediata con info timbrada y en la base de datos."
                    />
                  </p>
                  <p>
                    <FormattedMessage id="payroll.calculation-ding-p3" defaultMessage="-Validación de CFDI’s vs. SAT" />
                  </p>
                </div>
                <div className="card__icons">
                  <CsvSvg />
                </div>
              </div>
            </div>
          </div>
          <ReturnToServices />
        </Container>
      </GradientBg>
    </section>
  );
};
export default Calculation;
