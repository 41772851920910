// Librerias.
import React, { useState, useContext } from 'react';
import { Container, Nav, Navbar, NavDropdown, Modal, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Assets.
import { LogoWhiteSvg, LogoDarkSvg, ArrowRotate } from './Assets';

// Context.
import { langContext } from './../context/langContext';

const ModalNavbar = ({ show, setShow, fullscreen }) => {
  const [openServices, setOpenServices] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openLang, setOpenLang] = useState(false);

  const lang = useContext(langContext);
  
  const language = localStorage.getItem('lang');
  const showContactForm = language === 'es-MX' ? '/contact' : '/contact/sales';

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
      <Container>
        <Modal.Header closeButton closeVariant={'white'}></Modal.Header>
      </Container>

      <Modal.Body className="modal-links">
        <p>
          <Container>
            <a href="/">
              <FormattedMessage id="nav.home" defaultMessage="Inicio" />
            </a>
          </Container>
        </p>
        <p>
          <Container>
            <a href="/the-company">
              <FormattedMessage id="nav.the-company" defaultMessage="La Empresa" />
            </a>
          </Container>
        </p>
        <p>
          <Container>
            <a href="/approach">
              <FormattedMessage id="nav.approach" defaultMessage="Nuestro Approach" />
            </a>
          </Container>
        </p>
        <p>
          <Container>
            <div
              onClick={() => setOpenServices(!openServices)}
              aria-controls="example-collapse-text"
              aria-expanded={openServices}
              className="d-flex dropdown"
            >
              <FormattedMessage id="nav.services" defaultMessage="Servicios" />
            </div>
            <Collapse in={openServices}>
              <div id="example-collapse-text">
                <p>
                  <a class="dropdown-item" href="./payroll">
                    <FormattedMessage id="nav.payroll" defaultMessage="Maquila de Nómina" />
                  </a>
                </p>
                <p>
                  <a class="dropdown-item" href="./cfdis">
                    <FormattedMessage id="nav.cfdis" defaultMessage="Gestión de CFDI's" />
                  </a>
                </p>
                <p>
                  <a class="dropdown-item" href="./software">
                    <FormattedMessage id="nav.software" defaultMessage="Desarrollo de Software" />
                  </a>
                </p>
              </div>
            </Collapse>
          </Container>
        </p>
        <p>
          <Container>
            <div
              onClick={() => setOpenProducts(!openProducts)}
              aria-controls="example-collapse-text"
              aria-expanded={openProducts}
              className="d-flex dropdown"
            >
              <FormattedMessage id="nav.products" defaultMessage="Productos" />
            </div>
            <Collapse in={openProducts}>
              <div id="example-collapse-text">
                <p>
                  <a class="dropdown-item" href="./integrity">
                    iNTEGRITY®
                  </a>
                </p>
                <p>
                  <a class="dropdown-item" href="./my-company">
                    My [Company]®
                  </a>
                </p>
                <p>
                  <a class="dropdown-item" href="./dicoma">
                    DICOMA®
                  </a>
                </p>
              </div>
            </Collapse>
          </Container>
        </p>
        <p>
          <Container>
            <a href={showContactForm}>
              <FormattedMessage id="nav.contact" defaultMessage="Contacto" />
            </a>
          </Container>
        </p>
        <p>
          <Container>
            <a href="https://integrity.nomi.mx/" target="_blank" rel="noreferrer">
              Login
            </a>
          </Container>
        </p>
        <p>
          <Container>
            <div
              onClick={() => setOpenLang(!openLang)}
              aria-controls="example-collapse-text"
              aria-expanded={openLang}
              className="d-flex"
            >
              <ArrowRotate active={openLang} />
            </div>
            <Collapse in={openLang}>
              <div id="example-collapse-text">
                <p>
                  <a class="dropdown-item" href="./" onClick={() => lang.setLanguage('en-US')}>
                    English
                  </a>
                </p>
                <p>
                  <a class="dropdown-item" href="./" onClick={() => lang.setLanguage('es-MX')}>
                    Español
                  </a>
                </p>
                <p>
                  <a class="dropdown-item" href="./" onClick={() => lang.setLanguage('de-DE')}>
                    Deutsche
                  </a>
                </p>
                <p>
                  <a class="dropdown-item" href="./" onClick={() => lang.setLanguage('ja-JP')}>
                    日本語
                  </a>
                </p>
              </div>
            </Collapse>
          </Container>
        </p>
      </Modal.Body>
    </Modal>
  );
};

const NavbarNomi = ({ typeNav = 'white' }) => {
  const showNav = typeNav === 'dark' ? true : false;
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  const values = [true];

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const lang = useContext(langContext);
  const language = localStorage.getItem('lang');
  const showContactForm = language === 'es-MX' ? '/contact' : '/contact/sales';

  return (
    <Navbar collapseOnSelect expand="lg" className="py-0 navbar_nomi" variant="dark">
      <Container>
        <div className="my-0 logo">
          {showNav ? (
            <>{show ? <LogoWhiteSvg classAnchor="p-0" /> : <LogoDarkSvg classAnchor="p-0" />}</>
          ) : (
            <LogoWhiteSvg classAnchor="p-0" />
          )}
        </div>

        {!show &&
          values.map((v, idx) => (
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className={`me-2 ${showNav && 'nav-dark'}`}
              key={idx}
              onClick={() => handleShow(v)}
            ></Navbar.Toggle>
          ))}
        <ModalNavbar show={show} setShow={setShow} fullscreen={fullscreen} />

        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end align-items-center">
          <Nav className="jcs">
            <Link to="/the-company" className={`nav-link ${showNav && 'nav-dark'}`}>
              <FormattedMessage id="nav.the-company" defaultMessage="La Empresa" />
            </Link>
            <Link to="/approach" className={`nav-link ${showNav && 'nav-dark'}`}>
              <FormattedMessage id="nav.approach" defaultMessage="Nuestro Approach" />
            </Link>

            <NavDropdown
              title={<FormattedMessage id="nav.services" defaultMessage="Servicios" />}
              id="collasible-nav-dropdown"
              className={`mt-0 dropdown1 ${showNav && 'nav-dark'}`}
              renderMenuOnMount={true}
            >
              <NavDropdown.Item href="/payroll">
                <FormattedMessage id="nav.payroll" defaultMessage="Maquila de Nómina" />
              </NavDropdown.Item>
              <NavDropdown.Item href="/cfdis">
                <FormattedMessage id="nav.cfdis" defaultMessage="Gestión de CFDI's" />
              </NavDropdown.Item>
              <NavDropdown.Item href="/software">
                <FormattedMessage id="nav.software" defaultMessage="Desarrollo de Software" />
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={<FormattedMessage id="nav.products" defaultMessage="Productos" />}
              id="collasible-nav-dropdown"
              className={`mt-0 dropdown2 ${showNav && 'nav-dark'}`}
              renderMenuOnMount={true}
            >
              <NavDropdown.Item href="/integrity">
                <FormattedMessage id="nav.integrity" defaultMessage="iNTEGRITY®" />
              </NavDropdown.Item>
              <NavDropdown.Item href="/my-company">
                My<span className="fw-bold"> [Company]</span>®
              </NavDropdown.Item>
              <NavDropdown.Item href="/dicoma">
                <FormattedMessage id="nav.dicoma" defaultMessage="DICOMA®" />
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href={showContactForm} className={`nav-link ${showNav && 'nav-dark'}`}>
              <FormattedMessage id="nav.contact" defaultMessage="Contacto" />
            </Nav.Link>
            <div>
              <a
                href="https://integrity.nomi.mx/"
                target="_blank"
                rel="noreferrer"
                className={`nav-btn ${showNav && 'nav-dark'}`}
              >
                Login
              </a>
            </div>

            <div className="d-flex nav-lang dropdown">
              <ArrowRotate classType="icon_rotate_hover" showNav={showNav} />
              <div class="dropdown-lang">
                <div class="dropdown-links">
                  <div class="dropdown-item" onClick={() => lang.setLanguage('en-US')}>
                    English
                  </div>
                  <div class="dropdown-item" onClick={() => lang.setLanguage('es-MX')}>
                    Español
                  </div>
                  <div class="dropdown-item" onClick={() => lang.setLanguage('de-DE')}>
                    Deutsche
                  </div>
                  <div class="dropdown-item" onClick={() => lang.setLanguage('ja-JP')}>
                    日本語
                  </div>
                </div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarNomi;
