// Librerias.
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import handImg from './assets/img/hand.png';

// Hooks.
import useWindow from '../hooks/useWindow';
const MyCompany = () => {
  const windowSize = useWindow();

  return (
    <section className="payroll_my_company position-relative">
      <Container>
        <img src={handImg} alt="my-company" width={840} height={770} className="hand img-fluid" />
        {windowSize.width < 600 && (
          <h2 className="kiosko-title">
            <FormattedMessage id="payroll.mycompany" defaultMessage="Kiosko de Atención a Empleados" />
          </h2>
        )}
        <Row>
          <Col lg={{ span: 6, offset: 6 }} className="col-my-company">
            {windowSize.width > 600 && (
              <h2 className="mb-4">
                <FormattedMessage id="payroll.mycompany" defaultMessage="Kiosko de Atención a Empleados" />
              </h2>
            )}
            <p className="description">
              <FormattedMessage
                id="payroll.mycompany-p1"
                defaultMessage="Ponemos toda la información de tus empleados, literalmente, en la palma de sus manos…"
              />
            </p>
            <p className="description">
              <FormattedMessage
                id="payroll.mycompany-p2"
                defaultMessage="Podrán acceder, de forma segura, en línea desde cualquier dispositivo (móvil o de escritorio) para revisar y/o gestionar todos los datos y asuntos relacionados con su nómina."
              />
            </p>
            <div className="my-company mt-4">
              <p>
                <FormattedMessage id="payroll.mycompany-logo" defaultMessage="My " />
                <span className="fw-bold">
                  <FormattedMessage id="payroll.mycompany-logo-bold" defaultMessage="[Company]" />
                </span>
              </p>
              <p>
                <span className="text-white">
                  <FormattedMessage
                    id="payroll.mycompany-info"
                    defaultMessage="Para más información sobre nuestra app de atención personalizada a empleados: "
                  />
                </span>
                <br />
                <a href="/my-company" className="fw-bold">
                  <FormattedMessage id="payroll.mycompany-link" defaultMessage="PULSE AQUÍ" />
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default MyCompany;
