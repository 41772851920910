// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { GradientBg, ReturnToServices } from '../components-base';

// Components Payrroll.
import { TitleSpecialProcesses } from './Titles';

// Assets.
import { AccountingSvg, LogoImssSvg, LogoSatSvg, SettlementsPayrollSvg } from './assets';
import accountingBgImg from './assets/special-processes/accounting-bg.png';
import imssBgImg from './assets/special-processes/imss-bg.png';
import satBgImg from './assets/special-processes/sat-bg.png';
import settlementsBgImg from './assets/special-processes/settlements-bg.png';

const BgCard = styled.div`
  background: #521798 no-repeat left center url(${(props) => (props.bgCard ? props.bgCard : props.bgCard)});
`;

const CardSpecialProcesses = ({ icon, title, children, bgCard }) => {
  return (
    <div className="card">
      <BgCard bgCard={bgCard} className="card-header">
        <div className="card-header__icon">{icon}</div>
        <div className="card-header__title">{title}</div>
      </BgCard>
      <div className="card-body">{children}</div>
    </div>
  );
};

const SpecialProcess = () => {
  return (
    <section className="payroll_special-processes">
      <GradientBg className="gradient" direction="top left" gradientFrom="rgba(245, 245, 245, 1) 5%">
        <Container>
          <TitleSpecialProcesses />
          <div className="cards margin">
            <CardSpecialProcesses
              icon={<SettlementsPayrollSvg />}
              title={<FormattedMessage id="payroll.specialprocesses-settlements" defaultMessage="Nómina de Finiquitos" />}
              bgCard={settlementsBgImg}
            >
              <div>
                <FormattedMessage
                  id="payroll.specialprocesses-settlements-description"
                  defaultMessage="Mensualmente o al final de cada período de nómina (esto depende de las especificaciones definidas en la fase de implementación), calcularemos una nómina especial que proporciona un desglose detallado de todos los pagos de indemnizaciones y liquidaciones, facilitando el timbrado de los recibos."
                />
              </div>
            </CardSpecialProcesses>

            <CardSpecialProcesses
              icon={<LogoSatSvg />}
              title={<FormattedMessage id="payroll.specialprocesses-taxes" defaultMessage="Pago de Impuestos" />}
              bgCard={satBgImg}
            >
              <div>
                <FormattedMessage
                  id="payroll.specialprocesses-taxes-p1"
                  defaultMessage="Preparación y envío de toda la información requerida sobre el pago de impuestos:"
                />
              </div>
              <div>
                <div>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p2" defaultMessage="+ ISR" />
                </div>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p3" defaultMessage="- Impuesto retenido" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p4" defaultMessage="- Subsidio (a impuesto)" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p5" defaultMessage="-Subsidio (a empleado)" />
                </li>
              </div>
              <ul>
                <div>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p6" defaultMessage="+ ISN" />
                </div>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p7" defaultMessage="- Bases para el pago" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p8" defaultMessage="- Impuesto a pagar" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p9" defaultMessage="- Número de trabajadores" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-taxes-p10" defaultMessage="- Validación de CFDI’s vs. SAT" />
                </li>
              </ul>
            </CardSpecialProcesses>

            <CardSpecialProcesses
              icon={<LogoImssSvg />}
              title={<FormattedMessage id="payroll.specialprocesses-socialsecurity" defaultMessage="Seguro Social" />}
              bgCard={imssBgImg}
            >
              <ul>
                <div>
                  <FormattedMessage
                    id="payroll.specialprocesses-socialsecurity-p1"
                    defaultMessage="+ Movimientos de afiliación"
                  />
                </div>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-socialsecurity-p2" defaultMessage="· Altas" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-socialsecurity-p3" defaultMessage="· Bajas" />
                </li>
                <li>
                  <FormattedMessage
                    id="payroll.specialprocesses-socialsecurity-p4"
                    defaultMessage="· Modificaciones salariales"
                  />
                </li>
              </ul>
              <div>
                <FormattedMessage
                  id="payroll.specialprocesses-socialsecurity-p5"
                  defaultMessage="- Determinación de variables (bimestral)"
                />
              </div>
              <div>
                <FormattedMessage
                  id="payroll.specialprocesses-socialsecurity-p6"
                  defaultMessage="- Determinación mensual de cuotas obrero-patronales"
                />
              </div>
              <div>
                <FormattedMessage
                  id="payroll.specialprocesses-socialsecurity-p7"
                  defaultMessage="-Elaboración mensual de archivos para auditoría"
                />
              </div>
            </CardSpecialProcesses>

            <CardSpecialProcesses
              icon={<AccountingSvg />}
              title={<FormattedMessage id="payroll.specialprocesses-accounting" defaultMessage="Aspectos Contables" />}
              bgCard={accountingBgImg}
            >
              <div>
                <FormattedMessage
                  id="payroll.specialprocesses-accounting-p1"
                  defaultMessage="Elaboración y transferencia de todas las pólizas de Nómina, de acuerdo a las necesidades del sistema contable de nuestros clientes:"
                />
              </div>
              <br></br>
              <ul>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-accounting-p2" defaultMessage="- Nómina" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-accounting-p3" defaultMessage="- Finiquitos" />
                </li>
                <li>
                  <FormattedMessage id="payroll.specialprocesses-accounting-p4" defaultMessage="- Provisiones" />
                </li>
              </ul>
            </CardSpecialProcesses>
          </div>
          <ReturnToServices />
        </Container>
      </GradientBg>
    </section>
  );
};
export default SpecialProcess;
