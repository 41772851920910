import NavbarComponent from "./Navbar";
import FooterComponent from "./Footer";
import ButtonComponent from "./Button";
import HeaderHero from "./Header";
import ContactComponent from "./Contact";
import ReturnToServicesComponent from "./ReturnToServices";
import { GradientBg as GradientBgComponent } from "./Bg";

export const Navbar = NavbarComponent;
export const Footer = FooterComponent;
export const Button = ButtonComponent;
export const Header = HeaderHero;
export const Contact = ContactComponent;
export const GradientBg = GradientBgComponent;
export const ReturnToServices = ReturnToServicesComponent;
