// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Base components.
import { Button } from '../components-base';

// Assets.
import peopleTalkImg from './assets/img/people-talk.jpg';
import line from './assets/img/line.svg';
const Rules = () => {
  return (
    <section className="my_company_rules">
      <Container>
        <h2>
          <FormattedMessage id="mycompany.rules-title" defaultMessage="Su empresa." />
          <br />
          <FormattedMessage id="mycompany.rules-title2ndline" defaultMessage="Sus reglas." />
        </h2>

        <div className="img-line">
          <img src={line} alt="linea" />
        </div>
        <p className="description">
          <FormattedMessage
            id="mycompany.rules-description"
            defaultMessage="Esta aplicación web está formada por una estructura de módulos básica, una columna vertebral, a la cual pueden integrarse nuevos módulos, derivados de la necesidad de resolver problemas específicos, o bien, modificar los módulos básicos para adaptarse completamente a las necesidades específicas y objetivos de su negocio."
          />
        </p>
        <div className="content">
          <img src={peopleTalkImg} alt="people talking" className="img-fluid content__img" />
          <div className="content__detail">
            <p>
              <FormattedMessage
                id="mycompany.rules-detail"
                defaultMessage="Platiquemos sobre sus objetivos de desarrollo… Contamos con una infraestructura dinámica, ideada para conectarse fácilmente con otros servicios, además de un equipo capaz e innovador enfocado en el desarrollo a medida de tecnología."
              />
            </p>

            <Button to="/software" width={280} className="detail__btn">
              <FormattedMessage id="mycompany.rules-btn" defaultMessage="Desarrollo de Software" />
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Rules;
