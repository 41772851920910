// Librerias.
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { GradientBg } from '../components-base';

// Components Payrroll.
import { TitleCummunication } from './Titles';

// Assets.
import communicationImg from './assets/img/communication.png';
import checkSvg from './assets/icons/check.svg';

const Communication = () => {
  return (
    <section className="payroll_communication">
      <GradientBg className="gradient" gradientFrom="rgba(245, 245, 245, 1) 5%">
        <Container>
          <TitleCummunication />
          <Row className="mt-5 justify-content-between">
            <Col lg={6} className="">
              <h3 className="mb-4">
                <FormattedMessage id="payroll.communication-payrollreceipt" defaultMessage="Recibo de Nómina" />
              </h3>
              <p className="mb-4">
                <FormattedMessage
                  id="payroll.communication-payrollreceipt-description"
                  defaultMessage="Brindamos una solución integral de recibos de pago que comienza por definir su contenido, durante la fase de implementación. Luego, cada periodo, generamos certificados digitales (cfdi’s), los validamos contra el Servicio de Administración Tributaria (SAT) y entregamos los recibos (vía correo electrónico) a todos los empleados."
                />
              </p>
              <ul className="list-unstyled">
                <li className="mb-2 d-flex">
                  <span className="me-2">
                    <img src={checkSvg} alt="check icon" />
                  </span>
                  <p>
                    <FormattedMessage
                      id="payroll.communication-payrollreceipt-p1"
                      defaultMessage="Logotipo, diseño e información a mostrar 100% personalizables"
                    />
                  </p>
                </li>
                <li className="mb-2 d-flex">
                  <span className="me-2">
                    <img src={checkSvg} alt="check icon" />
                  </span>
                  <p>
                    <FormattedMessage
                      id="payroll.communication-payrollreceipt-p2"
                      defaultMessage="Sección de anuncios para mostrar avisos generales o segmentados"
                    />
                  </p>
                </li>
                <li className="d-flex">
                  <span className="me-2 ">
                    <img src={checkSvg} alt="check icon" />
                  </span>
                  <p>
                    <FormattedMessage
                      id="payroll.communication-payrollreceipt-p3"
                      defaultMessage="URL encriptada y seguridad máxima en servidores para almacenamiento de los PDF’s"
                    />
                  </p>
                </li>
              </ul>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end justify-content-center">
              <img src={communicationImg} alt="Recibo de Nómina Nomi" className="img-fluid communication-img" />
            </Col>
          </Row>
        </Container>
      </GradientBg>
    </section>
  );
};
export default Communication;
