// Librerias.
import React from "react";
// Components Base.
import { Footer } from "../components-base";

// Components Home
import Hero from "./Hero";
import OurHistory from "./OurHistory";
import OurMotivation from "./OurMotivation";
import OurGoal from "./OurGoal";
import OurCulture from "./OurCulture";
import OurApproach from "./OurApproach";

const PageTheCompany = () => {
  return (
    <>
      <Hero />
      <OurHistory />
      <OurMotivation />
      <OurGoal />
      <OurCulture />
      <OurApproach />
      <Footer />
    </>
  );
};

export default PageTheCompany;
