// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { GradientBg } from '../components-base/Bg';

// Assets.
import {
  CalculationSvg,
  CommunicationSvg,
  IncidentsSvg,
  InteractionSvg,
  ParametersSvg,
  RetrospectiveSvg,
  Num1Svg,
  Num2Svg,
  Num3Svg,
  Num4Svg,
  Num5Svg,
  Num6Svg,
  Num7Svg,
  ArrowRightSvg,
} from './assets';

const CardService = ({ iconNumber, iconService, title, description, to = '/', children }) => {
  return (
    <a className="card" href={to}>
      <GradientBg className="gradient_hover"></GradientBg>
      <div className="card__icons">
        {iconNumber}
        {iconService}
      </div>
      <div className="card__title">{title}</div>
      <div className="card__description">{description}</div>
      {children}
      <div className="card__arrow">
        <ArrowRightSvg />
      </div>
    </a>
  );
};

const Services = () => {
  return (
    <section className="payroll_services">
      <Container>
        <p className="payroll_services--text-center">
          <FormattedMessage
            id="payroll.services"
            defaultMessage="Ofrecemos, como outsourcing de proceso de negocio (BPO), un servicio integral y completamente personalizable de maquila de nóminas, diseñado para garantizar los más altos estándares de cumplimento y adaptarse con eficiencia a los requerimientos específicos de las transnacionales más exigentes, en México."
          />
        </p>
        <p className="margin" id="services">
          <FormattedMessage id="payroll.services-phases" defaultMessage="Fases del Servicio:" />
        </p>
        <div className="wrapper">
          <div className="card--big">
            <CardService
              iconNumber={<Num1Svg />}
              iconService={<ParametersSvg />}
              title={<FormattedMessage id="payroll.services-phases-parameters" defaultMessage="PARÁMETROS" />}
              description={
                <FormattedMessage
                  id="payroll.services-phases-parameters-description"
                  defaultMessage="Aquí comenzamos, cada periodo de nómina. Una configuración continua de nuestro sistema con datos fijos, pre-establecidos y/o dinámicos..."
                />
              }
              to="#parameters"
            >
              <div className="card__description--gray">
                <div className="bold">
                  <FormattedMessage id="payroll.services-phases-parameters-implementation" defaultMessage="1.1 Implementación" />
                </div>
                <FormattedMessage
                  id="payroll.services-phases-parameters-implementation-description"
                  defaultMessage="Una configuración inicial y única del sistema con información de la compañía y empleados del cliente."
                />
              </div>
              <div className="card__update">
                <FormattedMessage id="payroll.services-phases-parameters-annualupdate" defaultMessage="1.2 Actualización Anual" />
              </div>
              <div className="card__update">
                <FormattedMessage
                  id="payroll.services-phases-parameters-continuousupdate"
                  defaultMessage="1.3 Actualización Continua"
                />
              </div>
            </CardService>
          </div>

          <CardService
            iconNumber={<Num2Svg />}
            iconService={<InteractionSvg />}
            title={<FormattedMessage id="payroll.services-phases-interaction" defaultMessage="INTERACCIÓN" />}
            description={
              <FormattedMessage
                id="payroll.services-phases-interaction-description"
                defaultMessage="Generación, en línea, de información de empleados que después se convertirá en incidencias…"
              />
            }
            to="#interaction"
          />

          <CardService
            iconNumber={<Num3Svg />}
            iconService={<IncidentsSvg />}
            title={<FormattedMessage id="payroll.services-phases-incidents" defaultMessage="INCIDENCIAS" />}
            description={
              <FormattedMessage
                id="payroll.services-phases-incidents-description"
                defaultMessage="Información que varía de periodo a periodo y resulta en la base del cálculo de la nómina..."
              />
            }
            to="#incidents"
          />

          <CardService
            iconNumber={<Num4Svg />}
            iconService={<CalculationSvg />}
            title={<FormattedMessage id="payroll.services-phases-calculation" defaultMessage="CÁLCULO" />}
            description={
              <FormattedMessage
                id="payroll.services-phases-calculation-description"
                defaultMessage="La información recabada en fases anteriores está lista para ser procesada en nuestro sistema…"
              />
            }
            to="#calculation"
          />

          <CardService
            iconNumber={<Num5Svg />}
            iconService={<IncidentsSvg />}
            title={<FormattedMessage id="payroll.services-phases-specialprocesses" defaultMessage="PROCESOS ESPECIALES" />}
            description={
              <FormattedMessage
                id="payroll.services-phases-specialprocesses-description"
                defaultMessage="Tareas a ejecutar cada periodo, mes o año para garantizar el cumplimiento fiscal y legal…"
              />
            }
            to="#specialProcesses"
          />

          <CardService
            iconNumber={<Num6Svg />}
            iconService={<CommunicationSvg />}
            title={<FormattedMessage id="payroll.services-phases-communication" defaultMessage="COMUNICACIÓN" />}
            description={
              <FormattedMessage
                id="payroll.services-phases-communication-description"
                defaultMessage="Momento de ser claros y abiertos con la información para reducir las aclaraciones con empleados…"
              />
            }
            to="#communication"
          />

          <CardService
            iconNumber={<Num7Svg />}
            iconService={<RetrospectiveSvg />}
            title={<FormattedMessage id="payroll.services-phases-retrospective" defaultMessage="RETROSPECTIVA" />}
            description={
              <FormattedMessage
                id="payroll.services-phases-retrospective-description"
                defaultMessage="Mejoramos nuestros procesos y experiencia de servicio de un periodo al siguiente…"
              />
            }
            to="#retrospective"
          />
        </div>
      </Container>
    </section>
  );
};
export default Services;
