// Librerias.
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';

// Languages.
import JapaneseMessages from './../lang/ja-JP.json';
import GermanMessages from './../lang/de-DE.json';
import SpanishMessages from './../lang/es-MX.json';
import EnglishMessages from './../lang/en-US.json';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
  let localeByDefault;
  let messagesByDefault;
  const lang = localStorage.getItem('lang');

  if (lang) {
    localeByDefault = lang;

    if (lang === 'es-MX') {
      messagesByDefault = SpanishMessages;
    } else if (lang === 'ja-JP') {
      messagesByDefault = JapaneseMessages;
    } else if (lang === 'en-US') {
      messagesByDefault = EnglishMessages;
    } else if (lang === 'de-DE') {
      messagesByDefault = GermanMessages;
    } else {
      localeByDefault = 'es-MX';
      messagesByDefault = SpanishMessages;
    }
  }

  const [messages, setMessages] = useState(messagesByDefault);
  const [locale, setLocale] = useState(localeByDefault);

  const setLanguage = (language) => {
    switch (language) {
      case 'es-MX':
        setMessages(SpanishMessages);
        setLocale('es-MX');
        localStorage.setItem('lang', 'es-MX');
        break;
      case 'ja-JP':
        setMessages(JapaneseMessages);
        setLocale('ja-JP');
        localStorage.setItem('lang', 'ja-JP');
        break;
      case 'en-US':
        setMessages(EnglishMessages);
        setLocale('en-US');
        localStorage.setItem('lang', 'en-US');
        break;
      case 'de-DE':
        setMessages(GermanMessages);
        setLocale('de-DE');
        localStorage.setItem('lang', 'de-DE');
        break;
      default:
        setMessages(SpanishMessages);
        setLocale('es-MX');
        localStorage.setItem('lang', 'es-MX');
    }
  };

  return (
    <langContext.Provider value={{ setLanguage: setLanguage }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export { LangProvider, langContext };
