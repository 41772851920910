// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container } from 'react-bootstrap';

// Assets.
import ourCultureBgSvg from './assets/svg/our-culture-bg.svg';
import ourCultureMobileBgSvg from './assets/svg/our-culture-mobile-bg.svg';
import { CheckWhiteSvg, IconCareSvg, IconClaritySvg, IconIntegritySvg, IconPersistenceSvg } from './Assets';

// Hooks.
import useWindow from '../hooks/useWindow';

const OurCultureDesktop = () => {
  return (
    <section className="the-company_our-culture the-company_our-culture__bg">
      <Container className="container__bg position-relative">
        <div className="description">
          <FormattedMessage
            id="thecompany.culture-description"
            defaultMessage="Creemos que una cultura centrada en valores humanos nos guiará a la excelencia en todas sus formas…         "
          />
        </div>

        <div className="wrapper">
          <div className="wrapper__circle-info">
            <div className="wrapper__circle-info--title gloria">
              <span>
                <FormattedMessage id="thecompany.culture-title-01" defaultMessage="Nuestra" />
                <br />
                <FormattedMessage id="thecompany.culture-title-02" defaultMessage="Cultura" />
              </span>
            </div>
            <div className="wrapper__circle-info--care">
              <IconCareSvg />
              <FormattedMessage id="thecompany.culture-care" defaultMessage="CUIDADO" />
            </div>
            <div className="wrapper__circle-info--persistence">
              <IconPersistenceSvg />
              <FormattedMessage id="thecompany.culture-persistence" defaultMessage="PERSISTENCIA" />
            </div>
            <div className="wrapper__circle-info--clarity">
              <IconClaritySvg />
              <FormattedMessage id="thecompany.culture-clarity" defaultMessage="CLARIDAD" />
            </div>
            <div className="wrapper__circle-info--integrity">
              <IconIntegritySvg />
              <FormattedMessage id="thecompany.culture-integrity" defaultMessage="INTEGRIDAD" />
            </div>
          </div>

          <div className="wrapper__values">
            <div>
              <div className="wrapper__values--title-pink">
                <FormattedMessage id="thecompany.culture-values" defaultMessage="NUESTROS VALORES:" />
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <FormattedMessage id="thecompany.culture-values-audacity" defaultMessage="OSADÍA" />
              </div>
              <div>
                <FormattedMessage
                  id="thecompany.culture-values-audacity-description"
                  defaultMessage="Tenemos el valor de atrevernos a pensar y hacer las cosas de forma diferente."
                />
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <FormattedMessage id="thecompany.culture-values-justice" defaultMessage="JUSTICIA" />
              </div>
              <div>
                <FormattedMessage
                  id="thecompany.culture-values-justice-description"
                  defaultMessage="Nos enorgullece actuar, siempre, con honestidad e imparcialidad, sin vacilaciones."
                />
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <FormattedMessage id="thecompany.culture-values-compromise" defaultMessage="COMPROMISO" />
              </div>
              <div>
                <FormattedMessage
                  id="thecompany.culture-values-compromise-description"
                  defaultMessage="Estamos orientados y determinados a entregar resultados."
                />
              </div>
            </div>
          </div>
        </div>
        <img src={ourCultureBgSvg} alt="Nuestra cultura" className="img-culture" width={1500} height={1512} />
      </Container>
    </section>
  );
};

const OurCultureMobile = () => {
  return (
    <section className="the-company_our-culture_mobile the-company_our-culture_mobile___bg">
      <img src={ourCultureMobileBgSvg} alt="Nuestra cultura" className="img-culture img-fluid" width={768} height={2018} />

      <div className="circle-info">
        <div className="circle-info--title gloria">
          <span>
            <FormattedMessage id="thecompany.culture-title-01" defaultMessage="Nuestra" /> <br />
            <FormattedMessage id="thecompany.culture-title-02" defaultMessage="Cultura" />
          </span>
        </div>
        <div className="circle-info--care">
          <IconCareSvg />
          <FormattedMessage id="thecompany.culture-care" defaultMessage="CUIDADO" />
        </div>
        <div className="circle-info--persistence">
          <IconPersistenceSvg />
          <FormattedMessage id="thecompany.culture-persistence" defaultMessage="PERSISTENCIA" />
        </div>
        <div className="circle-info--clarity">
          <IconClaritySvg />
          <FormattedMessage id="thecompany.culture-clarity" defaultMessage="CLARIDAD" />
        </div>
        <div className="circle-info--integrity">
          <IconIntegritySvg />
          <FormattedMessage id="thecompany.culture-integrity" defaultMessage="INTEGRIDAD" />
        </div>
      </div>

      <Container className="container__bg position-relative">
        <div className="container__description">
          <FormattedMessage
            id="thecompany.culture-description"
            defaultMessage="Creemos que una cultura centrada en valores humanos nos guiará a la excelencia en todas sus formas…"
          />
        </div>

        <div className="wrapper wrapper__bg">
          <div className="wrapper__values">
            <div>
              <div className="wrapper__values--title-pink">
                <FormattedMessage id="thecompany.culture-values" defaultMessage="NUESTROS VALORES:" />
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <CheckWhiteSvg />
                <FormattedMessage id="thecompany.culture-values-audacity" defaultMessage="OSADÍA" />
              </div>
              <div>
                <FormattedMessage
                  id="thecompany.culture-values-audacity-description"
                  defaultMessage="Tenemos el valor de atrevernos a pensar y hacer las cosas de forma diferente."
                />
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <CheckWhiteSvg />
                <FormattedMessage id="thecompany.culture-values-justice" defaultMessage="JUSTICIA" />
              </div>
              <div>
                <FormattedMessage
                  id="thecompany.culture-values-justice-description"
                  defaultMessage="Nos enorgullece actuar, siempre, con honestidad e imparcialidad, sin vacilaciones."
                />
              </div>
            </div>
            <div>
              <div className="wrapper__values--title">
                <CheckWhiteSvg />
                <FormattedMessage id="thecompany.culture-values-compromise" defaultMessage="COMPROMISO" />
              </div>
              <div>
                <FormattedMessage
                  id="thecompany.culture-values-compromise-description"
                  defaultMessage="Estamos orientados y determinados a entregar resultados."
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const OurCulture = () => {
  const windowSize = useWindow();
  return <>{windowSize.width > 768 ? <OurCultureDesktop /> : <OurCultureMobile />}</>;
};

export default OurCulture;
