// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import { IllustVisionSvg, IllustMissionSvg, ArrowMobileSvg } from './Assets';

const CardService = ({ cardName, icon, title, description }) => {
  return (
    <div className={`card ${cardName}`}>
      <div className="card__body">
        {icon}
        <h3>{title}</h3>
        {description}
      </div>
    </div>
  );
};

const OurGoal = () => {
  return (
    <section className="the-company_our-goal">
      <Container className="wrapper">
        <div className="how gloria">
          <span className="hide-on-mobile">
            <FormattedMessage id="thecompany.goal-how" defaultMessage="¿Cómo llegamos ahí?" />
          </span>
          <div className="alignment-text-mobile hide-on-desktop">
            <span className="text-mobile">
              <FormattedMessage id="thecompany.goal-how-mobile-01" defaultMessage="¿Cómo" />
              <br />
              <FormattedMessage id="thecompany.goal-how-mobile-02" defaultMessage="llegamos" />
              <br />
              <FormattedMessage id="thecompany.goal-how-mobile-03" defaultMessage="ahí?" />
            </span>
            <ArrowMobileSvg />
          </div>
        </div>

        <CardService
          cardName="vision"
          icon={<IllustVisionSvg />}
          title={
            <h3>
              <FormattedMessage id="thecompany.goal-vision-title" defaultMessage="Visión" />
            </h3>
          }
          description={
            <p>
              <FormattedMessage
                id="thecompany.goal-vision-description"
                defaultMessage="Ser un factor decisivo para que las compañías globales decidan invertir en México, antes que en otros países."
              />
            </p>
          }
        />
        <div className="goal gloria">
          <FormattedMessage id="thecompany.goal-01" defaultMessage="Nuestra" /> <br />
          <FormattedMessage id="thecompany.goal-02" defaultMessage="Meta" />
        </div>
        <div className="where gloria">
          <span className="hide-on-mobile">
            <FormattedMessage id="thecompany.goal-where-01" defaultMessage="¿Hacia dónde" />
            <br />
            <FormattedMessage id="thecompany.goal-where-02" defaultMessage="Vamos?" />
          </span>
          <div className="alignment-text-mobile hide-on-desktop">
            <span className="text-mobile">
              <FormattedMessage id="thecompany.goal-where-mobile-01" defaultMessage="¿Hacia" /> <br />
              <FormattedMessage id="thecompany.goal-where-mobile-02" defaultMessage="dónde" /> <br />
              <FormattedMessage id="thecompany.goal-where-02" defaultMessage="Vamos?" />
            </span>
            <ArrowMobileSvg />
          </div>
        </div>
        <CardService
          cardName="mission"
          icon={<IllustMissionSvg />}
          title={
            <h3>
              <FormattedMessage id="thecompany.goal-mission-title" defaultMessage="Misión" />
            </h3>
          }
          description={
            <p>
              <FormattedMessage
                id="thecompany.goal-mission-description"
                defaultMessage="Brindar pensamiento creativo, procesos y tecnología que agilicen la administración de compañías globales en México."
              />
            </p>
          }
        />
      </Container>
    </section>
  );
};

export default OurGoal;
