// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const What = () => {
  return (
    <section className="page_approach_what">
      <Container>
        <p className="question">
          <FormattedMessage id="approach.what-different" defaultMessage="¿Qué nos hace difentes?" />
        </p>
        <p className="our">
          <FormattedMessage id="approach.what-people" defaultMessage="Nuestra gente." />
        </p>
        <p>
          <FormattedMessage
            id="approach.what-experience"
            defaultMessage="Y la experiencia de servicio que están tan preparados como determinados a proveer a nuestros clientes…"
          />
        </p>
      </Container>
    </section>
  );
};

export default What;
