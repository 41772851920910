import styled from "styled-components";

import heroDefault from "../assets/img/hero-home.png";
export const Header = styled.header`
  background-image: url(${(props) =>
    props.uri_bg_d ? props.uri_bg_d : heroDefault});
  background-size: cover;
  background-position: center;
  /* height: ${(props) => (props.height ? `${props.height}px` : "auto")}; */
  &.hero-img {
  }
  @media (max-width: 768px) {
    background-image: url(${(props) =>
      props.uri_bg_t ? props.uri_bg_t : props.uri_bg_d});
  }
  @media (max-width: 475px) {
    background-image: url(${(props) =>
      props.uri_bg_p ? props.uri_bg_p : props.uri_bg_d});
  }
`;
