// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Container } from 'react-bootstrap';
import { Button } from '../components-base';

// Assets.
import { IconArrowSvg, HowBg, HowBgMobile } from './Assets';

const How = () => {
  return (
    <section className="home_how">
      <Container>
        <div className="home_how__info">
          <h1 className="title">
            <FormattedMessage id="home.how-title" defaultMessage="Cómo lo hacemos…" />
          </h1>
          <p className="description">
            <FormattedMessage
              id="home.how-description"
              defaultMessage="Una extraordinaria experiencia para nuestros clientes externos depende de qué tan extraordinaria es para nuestros clientes internos."
            />
          </p>
          <Button to="/approach" width="320" className="btn btn-desktop">
            <FormattedMessage id="home.how-btn" defaultMessage="Nuestro Approach" />
            <span>
              <IconArrowSvg className="icon-arrow"></IconArrowSvg>
            </span>
          </Button>
          <Button to="/approach" className="btn btn-mobile">
            <FormattedMessage id="home.how-btn" defaultMessage="Nuestro Approach" />
            <span>
              <IconArrowSvg className="icon-arrow"></IconArrowSvg>
            </span>
          </Button>
        </div>
      </Container>
      <div className="home_how__bg"></div>
      <HowBg className="home_how__bg-img img-fluid" />
      <HowBgMobile className="home_how__bg-mobile-img img-fluid" />
    </section>
  );
};

export default How;
