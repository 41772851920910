// Arquitecture.
import aws from "./assets/arquitecture/aws.svg";
import azure from "./assets/arquitecture/azure.svg";
import digitalOcean from "./assets/arquitecture/digital-ocean.svg";
import django from "./assets/arquitecture/django.svg";
import docker from "./assets/arquitecture/docker.svg";
import flask from "./assets/arquitecture/flask.svg";
import ibmi from "./assets/arquitecture/ibmi.svg";
import js from "./assets/arquitecture/js.svg";
import nginx from "./assets/arquitecture/nginx.svg";
import postgreSQL from "./assets/arquitecture/postgreSQL.svg";
import python from "./assets/arquitecture/python.svg";
import react from "./assets/arquitecture/react.svg";

// Arquitecture.
export const LogoAws = ({ className }) => {
  return (
    <img
      src={aws}
      alt="Logo AWS"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoAzure = ({ className }) => {
  return (
    <img
      src={azure}
      alt="Logo Azure"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoDigitalOcean = ({ className }) => {
  return (
    <img
      src={digitalOcean}
      alt="Logo Digital Ocean"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoDjango = ({ className }) => {
  return (
    <img
      src={django}
      alt="Logo django"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoDocker = ({ className }) => {
  return (
    <img
      src={docker}
      alt="Logo docker"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoFlask = ({ className }) => {
  return (
    <img
      src={flask}
      alt="Logo Flask"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoIBMi = ({ className }) => {
  return (
    <img
      src={ibmi}
      alt="Logo IBMi"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoJS = ({ className }) => {
  return (
    <img
      src={js}
      alt="Logo JavaScript"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoNGINX = ({ className }) => {
  return (
    <img
      src={nginx}
      alt="Logo nginx"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoPostgreSQL = ({ className }) => {
  return (
    <img
      src={postgreSQL}
      alt="Logo postgreSQL"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoPython = ({ className }) => {
  return (
    <img
      src={python}
      alt="Logo Python"
      width={150}
      height={120}
      className={className}
    />
  );
};
export const LogoReact = ({ className }) => {
  return (
    <img
      src={react}
      alt="Logo React"
      width={150}
      height={120}
      className={className}
    />
  );
};
