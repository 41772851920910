import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
//Asset.
import checkin from './assets/img/checkin.gif';
import manager from './assets/img/manager.png';
import empleado from './assets/img/empleado.png';

const CardDicoma = ({ title, img, width = 318, height = 318, subtitle, description, classSubtitle = '' }) => {
  return (
    <div className={'card'}>
      <h2>{title}</h2>
      <div>
        <img src={img} alt="" width={width} height={height} className="img-fluid" />
      </div>
      <div>
        <h4 className={classSubtitle}>{subtitle}</h4>
        <>{description}</>
      </div>
    </div>
  );
};

const Manager = () => {
  return (
    <section className="dicoma_manager">
      <Container>
        <Row>
          <Col lg={3} className="px-0 ">
            <CardDicoma
              title={<FormattedMessage id="dicoma.manager-manager" defaultMessage="Manager" />}
              img={manager}
              subtitle={<FormattedMessage id="dicoma.manager-manager-subtitle" defaultMessage="Todos los Consumos" />}
              description={
                <FormattedMessage
                  id="dicoma.manager-manager-description"
                  defaultMessage="El área de RRHH podrá consultar los registros generales de empleados, proveedores y/o invitados."
                />
              }
            />
          </Col>
          <Col lg={6} className="px-0 order-0">
            <CardDicoma
              title={<FormattedMessage id="dicoma.manager-checkin" defaultMessage="Check-in" />}
              img={checkin}
              subtitle={
                <FormattedMessage id="dicoma.manager-checkin-subtitle" defaultMessage="Cuando el empleado hace Check-in…" />
              }
              description={
                <>
                  <p>
                    <FormattedMessage id="dicoma.manager-checkin-p1" defaultMessage="1… sistema recibe una PETICIÓN," />
                  </p>
                  <p>
                    <FormattedMessage id="dicoma.manager-checkin-p2" defaultMessage="2… VALIDA según las reglas de negocio," />
                  </p>
                  <p>
                    <FormattedMessage id="dicoma.manager-checkin-p3" defaultMessage="3… regresa una RESPUESTA," />
                  </p>
                  <button className="button-dicoma">
                    <FormattedMessage id="dicoma.manager-checkin-p4" defaultMessage="4. REGISTRA" />
                  </button>
                </>
              }
              width={442}
              height={430}
              classSubtitle="sub-title-middle "
            />
          </Col>
          <Col lg={3} className="px-0 order-3">
            <CardDicoma
              title={<FormattedMessage id="dicoma.manager-worker" defaultMessage="Empleado" />}
              img={empleado}
              subtitle={<FormattedMessage id="dicoma.manager-worker-subtitle" defaultMessage="Mis Consumos" />}
              description={
                <FormattedMessage
                  id="dicoma.manager-worker-description"
                  defaultMessage="El empleado podrá consultar sus consumos mediante su acceso a la aplicación de auto-servicio: My[Company]"
                />
              }
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Manager;
