// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import { Num1Svg, Num2Svg, Num3Svg } from './assets/index.js';

const CardUsability = ({ icon, title, description }) => {
  return (
    <div className="card">
      <div className="card__icon">{icon}</div>
      <div className="card__title">{title}</div>
      <div className="card__description">{description}</div>
    </div>
  );
};

const Usability = () => {
  return (
    <section className="software_usability">
      <Container>
        <h2 className="software_usability__description">
          <FormattedMessage
            id="software.usability-description"
            defaultMessage="Creemos que la diferencia entre una simple aplicación y una gran herramienta de trabajo se llama..."
          />
        </h2>
      </Container>
      <div className="wrapper-bg">
        <Container>
          <div className="wrapper">
            <h1>
              <FormattedMessage id="software.usability" defaultMessage="USABILIDAD" />
            </h1>
            <div className="cards">
              <CardUsability
                icon={<Num1Svg />}
                title={<FormattedMessage id="software.usability-easier" defaultMessage="Más Fácil" />}
                description={
                  <FormattedMessage
                    id="software.usability-easier-description"
                    defaultMessage="Nuestras aplicaciones son sencillas de entender y muy fáciles de usar, a diferencia de otras similares y teniendo en cuenta la naturaleza de los temas que se tratan, como cifras, nóminas, finanzas e impuestos, entre otros."
                  />
                }
              ></CardUsability>
              <CardUsability
                icon={<Num2Svg />}
                title={<FormattedMessage id="software.usability-quicker" defaultMessage="Más Rápido" />}
                description={
                  <FormattedMessage
                    id="software.usability-quicker-description"
                    defaultMessage="Evitamos ventanas y/o preguntas innecesarias para crear flujos intuitivos. Ya que conocemos bien los procesos manuales que están implicados en la ejecución de tareas y validaciones, ahorramos tiempo al automatizar esos procesos."
                  />
                }
              ></CardUsability>
              <CardUsability
                icon={<Num3Svg />}
                title={<FormattedMessage id="software.usability-nicer" defaultMessage="Más Agradable" />}
                description={
                  <FormattedMessage
                    id="software.usability-nicer-description"
                    defaultMessage="En nuestras interfaces cuidamos detalles como los colores, los espacios en blanco, la composición visual, las ilustraciones; incluso el tono de voz mismo o el lenguaje que usamos: sin restar seriedad, lo hacemos más agradable."
                  />
                }
              ></CardUsability>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <h2 className="software_usability__description2">
          <FormattedMessage
            id="software.usability-description2"
            defaultMessage="Automatizamos procesos manuales dentro de la gestión administrativa de su capital humano, mediante un esquema de
          servicio "
          />
          <span>
            <FormattedMessage id="software.usability-description2-pink" defaultMessage="full-cycle:" />
          </span>
          <FormattedMessage id="software.usability-description2-2" defaultMessage=" " />
        </h2>
      </Container>
    </section>
  );
};
export default Usability;
