// Librerias
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';
import ReturnToServices from '../components-base/ReturnToServices';
import { TitleSoftware } from './Titles';

// Assets.
import {
  DataBaseSvg,
  ImgDataBaseSvg,
  IntegrationsSvg,
  ImgIntegrationsSvg,
  WebAppSvg,
  ImgWebAppSvg,
  ArrowsSvg,
  ArrowsMobileSvg,
} from './assets/index.js';

const CardSoftware = ({ icon, title, description, imgSoftware, children, classChildren }) => {
  return (
    <div className="wrapper">
      <div className="description row">
        <Col className="description__text mb-4">
          <div className="description__text--title mb-4">
            <span>{icon}</span>
            <h3>{title}</h3>
          </div>
          <div className="description__text--body">{description}</div>
        </Col>
        <Col className="description__img">{imgSoftware}</Col>
      </div>
      <div className={classChildren}>{children}</div>
    </div>
  );
};

const CardDetail = ({ children, classCard = '' }) => {
  return <div className={`cards ${classCard}`}>{children}</div>;
};

const Software = () => {
  return (
    <section className="software_software">
      <Container>
        <TitleSoftware />
        <CardSoftware
          icon={<WebAppSvg />}
          title={<FormattedMessage id="software.software-webapp" defaultMessage="Aplicaciones Web" />}
          description={
            <FormattedMessage
              id="software.software-webapp-description"
              defaultMessage="Nuestras aplicaciones web facilitan el acceso desde una computadora o dispositivo con internet. Esto también permite la actualización del producto, sin necesidad de que el usuario instale nuevas versiones."
            />
          }
          imgSoftware={<ImgWebAppSvg />}
          classChildren="web"
        >
          <div>
            <h2>
              <FormattedMessage id="software.software-webapp-frontend" defaultMessage="FRONT-END" />
            </h2>
            <CardDetail classCard="web-card">
              <h4>
                <FormattedMessage id="software.software-webapp-frontend-layout" defaultMessage="Maquetación" />
              </h4>
              <p>
                <FormattedMessage
                  id="software.software-webapp-frontend-layout-description"
                  defaultMessage="La maquetación es darle vida al prototipo, ya que permite verlo en los navegadores debido a su programación HTML, uso de hojas de estilo (css, java…), frameworks y entre otros."
                />
              </p>
              <h4>
                <FormattedMessage
                  id="software.software-webapp-frontend-serviceconnection"
                  defaultMessage="Conexión a Servicios"
                />
              </h4>
              <p>
                <FormattedMessage
                  id="software.software-webapp-frontend-serviceconnection-description"
                  defaultMessage="La programación del ‘front’ (previamente conocido como prototipo) debe considerar la creación de entradas y salidas necesarias para el intercambio de información con el ‘back’."
                />
              </p>
            </CardDetail>
          </div>

          <div className="icon">
            <ArrowsSvg />
          </div>

          <div className="icon-mobile">
            <ArrowsMobileSvg />
          </div>

          <div>
            <h2>
              <FormattedMessage id="software.software-webapp-backend" defaultMessage="BACK-END" />
            </h2>
            <CardDetail classCard="web-card">
              <h4>
                <FormattedMessage id="software.software-webapp-backend-endpoints" defaultMessage="Servicios (end-points)" />
              </h4>
              <p>
                <FormattedMessage
                  id="software.software-webapp-backend-endpoints-description"
                  defaultMessage="Los endpoints son las URLs de un API o un backend que responden a una petición hecha desde el frontend. Es el punto inicial de comunicación entre estas dos instancias."
                />
              </p>
              <h4>
                <FormattedMessage id="software.software-webapp-backend-logic" defaultMessage="Lógica de Negocio" />
              </h4>
              <p>
                <FormattedMessage
                  id="software.software-webapp-backend-logic-description"
                  defaultMessage="Es la parte del sistema que se encarga de codificar las reglas de negocio del mundo real que determinan cómo la información puede ser creada, almacenada y cambiada."
                />
              </p>
              <h4>
                <FormattedMessage id="software.software-webapp-backend-dbconnection" defaultMessage="Conexión a Base de Datos" />
              </h4>
              <p>
                <FormattedMessage
                  id="software.software-webapp-backend-dbconnection-description"
                  defaultMessage="Nuestras aplicaciones permiten el intercambio de información en tiempo real entre el usuario final y los registros almacenados en nuestras bases de datos."
                />
              </p>
            </CardDetail>
          </div>
        </CardSoftware>
        <CardSoftware
          icon={<IntegrationsSvg />}
          title={<FormattedMessage id="software.software-integrations" defaultMessage="Integraciones" />}
          description={
            <FormattedMessage
              id="software.software-integrations-description"
              defaultMessage="Todas nuestras aplicaciones pueden conectarse entre sí. También nos comunicamos con otros sistemas de nuestros clientes o de sus proveedores para enriquecer la oferta de la aplicación en desarrollo."
            />
          }
          imgSoftware={<ImgIntegrationsSvg />}
        >
          <Row>
            <Col>
              <CardDetail>
                <h4>
                  <FormattedMessage id="software.software-integrations-apirest" defaultMessage="API REST" />
                </h4>
                <p>
                  <FormattedMessage
                    id="software.software-integrations-apirest-description"
                    defaultMessage="Por sus siglas en inglés “Application Programming Interface”, y en español, “Interfaz de Programación de Aplicaciones”. Es la forma en que dos aplicaciones o servicios se comunican entre sí. … Por eso, estas sirven para que una aplicación pueda interactuar con otra."
                  />
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail>
                <h4>
                  <FormattedMessage id="software.software-integrations-sftp" defaultMessage="SFTP" />
                </h4>
                <p>
                  <FormattedMessage
                    id="software.software-integrations-sftp-description"
                    defaultMessage="Facilitamos la transferencias de archivos servidor-a-servidor entre nosotros y el cliente, sus proveedores, y servidores empresariales mediante SFTP (Secure File Transfer Protocol) que es una opción que garantiza la seguridad de su información y la de sus empleados."
                  />
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail>
                <h4>
                  <FormattedMessage id="software.software-integrations-microservices" defaultMessage="Microservicios" />
                </h4>
                <p>
                  <FormattedMessage
                    id="software.software-integrations-microservices-description"
                    defaultMessage="Un enfoque arquitectónico y organizativo para el desarrollo de software donde el software está compuesto por pequeños servicios independientes que se comunican a través de API bien definidas. Los propietarios de estos servicios son equipos pequeños independientes."
                  />
                </p>
              </CardDetail>
            </Col>
          </Row>
        </CardSoftware>
        <CardSoftware
          icon={<DataBaseSvg />}
          title={<FormattedMessage id="software.software-db" defaultMessage="Bases de datos" />}
          description={
            <FormattedMessage
              id="software.software-db-description"
              defaultMessage="Dado que manejamos grandes cantidades de información sensible de sus empleados, mantenemos una sana gestión para salvaguardar los registros de su información generada por nuestras aplicaciones."
            />
          }
          imgSoftware={<ImgDataBaseSvg />}
        >
          <Row className="flex-wrap">
            <Col>
              <CardDetail>
                <h4>
                  <FormattedMessage id="software.software-db-map" defaultMessage="Mapeo de datos" />
                </h4>
                <p>
                  <FormattedMessage
                    id="software.software-db-map-description"
                    defaultMessage="El mapeo de datos es el proceso por el que se establecen correspondencias entre campos de una base de datos a otra. Es el primer paso para facilitar la migración, integración y otras tareas de gestión de datos."
                  />
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail>
                <h4>
                  <FormattedMessage id="software.software-db-normalization" defaultMessage="Normalización de Datos" />
                </h4>
                <p>
                  <FormattedMessage
                    id="software.software-db-normalization-description"
                    defaultMessage="El proceso de organización de datos en una base de datos. Esto incluye crear tablas y establecer relaciones entre dichas tablas de acuerdo con reglas diseñadas tanto para proteger los datos como para que la base de datos sea más flexible al eliminar la redundancia y la dependencia incoherente."
                  />
                </p>
              </CardDetail>
            </Col>
            <Col>
              <CardDetail className="mb-0">
                <h4>
                  <FormattedMessage id="software.software-db-analysis" defaultMessage="Análisis de datos" />
                </h4>
                <p>
                  <FormattedMessage
                    id="software.software-db-analysis-description"
                    defaultMessage="El análisis de datos es el estudio exhaustivo de un conjunto de información cuyo objetivo es obtener conclusiones que permitan a una empresa o entidad tomar una decisión. … Durante este análisis, los datos pueden ser objeto de operaciones, por ejemplo, para obtener indicadores estadísticos."
                  />
                </p>
              </CardDetail>
            </Col>
          </Row>
        </CardSoftware>
        <ReturnToServices />
      </Container>
      <div className="line"></div>
    </section>
  );
};
export default Software;
