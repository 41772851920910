// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import {
  LogoAeromexico,
  LogoBonfont,
  LogoCirclePurple,
  LogoCondumex,
  LogoDanone,
  LogoExxonMobil,
  LogoGillete,
  LogoGlaxoWellcome,
  LogoIci,
  LogoJanseencilag,
  LogoKraft,
  LogoPemex,
  LogoPfizer,
  LogoPilgrims,
  LogoToyota,
  LogoUnivision,
} from './Assets';

const SliderLogos = () => {
  return (
    <div class="slider">
      <div class="slide-track">
        <div class="slide">
          <LogoKraft />
        </div>
        <div class="slide">
          <LogoUnivision />
        </div>
        <div class="slide">
          <LogoCirclePurple />
        </div>
        <div class="slide">
          <LogoDanone />
        </div>
        <div class="slide">
          <LogoToyota />
        </div>
        <div class="slide">
          <LogoGillete />
        </div>
        <div class="slide">
          <LogoAeromexico />
        </div>
        <div class="slide">
          <LogoExxonMobil />
        </div>
        <div class="slide">
          <LogoPfizer />
        </div>
        <div class="slide">
          <LogoCondumex />
        </div>
        <div class="slide">
          <LogoJanseencilag />
        </div>
        <div class="slide">
          <LogoPilgrims />
        </div>
        <div class="slide">
          <LogoGlaxoWellcome />
        </div>
        <div class="slide">
          <LogoIci />
        </div>
        <div class="slide">
          <LogoBonfont />
        </div>
        <div class="slide">
          <LogoPemex />
        </div>
        <div class="slide">
          <LogoKraft />
        </div>
        <div class="slide">
          <LogoUnivision />
        </div>
        <div class="slide">
          <LogoCirclePurple />
        </div>
        <div class="slide">
          <LogoDanone />
        </div>
        <div class="slide">
          <LogoToyota />
        </div>
        <div class="slide">
          <LogoGillete />
        </div>
        <div class="slide">
          <LogoAeromexico />
        </div>
        <div class="slide">
          <LogoExxonMobil />
        </div>
        <div class="slide">
          <LogoPfizer />
        </div>
        <div class="slide">
          <LogoCondumex />
        </div>
        <div class="slide">
          <LogoJanseencilag />
        </div>
        <div class="slide">
          <LogoPilgrims />
        </div>
        <div class="slide">
          <LogoGlaxoWellcome />
        </div>
        <div class="slide">
          <LogoIci />
        </div>
        <div class="slide">
          <LogoBonfont />
        </div>
        <div class="slide">
          <LogoPemex />
        </div>
      </div>
    </div>
  );
};

const Clients = () => {
  return (
    <section className="home_clients">
      <Container>
        <p>
          <FormattedMessage
            id="home.clients"
            defaultMessage="En +30 años de experiencia hemos trabajado con algunas de las mejores compañías globales que se han establecido en México:"
          />
        </p>
      </Container>
      <SliderLogos />
    </section>
  );
};

export default Clients;
