// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import { IllustCommunicationSvg, IllustExperienceSvg, IllustPerspectiveSvg } from './Assets';

const CardService = ({ icon, title, description }) => {
  return (
    <div className="card">
      <div className="card__body">
        {icon}
        <h3>{title}</h3>
        {description}
      </div>
    </div>
  );
};

const Why = () => {
  return (
    <section className="home_why">
      <Container>
        <h2>
          <FormattedMessage id="home.why-title" defaultMessage="Por qué lo hacemos…" />
        </h2>

        <div className="cards-wrapper">
          <CardService
            description={
              <p>
                <span>
                  <FormattedMessage id="home.why-card-experience-bold" defaultMessage="Mejor experiencia de trabajo " />
                </span>
                <FormattedMessage id="home.why-card-experience" defaultMessage="para las áreas de nómina, finanzas y RRHH" />
              </p>
            }
            icon={<IllustExperienceSvg />}
          />
          <CardService
            description={
              <p>
                <span>
                  <FormattedMessage id="home.why-card-communication-bold" defaultMessage="Comunicación directa y clara " />
                </span>
                <FormattedMessage id="home.why-card-communication" defaultMessage="con todos los empleados" />
              </p>
            }
            icon={<IllustCommunicationSvg />}
          />
          <CardService
            description={
              <p>
                <span>
                  <FormattedMessage id="home.why-card-perspective-bold" defaultMessage="Amplia perspectiva " />
                </span>
                <FormattedMessage
                  id="home.why-card-perspective"
                  defaultMessage="para los líderes de sus equipos y la dirección"
                />
              </p>
            }
            icon={<IllustPerspectiveSvg />}
          />
        </div>
      </Container>
    </section>
  );
};

export default Why;
