// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Navbar } from '../components-base';

// Assets.

const Hero = () => {
  return (
    <>
      <Navbar typeNav="dark" />
      <Container>
        <section className="contact_hero">
          <h2>
            <FormattedMessage id="contact.hero" defaultMessage="¡Hagamos contacto!" />
          </h2>
        </section>
      </Container>
    </>
  );
};
export default Hero;
