// Librerias.
import React from "react";
// Components Base.
import { Footer } from "../components-base";
// Components Home
import Hero from "./Hero";
import Why from "./Why";
import Do from "./Do";
import Contact from "./Contact";
import How from "./How";
import Clients from "./Clients";

const PageHome = () => {
  return (
    <>
      <Hero />
      <Why />
      <How />
      <Do />
      <Contact />
      <Clients />
      <Footer />
    </>
  );
};

export default PageHome;
