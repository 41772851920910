// Librerias.
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReturnToServices from "../components-base/ReturnToServices";
import { TitleArchitecture } from "./Titles";

// Assets.
import architectureImg from "./assets/img/architecture.png";
// Assets.
import {
  LogoAws,
  LogoAzure,
  LogoDigitalOcean,
  LogoDjango,
  LogoDocker,
  LogoFlask,
  LogoIBMi,
  LogoJS,
  LogoNGINX,
  LogoPostgreSQL,
  LogoPython,
  LogoReact,
} from "./Assets";

const SliderLogos = () => {
  return (
    <div class="slider">
      <div class="slide-track">
        <div class="slide">
          <LogoAws />
        </div>
        <div class="slide">
          <LogoAzure />
        </div>
        <div class="slide">
          <LogoDigitalOcean />
        </div>
        <div class="slide">
          <LogoDjango />
        </div>
        <div class="slide">
          <LogoDocker />
        </div>
        <div class="slide">
          <LogoFlask />
        </div>
        <div class="slide">
          <LogoIBMi />
        </div>
        <div class="slide">
          <LogoJS />
        </div>
        <div class="slide">
          <LogoNGINX />
        </div>
        <div class="slide">
          <LogoPostgreSQL />
        </div>
        <div class="slide">
          <LogoPython />
        </div>
        <div class="slide">
          <LogoReact />
        </div>
        <div class="slide">
          <LogoAws />
        </div>
        <div class="slide">
          <LogoAzure />
        </div>
        <div class="slide">
          <LogoDigitalOcean />
        </div>
        <div class="slide">
          <LogoDjango />
        </div>
        <div class="slide">
          <LogoDocker />
        </div>
        <div class="slide">
          <LogoFlask />
        </div>
        <div class="slide">
          <LogoIBMi />
        </div>
        <div class="slide">
          <LogoJS />
        </div>
        <div class="slide">
          <LogoNGINX />
        </div>
        <div class="slide">
          <LogoPostgreSQL />
        </div>
        <div class="slide">
          <LogoPython />
        </div>
        <div class="slide">
          <LogoReact />
        </div>
      </div>
    </div>
  );
};

const Architecture = () => {
  return (
    <section className="software_architecture">
      <Container>
        <Row>
          <Col lg={6}>
            <TitleArchitecture />
          </Col>
          <Col lg={6} className="img-architecture mt-4">
            <img
              src={architectureImg}
              alt="architecture"
              width={500}
              height={380}
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>

      <SliderLogos />

      <Container>
        <ReturnToServices />
      </Container>
    </section>
  );
};
export default Architecture;
