// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import {
  CircleImg,
  ArrowRight,
  ArrowDown,
  ResearchArrowDesktop,
  ResearchArrowMobile,
  TryArrowDesktop,
  TryArrowMobile,
  LearnFromPrototypeArrowDesktop,
  LearnFromPrototypeArrowMobile,
  TestingArrowDesktop,
  TestingArrowMobile,
  LearnFromUsersArrowDesktop,
  LearnFromUsersArrowMobile,
} from './Assets';

// Hooks.
import useWindow from '../hooks/useWindow';

const ImgThinking = () => {
  const windowSize = useWindow();
  const arrow = windowSize.width > 768 ? <ArrowRight /> : <ArrowDown />;
  const learnFromUsersArrow = windowSize.width > 768 ? <LearnFromUsersArrowDesktop /> : <LearnFromUsersArrowMobile />;
  const researchArrow = windowSize.width > 768 ? <ResearchArrowDesktop /> : <ResearchArrowMobile />;
  const tryArrow = windowSize.width > 768 ? <TryArrowDesktop /> : <TryArrowMobile />;
  const learnFromPrototypeArrow = windowSize.width > 768 ? <LearnFromPrototypeArrowDesktop /> : <LearnFromPrototypeArrowMobile />;
  const testingArrow = windowSize.width > 768 ? <TestingArrowDesktop /> : <TestingArrowMobile />;
  return (
    <>
      <div className="circles-grid">
        <div className="learn-from-users stacked">
          <span>
            <FormattedMessage id="approach.thinking-learnfromusers-01" defaultMessage="Aprender del usuario" />
            <br />
            <FormattedMessage id="approach.thinking-learnfromusers-02" defaultMessage="mediante las pruebas" />
          </span>
          {learnFromUsersArrow}
        </div>
        <div className="research">
          <span>
            <FormattedMessage id="approach.thinking-research-01" defaultMessage="Empatizar" />
            <br />
            <FormattedMessage id="approach.thinking-research-02" defaultMessage="para definir el" /> <br />
            <FormattedMessage id="approach.thinking-research-03" defaultMessage="problema" />
          </span>
          {researchArrow}
        </div>
        <div className="try">
          <span>
            <FormattedMessage id="approach.thinking-try-01" defaultMessage="Probar genera" /> <br />
            <FormattedMessage id="approach.thinking-try-02" defaultMessage="nuevas ideas" />
          </span>
          {tryArrow}
        </div>

        <div className="circle empathize">
          <CircleImg />
          <FormattedMessage id="approach.thinking-empathize" defaultMessage="Empatizar" />
        </div>
        <div className="arrow arrow-empathize">{arrow}</div>
        <span className="research-mobile-text stacked">
          <FormattedMessage id="approach.thinking-research-mobile-01" defaultMessage="Empatizar" />
          <br />
          <FormattedMessage id="approach.thinking-research-mobile-02" defaultMessage="para definir" />
          <br />
          <FormattedMessage id="approach.thinking-research-mobile-03" defaultMessage="el problema" />
        </span>

        <div className="circle define">
          <CircleImg />
          <span>
            <FormattedMessage id="approach.thinking-define" defaultMessage="Definir" />
          </span>
        </div>
        <div className="arrow arrow-define">{arrow}</div>
        <span className="learn-from-users-mobile-text stacked">
          <FormattedMessage id="approach.thinking-learnfromusers-mobile-01" defaultMessage="Aprender del" /> <br />
          <FormattedMessage id="approach.thinking-learnfromusers-mobile-02" defaultMessage="usuario" />
          <br />
          <FormattedMessage id="approach.thinking-learnfromusers-mobile-03" defaultMessage="mediante las" />
          <br />
          <FormattedMessage id="approach.thinking-learnfromusers-mobile-04" defaultMessage="pruebas" />
        </span>

        <div className="circle ideate">
          <CircleImg />
          <div className="span">
            <FormattedMessage id="approach.thinking-ideate" defaultMessage="Idear" />
          </div>
        </div>
        <div className="arrow arrow-ideate">{arrow}</div>
        <span className="learn-from-prototype-mobile-text stacked">
          <FormattedMessage id="approach.thinking-learnfromprototype-mobile-01" defaultMessage="Aprender de" />
          <br />
          <FormattedMessage id="approach.thinking-learnfromprototype-mobile-02" defaultMessage="prototipos" />
          <br />
          <FormattedMessage id="approach.thinking-learnfromprototype-mobile-03" defaultMessage="genera ideas" />
        </span>

        <div className="circle prototype">
          <CircleImg />
          <div className="span">
            <FormattedMessage id="approach.thinking-prototype" defaultMessage="Prototipar" />
          </div>
        </div>

        <span className="try-mobile-text stacked">
          <FormattedMessage id="approach.thinking-try-mobile-01" defaultMessage="Probar" />
          <br />
          <FormattedMessage id="approach.thinking-try-mobile-02" defaultMessage="genera" />
          <br />
          <FormattedMessage id="approach.thinking-try-mobile-03" defaultMessage="nuevas ideas" />
        </span>

        <span className="testing-mobile-text stacked">
          <FormattedMessage id="approach.thinking-testing-mobile-01" defaultMessage="Probar" />
          <br />
          <FormattedMessage id="approach.thinking-testing-mobile-02" defaultMessage="revela ideas" />
          <br />
          <FormattedMessage id="approach.thinking-testing-mobile-03" defaultMessage="que" />
          <br />
          <FormattedMessage id="approach.thinking-testing-mobile-04" defaultMessage="redefinen el" />
          <br />
          <FormattedMessage id="approach.thinking-testing-mobile-05" defaultMessage="problema" />
        </span>

        <div className="arrow arrow-prototype">{arrow}</div>

        <div className="circle test">
          <CircleImg />
          <div className="span">
            <FormattedMessage id="approach.thinking-test" defaultMessage="Test" />
          </div>
        </div>

        <div className="learn-from-prototype">
          <span>
            <FormattedMessage id="approach.thinking-learnfromprototype-01" defaultMessage="Aprender de" /> <br />
            <FormattedMessage id="approach.thinking-learnfromprototype-02" defaultMessage="prototipos" /> <br />
            <FormattedMessage id="approach.thinking-learnfromprototype-03" defaultMessage="genera ideas" />
          </span>
          {learnFromPrototypeArrow}
        </div>
        <div className="testing stacked">
          <span>
            <FormattedMessage id="approach.thinking-testing-01" defaultMessage="Probar revela ideas que" /> <br />
            <FormattedMessage id="approach.thinking-testing-02" defaultMessage="redefinen el problema" />
          </span>
          {testingArrow}
        </div>
      </div>
    </>
  );
};

const Thinking = () => {
  return (
    <section className="approach_thinking">
      <Container>
        <h2>
          <FormattedMessage id="approach.thinking-title" defaultMessage="Design Thinking" />
        </h2>
        <p>
          <FormattedMessage
            id="approach.thinking-description"
            defaultMessage="Como verdaderos innovadores, nos basamos en marcos de pensamiento creativos no convencionales, pero eficaces, que nos proporcionan una visión diferente, más humana, sobre cómo mejorar o desarrollar productos digitales que se centran en los obstáculos reales del usuario, el contexto y las necesidades específicas…"
          />
        </p>
        <ImgThinking />
      </Container>
    </section>
  );
};

export default Thinking;
