// Librerias.
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FormattedMessage } from 'react-intl';

// Assets.
import arrowTitle from './assets/welcome/arrow-title.svg';
import arrowTitleMobile from './assets/welcome/arrow-title-mobile.svg';
import iconPayroll from './assets/welcome/icon-payroll.svg';
import iconPayrollInverted from './assets/welcome/icon-payroll-inverted.svg';
import showcasePayroll from './assets/welcome/showcase-payroll.jpg';
import iconCalendar from './assets/welcome/icon-calendar.svg';
import iconCalendarInverted from './assets/welcome/icon-calendar-inverted.svg';
import showcaseCalendar from './assets/welcome/showcase-calendar.jpg';
import iconDinningRoom from './assets/welcome/icon-dinning-room.svg';
import iconDinningRoomInverted from './assets/welcome/icon-dinning-room-inverted.svg';
import showcaseDinningRoom from './assets/welcome/showcase-dinner-room.jpg';
import iconDisabilities from './assets/welcome/icon-disabilities.svg';
import iconDisabilitiesInverted from './assets/welcome/icon-disabilities-inverted.svg';
import showcaseDisabilities from './assets/welcome/showcase-disabilities.jpg';
import iconQuestionMark from './assets/welcome/icon-question-mark.svg';
import iconQuestionMarkInverted from './assets/welcome/icon-question-mark-inverted.svg';
import showcaseQuestionMark from './assets/welcome/showcase-question-mark.jpg';
import iconRecord from './assets/welcome/icon-record.svg';
import iconRecordInverted from './assets/welcome/icon-record-inverted.svg';
import showcaseRecord from './assets/welcome/showcase-record.jpg';
import iconScrollX from './assets/welcome/icon-scroll-x.svg';
import iconVacations from './assets/welcome/icon-vacations.svg';
import iconVacationsInverted from './assets/welcome/icon-vacations-inverted.svg';
import showcaseVacations from './assets/welcome/showcase-vacations.jpg';
import iconVouchers from './assets/welcome/icon-vouchers.svg';
import iconVouchersInverted from './assets/welcome/icon-vouchers-inverted.svg';
import showcaseVouchers from './assets/welcome/showcase-vouchers.jpg';

const ModulTab = ({ icon, title, active, classHover }) => {
  return (
    <div
      className={active ? `modul-tab ${classHover ? 'active-blue' : 'active'}` : `modul-tab  ${classHover ? 'module-blue' : ''}`}
    >
      <div className="modul-tab__icon">
        <img src={icon} alt="Icon" />
      </div>
      <div className="modul-tab__title">{title}</div>
    </div>
  );
};

const ModulShowcase = ({ showcase }) => {
  return (
    <div className="modul-showcase">
      <img src={showcase} alt="Showcase" className="img-fluid img-showcase"></img>
    </div>
  );
};

const ModulDescription = ({ icon, title, body, customClass = '' }) => {
  return (
    <div className="modul-description">
      <Container>
        <div className={`modul-description__title modul-description__title--${customClass}`}>
          <div className="modul-description__title--icon">
            <img src={icon} alt="Icon" className="icon-desktop" />
          </div>
          <div className="modul-description__title--name">{title}</div>
        </div>

        <div className={`modul-description__body modul-description__body--${customClass}`}>{body}</div>
      </Container>
    </div>
  );
};

const ModulDetail = ({ showcase, icon, title, body, customClass }) => {
  return (
    <>
      <ModulShowcase showcase={showcase}></ModulShowcase>

      <ModulDescription icon={icon} title={title} body={body} customClass={customClass}></ModulDescription>
    </>
  );
};

const ModulDetails = [
  <ModulDetail
    showcase={showcasePayroll}
    icon={iconPayrollInverted}
    title={<FormattedMessage id="mycompany.welcome-payroll-title" defaultMessage="RECIBOS DE NÓMINA" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-payroll-description"
        defaultMessage="El empleado podrá acceder a un listado para consultar el detalle en línea y/o enviar por correo todos sus recibos de nómina, filtrándolos por tipo de nómina y fecha, desde que comenzó a laborar en la empresa. Al enviarse a sí mismo y/o a alguien más un recibo, el correo contendrá tanto la representación visual del CFDI en formato PDF como el archivo XML del mismo."
      />
    }
  />,

  <ModulDetail
    showcase={showcaseVacations}
    icon={iconVacationsInverted}
    title={<FormattedMessage id="mycompany.welcome-vacations-title" defaultMessage="VACACIONES Y PERMISOS" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-vacations-description"
        defaultMessage="El empleado puede consultar el desglose de sus días de vacaciones y/o permisos (matrimonio, día flotante, paternidad…) gozados, así como el saldo de sus días disponibles. Asimismo, puede hacer una solicitud a su supervisor para ocupar días y/o permisos disponibles."
      />
    }
  />,

  <ModulDetail
    showcase={showcaseDisabilities}
    icon={iconDisabilitiesInverted}
    title={<FormattedMessage id="mycompany.welcome-disabilities-title" defaultMessage="INCAPACIDADES" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-disabilities-description"
        defaultMessage="El empleado podrá visualizar un listado de sus incapacidades, por fecha y tipo de incapacidad, así como el detalle de los días previos o posteriores que éstas abarcan. Este módulo se alimenta con la información capturada por el área de Servicio Médico de la empresa, o el responsable indicado, en el módulo “Registro de Incapacidades” de iNTEGRITY."
      />
    }
  />,

  <ModulDetail
    showcase={showcaseDinningRoom}
    icon={iconDinningRoomInverted}
    title={<FormattedMessage id="mycompany.welcome-dinningroom-title" defaultMessage="COMEDOR" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-dinningroom-description"
        defaultMessage="El empleado podrá consultar y/o enviar por correo su reporte histórico de consumos, así como generar y/o enviar por correo su Código QR personal para acceder a la Cafetería, como una de las tres opciones que DiCOMA ofrece para autentificar el acceso (acercando su credencial de empleado (RFDI), ingresando manualmente su número de empleado y/o mostrando un código personal QR)."
      />
    }
  />,

  <ModulDetail
    showcase={showcaseRecord}
    icon={iconRecordInverted}
    title={<FormattedMessage id="mycompany.welcome-record-title" defaultMessage="CONSTANCIAS LABORALES" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-record-description"
        defaultMessage="El empleado podrá SOLICITAR diferentes tipos de constancias laborales (General, Fonacot, Guardería, Renta de Vivienda, Visa…) emitidos por la empresa para llevar a acabo trámites en donde se requieran. Y recogerlas firmadas en el área de correspondiente."
      />
    }
  />,

  <ModulDetail
    showcase={showcaseVouchers}
    icon={iconVouchersInverted}
    title={<FormattedMessage id="mycompany.welcome-vouchers-title" defaultMessage="VALES DE DESPENSA" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-vouchers-description"
        defaultMessage="El empleado podrá solicitar la reposición de su tarjeta de vales de despensa. Para reponerla, primero deberá reportarla por teléfono al proveedor, donde le indicarán un número de folio, el cual deberá ingresar al solicitar la reposición. Una vez hecho esto, podrá pasar a recoger su nueva tarjeta, 24 horas después, en el área correspondiente."
      />
    }
  />,

  <ModulDetail
    showcase={showcaseCalendar}
    icon={iconCalendarInverted}
    title={<FormattedMessage id="mycompany.welcome-calendar-title" defaultMessage="CALENDARIO DE TRABAJO" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-calendar-description"
        defaultMessage="El empleado podrá acceder al Calendario de Trabajo donde verá reflejados los días de descanso y festivos generales, así sus propios días de naciones y/o permisos. Y podrá enviárselo a sí mismo, por correo electrónico."
      />
    }
  />,

  <ModulDetail
    showcase={showcaseQuestionMark}
    icon={iconQuestionMarkInverted}
    title={<FormattedMessage id="mycompany.welcome-questionmark-title" defaultMessage="MÓDULOS A LA MEDIDA" />}
    body={
      <FormattedMessage
        id="mycompany.welcome-questionmark-description"
        defaultMessage="Estamos listos para profundizar en los retos que enfrenta, de cara a la automatización de tareas y/o procesos que ayuden a tener una operación más fluida. Podemos adaptar cualquiera de nuestros módulos a sus requerimientos específicos, o bien, desarrollar módulos completamente nuevos y personalizados."
      />
    }
    customClass="blue"
  />,
];

const Welcome = () => {
  const [position, setPosition] = useState(0);
  return (
    <section className="my_company_welcome">
      <Container>
        <div className="title">
          <div>
            <FormattedMessage id="mycompany.welcome-title" defaultMessage="Bienvenidos a: " />
          </div>
          <div className="title__company">
            <FormattedMessage id="mycompany.welcome-title-company" defaultMessage="My " />
            <span className="title__company--name">
              <FormattedMessage id="mycompany.welcome-title-company-name" defaultMessage="[COMPANY]" />
              <div className="name-description gloria">
                <img src={arrowTitle} alt="arrow title" className="img-fluid hide-on-mobile" />
                <span>
                  <FormattedMessage id="mycompany.welcome-title-company-name-description01" defaultMessage="Nombre de su" />
                  <br />
                  <FormattedMessage id="mycompany.welcome-title-company-name-description02" defaultMessage="empresa aquí" />
                </span>
                <img src={arrowTitleMobile} alt="arrow title" className="img-fluid img-mobile hide-on-desktop" />
              </div>
            </span>
            <span className="title__company--registered">
              <FormattedMessage id="mycompany.welcome-title-company-registered" defaultMessage="®" />
            </span>
          </div>
        </div>
        <p className="description">
          <FormattedMessage
            id="mycompany.welcome-description"
            defaultMessage="La PLATAFORMA DIGITAL en la que todos los integrantes de su equipo pueden realizar trámites, en línea y no en ventanilla, derivados o relacionados con el pago de su nómina. Esta aplicación se conecta con nuestro sistema de nóminas, o con cualquier otro sistema, mediante web-services que garantizan el intercambio en-tiempo-real con la base de datos de información personal de los empleados."
          />
        </p>

        <p className="text-center">
          <FormattedMessage id="mycompany.welcome-knowmodules" defaultMessage="Conozca los módulos básicos de la aplicación:" />
        </p>
      </Container>
      <ScrollContainer className="moduls">
        <div onClick={() => setPosition(0)}>
          <ModulTab
            active={position === 0}
            icon={iconPayroll}
            title={<FormattedMessage id="mycompany.welcome-tab-payroll" defaultMessage="RECIBOS DE NÓMINA" />}
          ></ModulTab>
        </div>
        <div onClick={() => setPosition(1)}>
          <ModulTab
            active={position === 1}
            icon={iconVacations}
            title={<FormattedMessage id="mycompany.welcome-tab-vacations" defaultMessage="VACACIONES Y PERMISOS" />}
          ></ModulTab>
        </div>
        <div onClick={() => setPosition(2)}>
          <ModulTab
            active={position === 2}
            icon={iconDisabilities}
            title={<FormattedMessage id="mycompany.welcome-tab-disabilities" defaultMessage="INCAPACIDADES" />}
          ></ModulTab>
        </div>
        <div onClick={() => setPosition(3)}>
          <ModulTab
            active={position === 3}
            icon={iconDinningRoom}
            title={<FormattedMessage id="mycompany.welcome-tab-dinningroom" defaultMessage="COMEDOR" />}
          ></ModulTab>
        </div>
        <div onClick={() => setPosition(4)}>
          <ModulTab
            active={position === 4}
            icon={iconRecord}
            title={<FormattedMessage id="mycompany.welcome-tab-record" defaultMessage="CONSTANCIAS LABORALES" />}
          ></ModulTab>
        </div>
        <div onClick={() => setPosition(5)}>
          <ModulTab
            active={position === 5}
            icon={iconVouchers}
            title={<FormattedMessage id="mycompany.welcome-tab-vouchers" defaultMessage="VALES DE DESPENSA" />}
          ></ModulTab>
        </div>
        <div onClick={() => setPosition(6)}>
          <ModulTab
            active={position === 6}
            icon={iconCalendar}
            title={<FormattedMessage id="mycompany.welcome-tab-calendar" defaultMessage="CALENDARIO DE TRABAJO" />}
          ></ModulTab>
        </div>
        <div onClick={() => setPosition(7)}>
          <ModulTab
            active={position === 7}
            icon={iconQuestionMark}
            title={<FormattedMessage id="mycompany.welcome-tab-questionmark" defaultMessage="MÓDULOS A LA MEDIDA" />}
            classHover="active-blue"
          ></ModulTab>
        </div>
        <div className="modul-tab--empty"></div>
      </ScrollContainer>
      <div className="modul-tab--scroll">
        <img src={iconScrollX} alt="scroll"></img>
      </div>

      {/* here detail */}
      {ModulDetails[position]}
    </section>
  );
};
export default Welcome;
