// Librerias.
import React from 'react';

// SVG's.
import ourMotivationSvg from './assets/svg/our-motivation.svg';
import ourMotivationMobileSvg from './assets/svg/our-motivation-mobile.svg';
import mexicoSmileSvg from './assets/svg/mexico-smile.svg';
import iconOurGoalSvg from './assets/svg/icon-our-goal.svg';
import iconOurGoalMobile1Svg from './assets/svg/icon-our-goal-mobile1.svg';
import iconOurGoalMobile2Svg from './assets/svg/icon-our-goal-mobile2.svg';
import illustMissionSvg from './assets/svg/illust-mission.svg';
import illustVisionSvg from './assets/svg/illust-vision.svg';
import iconArrowSvg from './assets/svg/icon-arrow.svg';
import iconTeamNomiSvg from './assets/svg/icon-team-nomi.svg';
import ourCultureBgSvg from './assets/svg/our-culture-bg.svg';
import ourCultureMobileBgSvg from './assets/svg/our-culture-mobile-bg.svg';
import iconCareSvg from './assets/svg/icon-care.svg';
import iconClaritySvg from './assets/svg/icon-clarity.svg';
import iconIntegritySvg from './assets/svg/icon-integrity.svg';
import iconPersistenceSvg from './assets/svg/icon-persistence.svg';
import checkWhiteSvg from './assets/svg/check-white.svg';
import arrowMobileSvg from './assets/svg/arrow-mobile.svg';

// Images.
import imgOurHistory from './assets/img/our-history.jpg';

export const ImgOurHistory = () => {
  return <img src={imgOurHistory} alt="Nuestra Historia" width="462px" height="968px" className="img-fluid" />;
};

export const OurMotivationSvg = () => {
  return <img src={ourMotivationSvg} alt="Nuestra Motivacion" width="537" height="98" className="img-fluid" />;
};

export const OurMotivationMobileSvg = () => {
  return <img src={ourMotivationMobileSvg} alt="Nuestra Motivacion" className="img-fluid" />;
};

export const IconOurGoalSvg = () => {
  return <img src={iconOurGoalSvg} alt="Icono de Nuestra Meta" className="img-fluid" />;
};

export const IconOurGoalMobile1Svg = () => {
  return <img src={iconOurGoalMobile1Svg} alt="Icono de Nuestra Meta para movil 1" className="img-fluid card__arrow" />;
};

export const IconOurGoalMobile2Svg = () => {
  return <img src={iconOurGoalMobile2Svg} alt="Icono de Nuestra Meta para movil 2" className="img-fluid card__arrow" />;
};

export const IllustMissionSvg = () => {
  return <img src={illustMissionSvg} alt="Illustración Misión" className="img-fluid card__illust" />;
};

export const IllustVisionSvg = () => {
  return <img src={illustVisionSvg} alt="Illustración Visión" className="img-fluid card__illust" />;
};

export const IconArrowSvg = () => {
  return <img src={iconArrowSvg} alt="Arrow Icon" />;
};

export const IconTeamNomiSvg = () => {
  return <img src={iconTeamNomiSvg} className="img-fluid" alt="Team Nomi Icon" />;
};

export const OurCultureBgSvg = () => {
  return <img src={ourCultureBgSvg} alt="Our Culture Background" className="img-fluid the-company_our-culture__bg" />;
};

export const OurCultureMobileBgSvg = () => {
  return (
    <img
      src={ourCultureMobileBgSvg}
      alt="Our Culture Mobile Background"
      className="img-fluid the-company_our-culture-mobile__bg"
    />
  );
};
export const IconCareSvg = () => {
  return <img src={iconCareSvg} alt="Icon Care" className="" />;
};
export const IconClaritySvg = () => {
  return <img src={iconClaritySvg} alt="Icon Clarity" className="" />;
};
export const IconIntegritySvg = () => {
  return <img src={iconIntegritySvg} alt="Icon Integrity" className="" />;
};
export const IconPersistenceSvg = () => {
  return <img src={iconPersistenceSvg} alt="Icon Persistence" className="" />;
};

export const MexicoSmileSvg = () => {
  return <img src={mexicoSmileSvg} alt="Mexico smile" className="img-fluid" />;
};

export const CheckWhiteSvg = () => {
  return <img src={checkWhiteSvg} alt="Check White Icon" className="icon" />;
};

export const ArrowMobileSvg = () => {
  return <img src={arrowMobileSvg} alt="Arrow Our Goal Mobile" className="img-fluid" />;
};
