// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Container } from 'react-bootstrap';
import { Button } from '../components-base';

// Assets.
import { IconArrowSvg, IconMexicoSvg } from './Assets';
import whatBgMobile from './assets/svg/contact-nomi-mobile.svg';

const Contact = () => {
  const language = localStorage.getItem('lang');
  const showContactForm = language === 'es-MX' ? './contact' : './contact/sales';

  return (
    <section className="home_contact">
      <Container>
        <div className="home_contact__info">
          <h1 className="title">
            <IconMexicoSvg />
          </h1>
          <p className="description">
            <FormattedMessage
              id="home.contact-description"
              defaultMessage="¿Requiere soluciones a la medida que se adapten a las condiciones cambiantes de sus negocios, en México?"
            />
          </p>
          <Button to={showContactForm} width="320" className="btn btn-desktop">
            <FormattedMessage id="home.contact-btn" defaultMessage="Contáctenos" />
            <span>
              <IconArrowSvg className="icon-arrow"></IconArrowSvg>
            </span>
          </Button>
          <Button to={showContactForm} className="btn btn-mobile">
            <FormattedMessage id="home.contact-btn" defaultMessage="Contáctenos" />
            <span>
              <IconArrowSvg className="icon-arrow"></IconArrowSvg>
            </span>
          </Button>
        </div>
      </Container>
      <div className="home_contact__bg"></div>
      <img src={whatBgMobile} alt="asdasd" className="home_contact__bg-mobile-img"></img>
    </section>
  );
};

export default Contact;
