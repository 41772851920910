// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Header } from '../components-base';

// Assets.
import heroBgD from './assets/img/hero-bg-d.jpg';
import heroBgT from './assets/img/hero-bg-t.jpg';
import heroBgP from './assets/img/hero-bg-p.jpg';

const Hero = () => {
  return (
    <Header uri_bg_d={heroBgD} uri_bg_t={heroBgT} uri_bg_p={heroBgP} className="my_company_hero" height={1547}>
      <Container>
        <div className="layout-title">
          <h1 className="">
            <FormattedMessage id="mycompany.hero" defaultMessage="La información personal de sus empleados donde debe estar..." />
          </h1>

          <h1 className="sub-title">
            <FormattedMessage id="mycompany.hero-bold" defaultMessage="¡EN SUS MANOS!" />
          </h1>
        </div>
      </Container>
      <div className="my_company_hero__bg img-fluid"></div>
    </Header>
  );
};
export default Hero;
