// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
// Components Base.
import Button from './Button';
const Contact = ({ title }) => {
  const language = localStorage.getItem('lang');
  const showContactForm = language === 'es-MX' ? './contact' : './contact/sales';

  return (
    <section className="contact">
      <div className="purple-line-mobile"></div>
      <Container className="text-center">
        <p className="mb-lg-4 mb-3">{title}</p>
        <h1 className="mb-lg-5 mb-3">
          <FormattedMessage id="components.contact" defaultMessage="¡Compruébelo usted mism@!" />
        </h1>
        <div className="d-flex justify-content-center">
          <Button to={showContactForm} width="260">
            <FormattedMessage id="components.contact-btn" defaultMessage="Contáctenos" />
          </Button>
        </div>
      </Container>
    </section>
  );
};
export default Contact;
