// Librerias.
import React from 'react';
import { Container, Dropdown, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Components Base.
import { Footer } from '../components-base';
// Components Contact.
import ReturnToForms from './ReturnToForms';
import Hero from './Hero';
import { Sales } from './ApiCalls';
// Assets.
import sales from './assets/img/sales.svg';
// Hooks.
import { useForm } from 'react-hook-form';

const PageContactSales = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const history = useHistory();

  const onSubmit = async (data, e) => {
    console.log(data);
    var formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('ubication', data.ubication);
    formData.append('company', data.company);
    formData.append('line', data.line);
    formData.append('role', data.role);
    formData.append('employees', data.employees);
    formData.append('maquila', data.maquila);
    formData.append('cfdis', data.cfdis);
    formData.append('software', data.software);
    formData.append('integrity', data.integrity);
    formData.append('my_company', data.my_company);
    formData.append('dicoma', data.dicoma);
    formData.append('comments', data.comments);
    const res = await Sales(formData);
    if (res.status === 200) {
      history.push('/contact/sales/success');
      e.target.reset();
    } else {
      history.push('/error');
    }
    //redireccionar hacia mensaje de exito
  };
  const language = localStorage.getItem('lang');
  const showReturnSvg = language === 'es-MX' ? 'show' : 'hide';

  return (
    <>
      <Hero />
      <section className="contact_sales">
        <Container>
          <ReturnToForms to={'../contact'} className={showReturnSvg} />
          <div className="wrapper">
            <img src={sales} alt="Sales Icon" className="icon" />
            <h3 className="">
              <FormattedMessage id="contact.sales" defaultMessage="¿Desea contactar con nuestro equipo de ventas?" />
            </h3>
            <p className="description">
              <FormattedMessage
                id="contact.sales-description"
                defaultMessage="Favor de compartirnos su información de contacto para poder atenderle…"
              />
            </p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form_group">
                <label className="form_group--label" htmlFor="name">
                  <FormattedMessage id="contact.sales-name" defaultMessage="Nombre completo" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  {...register('name', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.sales-name-required" defaultMessage="El nombre es obligatorio" />,
                    },
                  })}
                />
                <small className="text-danger"> {errors.name && errors.name.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="email">
                  <FormattedMessage id="contact.sales-email" defaultMessage="Correo electrónico" />
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register('email', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.sales-email-required" defaultMessage="El email es obligatorio" />,
                    },
                  })}
                />
                <small className="text-danger"> {errors.email && errors.email.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="phone">
                  <FormattedMessage id="contact.sales-phone" defaultMessage="Teléfono" />
                </label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  {...register('phone', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.sales-phone-required" defaultMessage="El teléfono es obligatorio" />,
                    },
                    minLength: {
                      value: 10,
                      message: (
                        <FormattedMessage
                          id="contact.sales-phone-required-minlength"
                          defaultMessage="Favor de ingresar 10 dígitos"
                        />
                      ),
                    },
                    maxLength: {
                      value: 10,
                      message: (
                        <FormattedMessage
                          id="contact.sales-phone-required-maxlength"
                          defaultMessage="Favor de ingresar solo 10 dígitos"
                        />
                      ),
                    },
                  })}
                />
                <small className="text-danger"> {errors.phone && errors.phone.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="company">
                  <FormattedMessage id="contact.sales-company" defaultMessage="Empresa" />
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  {...register('company', {
                    required: {
                      value: true,
                      message: (
                        <FormattedMessage id="contact.sales-company-required" defaultMessage="La empresa es obligatoria" />
                      ),
                    },
                  })}
                />
                <small className="text-danger"> {errors.company && errors.company.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="role">
                  <FormattedMessage id="contact.sales-role" defaultMessage="Puesto que desempeña" />
                </label>
                <input
                  type="text"
                  id="role"
                  name="role"
                  {...register('role', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.sales-role-required" defaultMessage="El puesto es obligatorio" />,
                    },
                  })}
                />
                <small className="text-danger"> {errors.role && errors.role.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="line">
                  <FormattedMessage id="contact.sales-line" defaultMessage="Giro de la empresa" />
                </label>
                <input
                  type="text"
                  id="line"
                  name="line"
                  {...register('line', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.sales-line-required" defaultMessage="El giro es obligatorio" />,
                    },
                  })}
                />
                <small className="text-danger"> {errors.line && errors.line.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="ubication">
                  <FormattedMessage id="contact.sales-ubication" defaultMessage="Ubicación de la empresa" />
                </label>
                <div className="form__dropdown-menu">
                  <div className="custom-select">
                    <select
                      id="ubication"
                      name="ubication"
                      {...register('ubication', {
                        required: (
                          <FormattedMessage id="contact.sales-ubication-required" defaultMessage="La ubicación es obligatoria" />
                        ),
                      })}
                    >
                      <FormattedMessage id="contact.dropdown-placeholder" defaultMessage="Selecciona una opción">
                        {(message) => (
                          <option value="" disabled selected>
                            {message}
                          </option>
                        )}
                      </FormattedMessage>
                      <option value="Aguascalientes"> Aguascalientes </option>
                      <option value="Baja California"> Baja California </option>
                      <option value="Baja California Sur">Baja California Sur </option>
                      <option value="Campeche"> Campeche </option> <option value="Chiapas"> Chiapas </option>
                      <option value="Chihuahua"> Chihuahua </option> <option value="Coahuila"> Coahuila </option>
                      <option value="Colima"> Colima </option> <option value="Durango"> Durango </option>
                      <option value="Guanajuato"> Guanajuato </option> <option value="Guerrero"> Guerrero </option>
                      <option value="Hidalgo"> Hidalgo </option> <option value="Jalisco"> Jalisco </option>
                      <option value="Estado de Mexico"> Estado de México </option> <option value="Michoacan"> Michoacán </option>
                      <option value="Morelos"> Morelos </option> <option value="Nuevo Leon"> Nuevo León </option>
                      <option value="Oaxaca"> Oaxaca </option> <option value="Puebla"> Puebla </option>
                      <option value="Queretaro"> Querétaro </option> <option value="Quintana Roo"> Quintana Roo </option>
                      <option value="San Luis Potosi"> San Luis Potosí </option> <option value="Sinaloa"> Sinaloa </option>
                      <option value="Sonora"> Sonora </option> <option value="Tabasco"> Tabasco </option>
                      <option value="Tamaulipas"> Tamaulipas </option> <option value="Tlaxcala"> Tlaxcala </option>
                      <option value="Veracruz"> Veracruz </option> <option value="Yucatan"> Yucatán </option>
                      <option value="Zacatecas"> Zacatecas </option> <option value="Otro"> Otro </option>
                    </select>
                    <span className="custom-arrow"> </span>
                  </div>
                </div>
                <small className="text-danger"> {errors.ubication && errors.ubication.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="employees">
                  <FormattedMessage id="contact.sales-employees" defaultMessage="Cantidad de empleados" />
                </label>
                <div className="form__dropdown-menu">
                  <div className="custom-select">
                    <select
                      id="employees"
                      name="employees"
                      {...register('employees', {
                        required: (
                          <FormattedMessage
                            id="contact.sales-employees-required"
                            defaultMessage="La cantidad de empleados es obligatoria"
                          />
                        ),
                      })}
                    >
                      {/* <FormattedMessage id="" defaultMessage="" /> */}
                      <FormattedMessage id="contact.dropdown-placeholder" defaultMessage="Selecciona una opción">
                        {(message) => (
                          <option value="" disabled selected>
                            {message}
                          </option>
                        )}
                      </FormattedMessage>
                      <FormattedMessage id="contact.sales-employees-dropdown-01" defaultMessage="0 - 100 empleados">
                        {(message) => <option value="0 - 100">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="contact.sales-employees-dropdown-02" defaultMessage="101 - 500 empleados">
                        {(message) => <option value="101 - 500">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="contact.sales-employees-dropdown-03" defaultMessage="501 - 1000 empleados">
                        {(message) => <option value="501 - 1000">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="contact.sales-employees-dropdown-04" defaultMessage="1001 - 2000 empleados">
                        {(message) => <option value="1001 - 2000">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="contact.sales-employees-dropdown-05" defaultMessage="2001 - 3000 empleados">
                        {(message) => <option value="2001 - 3000">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="contact.sales-employees-dropdown-06" defaultMessage="Más de 3000 empleados">
                        {(message) => <option value="+3000">{message}</option>}
                      </FormattedMessage>
                    </select>
                    <span className="custom-arrow"> </span>
                  </div>
                </div>
                <small className="text-danger"> {errors.employees && errors.employees.message} </small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="dropdown-autoclose-outside">
                  <FormattedMessage id="contact.sales-service" defaultMessage="Servicio y / o producto de su interés" />
                </label>
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="success" id="dropdown-autoclose-outside" name="multiselect">
                    <FormattedMessage id="contact.sales-service-multiselect" defaultMessage="Elije 1 ó varias opciones..." />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-3">
                    <Form.Check
                      type={'checkbox'}
                      id={`maquila`}
                      label={<FormattedMessage id="contact.sales-service-multiselect-01" defaultMessage="Maquila de Nómina" />}
                      name="maquila"
                      {...register('maquila', {
                        required: false,
                      })}
                      value="INTERÉS"
                    />

                    <Form.Check
                      type={'checkbox'}
                      id={`cfdis`}
                      label={<FormattedMessage id="contact.sales-service-multiselect-02" defaultMessage="Gestión de CFDI'S" />}
                      name="cfdis"
                      {...register('cfdis', {
                        required: false,
                      })}
                      value="INTERÉS"
                    />

                    <Form.Check
                      type={'checkbox'}
                      id={`software`}
                      label={
                        <FormattedMessage id="contact.sales-service-multiselect-03" defaultMessage="Desarrollo de Software" />
                      }
                      name="software"
                      {...register('software', {
                        required: false,
                      })}
                      value="INTERÉS"
                    />

                    <Form.Check
                      type={'checkbox'}
                      id={`integrity`}
                      label={`iNTEGRITY`}
                      name="integrity"
                      {...register('integrity', {
                        required: false,
                      })}
                      value="INTERÉS"
                    />

                    <Form.Check
                      type={'checkbox'}
                      id={`my_company`}
                      label={`My [Company]`}
                      name="my_company"
                      {...register('my_company', {
                        required: false,
                      })}
                      value="INTERÉS"
                    />

                    <Form.Check
                      type={'checkbox'}
                      id={`dicoma`}
                      label={`DICOMA`}
                      name="dicoma"
                      {...register('dicoma', {
                        required: false,
                      })}
                      value="INTERÉS"
                    />
                  </Dropdown.Menu>
                </Dropdown>
                {/* <small className="text-danger">*Se requiere minimo un Servicio y/o producto</small> */}
              </div>
              <div className="form_group comments">
                <label className="form_group--label" htmlFor="comments">
                  <FormattedMessage id="contact.sales-comments" defaultMessage="Comentarios" />
                </label>

                <FormattedMessage
                  id="contact.sales-comments-placeholder"
                  defaultMessage="Favor de describir brevemente sus necesidades…"
                >
                  {(message) => (
                    <textarea
                      placeholder={message}
                      id="comments"
                      name="comments"
                      {...register('comments', {
                        required: {
                          value: true,
                          message: (
                            <FormattedMessage id="contact.sales-comments-required" defaultMessage="Este campo es obligatorio" />
                          ),
                        },
                      })}
                    ></textarea>
                  )}
                </FormattedMessage>
                <small className="text-danger"> {errors.comments && errors.comments.message} </small>
              </div>
              <button type="submit" className="btn-form">
                <FormattedMessage id="contact.sales-btn" defaultMessage="ENVIAR" />
              </button>
            </form>
          </div>
        </Container>
        <div className="wrapper__bg--mobile"></div>
      </section>
      <Footer />
    </>
  );
};
export default PageContactSales;
