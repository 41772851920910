// Librerias.
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader';
import { LangProvider } from './context/langContext';

// Estilos.
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss';

WebFont.load({
  google: {
    families: ['Ubuntu:300,400,500,700', 'Gloria Hallelujah:400', 'sans-serif', 'cursive'],
  },
});

ReactDOM.render(
  <LangProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </LangProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
