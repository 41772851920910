// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Assets.
import {
  CalculationSvg,
  CommunicationSvg,
  IncidentsSvg,
  InteractionSvg,
  Num1Svg,
  Num2Svg,
  Num3Svg,
  Num4Svg,
  Num5Svg,
  Num6Svg,
  Num7Svg,
  ParametersSvg,
  RetrospectiveSvg,
  SpecialProcessesSvg,
} from './assets';

const Title = ({ id, iconNumber, iconService, title, description }) => {
  return (
    <div>
      <div className="payroll_title" id={id}>
        <div className="d-flex align-items-center icons">
          <div className="icon-number">{iconNumber}</div>
          <div className="icon-service">{iconService}</div>
        </div>
        <div className="title">{title}</div>
      </div>
      <div className={`payroll_description`}>{description}</div>
    </div>
  );
};

export const TitleParameters = () => {
  return (
    <Title
      iconNumber={<Num1Svg />}
      iconService={<ParametersSvg />}
      title={<FormattedMessage id="payroll.titles-parameters" defaultMessage="PARÁMETROS" />}
      id="parameters"
      description={
        <>
          <p>
            <FormattedMessage
              id="payroll.titles-parameters-description"
              defaultMessage="Aquí comenzamos, cada periodo de nómina. Una configuración continua de nuestro sistema con datos fijos, pre-establecidos y/o dinámicos que se convertirá en la base para el cálculo de la nómina. Esta información se clasifica en diferentes grupos y etapas:"
            />
          </p>
          <ul className="fw-bold list-unstyled">
            <li>
              <FormattedMessage id="payroll.titles-parameters-implementation" defaultMessage="1.1 Implementación" />
            </li>
            <li>
              <FormattedMessage id="payroll.titles-parameters-annualupdate" defaultMessage="1.2 Actualización Anual" />
            </li>
            <li>
              <FormattedMessage
                id="payroll.titles-parameters-continuousupdate"
                defaultMessage="1.3 Actualización Continua (cada periodo de nómina)"
              />
            </li>
          </ul>
        </>
      }
    />
  );
};
export const TitleInteraction = () => {
  return (
    <Title
      iconNumber={<Num2Svg />}
      iconService={<InteractionSvg />}
      title={<FormattedMessage id="payroll.titles-interaction" defaultMessage="INTERACCIÓN" />}
      id="interaction"
      description={
        <>
          <p>
            <FormattedMessage
              id="payroll.titles-interaction-description"
              defaultMessage="A través de módulos especializados dentro de nuestra app iNTEGRITY, nuestro ejecutivo de cuenta y su equipo podrán generar y/o administrar información de los empleados que después se convertirá en las incidencias del periodo o información de entrada para los procesos especiales…"
            />
          </p>
        </>
      }
    />
  );
};
export const TitleIncidents = () => {
  return (
    <Title
      iconNumber={<Num3Svg />}
      iconService={<IncidentsSvg />}
      title={<FormattedMessage id="payroll.titles-incidents" defaultMessage="INCIDENCIAS" />}
      id="incidents"
      description={
        <p>
          <FormattedMessage
            id="payroll.titles-incidents-description"
            defaultMessage="Esta información, que varía de un periodo a otro, se forma de la interacción entre diferentes áreas y proveedores, con la ayuda de aplicaciones como la de Registro de Asistencia o gestión de Consumos del comedor, entre otras. Y se convierte, junto con los Parámetros del periodo, en la base para determinar los salarios del mismo…"
          />
        </p>
      }
    />
  );
};
export const TitleCalculation = () => {
  return (
    <Title
      iconNumber={<Num4Svg />}
      iconService={<CalculationSvg />}
      title={<FormattedMessage id="payroll.titles-calculation" defaultMessage="CÁLCULO" />}
      id="calculation"
      description={
        <p>
          <FormattedMessage
            id="payroll.titles-calculation-description"
            defaultMessage="Una vez que recopilamos y validamos todos los parámetros e incidencias del periodo, la información está lista para ser procesada en nuestro sistema de nóminas en la nube, que estratégicamente corre sobre uno de los servidores de procesamiento de datos más seguros, estables y rápidos del mercado: "
          />
          <span className="fw-bold">
            <FormattedMessage id="payroll.titles-calculation-description-bold" defaultMessage="IBM-i Series" />
          </span>
        </p>
      }
    />
  );
};
export const TitleSpecialProcesses = () => {
  return (
    <Title
      iconNumber={<Num5Svg />}
      iconService={<SpecialProcessesSvg />}
      title={<FormattedMessage id="payroll.titles-specialprocesses" defaultMessage="PROCESOS ESPECIALES" />}
      id="specialProcesses"
      description={
        <p>
          <FormattedMessage
            id="payroll.titles-specialprocesses-description"
            defaultMessage="Son procesos, adicionales al cálculo de nómina, que se tienen que ejecutar por periodo, mensual o anualmente, conforme al cumplimiento de las responsabilidades fiscales o laborales. Así como el envío de toda la información necesaria al área de finanzas para cumplir con los requerimientos contables."
          />
        </p>
      }
    />
  );
};
export const TitleCummunication = () => {
  return (
    <Title
      iconNumber={<Num6Svg />}
      iconService={<CommunicationSvg />}
      title={<FormattedMessage id="payroll.titles-communication" defaultMessage="COMUNICACIÓN" />}
      id="communication"
      description={
        <p>
          <FormattedMessage
            id="payroll.titles-communication-description"
            defaultMessage="Dado que generamos y gestionamos información privada de todos sus empleados, buscamos ser claros y comunicativos en todos nuestros procesos, con el fin de evitar las aclaraciones solicitadas al equipo de nóminas/RRHH y ayudarles a proporcionar una mejor experiencia de cliente interno hacia el resto de la empresa."
          />
        </p>
      }
    />
  );
};

export const TitleRetrospective = () => {
  return (
    <Title
      iconNumber={<Num7Svg />}
      iconService={<RetrospectiveSvg />}
      title={<FormattedMessage id="payroll.titles-retrospective" defaultMessage="RETROSPECTIVA" />}
      id="retrospective"
      description={
        <p className="ayroll_maquila_retrospective__description">
          <FormattedMessage
            id="payroll.titles-retrospective-description"
            defaultMessage="Estamos determinados a mejorar nuestros procesos y experiencia de servicio, de un cálculo de nómina a otro. Por eso, después de cada periodo, nos reunimos en una sesión retrospectiva para evaluar y definir acciones de mejora específicas para el siguiente periodo."
          />
        </p>
      }
    />
  );
};
