// Librerias.
import React from "react";
// Components Base.
import { Footer } from "../components-base";

// Components Contact.
import Hero from "./Hero";
import Forms from "./Forms";

const PageContact = () => {
  return (
    <>
      <Hero />
      <Forms />
      <Footer />
    </>
  );
};

export default PageContact;
