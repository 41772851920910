// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import { PersonImg, WatchImg, WomanImg } from './Assets';

const Step1 = () => {
  return (
    <>
      <div className="approach_steps__1">
        <div className="approach_steps__1--text-left">
          <h3>
            <FormattedMessage id="approach.steps-conscience" defaultMessage="Conciencia" />
          </h3>
          <p>
            <FormattedMessage
              id="approach.steps-conscience-p1"
              defaultMessage="Trabajamos para compañías de Alto Rendimiento. Por lo que entendemos la importancia de la Alta Disponibilidad, no solo de nuestra infraestructura tecnológica, sino especialmente de nuestro soporte al cliente 24x7."
            />
          </p>

          <p>
            <FormattedMessage
              id="approach.steps-conscience-p2"
              defaultMessage="El equipo NOMI está siempre atento y listo para atender cualquier situación respecto a su proceso de nómina, con el fin de ayudar a su equipo a superar cualquier obstáculo, esperado e inesperado, que pueda enfrentar."
            />
          </p>
        </div>
        <WatchImg className="approach_steps__1--img-right img-fluid" />
      </div>
    </>
  );
};
const Step2 = () => {
  return (
    <>
      <div className="approach_steps__2">
        <div className="approach_steps__2--text-right">
          <h3>
            <FormattedMessage id="approach.steps-training" defaultMessage="Capacitación" />
          </h3>
          <p>
            <FormattedMessage
              id="approach.steps-training-p1"
              defaultMessage="Hemos desarrollado una cultura creativa orientada al servicio, donde la innovación y la excelente atención al cliente son los objetivos más importantes."
            />
          </p>
          <p>
            <FormattedMessage
              id="approach.steps-training-p2"
              defaultMessage="Para mantener esto, no sólo instruimos a nuestro equipo sobre cómo generar ideas u ofrecer un servicio excelente, sino que también nos esforzamos por preservar un ambiente en el que las personas se sientan cómodas y comprometidas a hacerlo."
            />
          </p>
        </div>
        <PersonImg className="approach_steps__2--img-left img-fluid" />
      </div>
    </>
  );
};
const Step3 = () => {
  return (
    <>
      <div className="approach_steps__3">
        <div className="aproach_steps__3--text-left">
          <h3>
            <FormattedMessage id="approach.steps-improvement" defaultMessage="Mejora Continua" />
          </h3>
          <p>
            <FormattedMessage
              id="approach.steps-improvement-p1"
              defaultMessage="Porque estamos convencidos de que las personas sobresalientes crean equipos sobresalientes, y “un equipo sobresaliente equivale a un servicio extraordinario”…"
            />
          </p>
          <p>
            <FormattedMessage
              id="approach.steps-improvement-p2"
              defaultMessage="Damos a cada miembro la posibilidad de desarrollarse, personal y profesionalmente, para crecer dentro de la organización. Proporcionándoles las herramientas y la formación adecuadas para que logren sus propios objetivos a su propio ritmo."
            />
          </p>
        </div>
        <WomanImg className="approach_steps__3--img-right img-fluid" />
      </div>
    </>
  );
};
const Steps = () => {
  return (
    <>
      <div className="approach_steps__wrapper">
        <div className="approach_steps__wrapper--steps">
          <Container>
            <Step1 />
            <Step2 />
            <Step3 />
          </Container>
        </div>
        <div className="approach_steps__wrapper-bg"></div>
      </div>
    </>
  );
};

export default Steps;
