// Librerias.
import React from "react";

// Components Base.
import { Navbar } from "../components-base";

// Assets.

const Hero = () => {
  return <Navbar typeNav='dark'/>;
};
export default Hero;
