// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import ReturnToHome from '../components-base/ReturnToHome';

// Assets.
import ReturnSvg from './assets/error.svg';
import AlertSvg from './assets/alert.svg';

const PageError = () => {
  return (
    <>
      <div className="page-error">
        <div className="purple-line"></div>
        <Container>
          <div className="error_wrapper">
            <img src={ReturnSvg} alt="Error" className="illust img-fluid" />
            <div className="title">
              <img src={AlertSvg} alt="Alert Icon" className="icon img-fluid" />
              <span>
                <FormattedMessage id="error.title" defaultMessage="Lo sentimos. Tenemos el siguiente problema:" />
              </span>
            </div>
            <div className="description">
              <span>
                <FormattedMessage id="error.errornumber" defaultMessage="Error 404" />
              </span>
              <FormattedMessage id="error.description" defaultMessage=" - Página no encontrada" />
            </div>
            <ReturnToHome>
              <FormattedMessage id="error.btn" defaultMessage="Regresar" />
            </ReturnToHome>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PageError;
