import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
//Asset.
import dicoma from './assets/img/welcome.png';

const Welcome = () => {
  return (
    <section className="dicoma_welcome">
      <Container>
        <div className="d-flex flex-wrap justify-content-center align-items-center mb-5">
          <h2 className="me-3  mb-lg-0 mb-sm-0 mb-3 fw-bold">
            <FormattedMessage id="dicoma.welcome" defaultMessage="Bienvenidos a:" />
          </h2>
          <img src={dicoma} alt="logo dicoma" className="img-fluid" width={468} height={58} />
        </div>
        <p>
          <FormattedMessage
            id="dicoma.welcome-description"
            defaultMessage="DiCOMA es un sistema, una aplicación para gestionar el registro de los consumos en las cafeterías o comedores industriales de las plantas de nuestros clientes. Básicamente hablamos de una trifecta de aplicaciones: 1) el registro de consumos, 2) la gestión de estos por parte del área de RRHH y 3) la consulta de sus propios registros de parte de los empleados. La integración de tres aplicaciones: el checador de Dicoma, iNTEGRITY, donde me aparece el reporte de los registros y My[Company] donde aparecen los consumos al empleado."
          />
        </p>
      </Container>
    </section>
  );
};
export default Welcome;
