// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import {
  IconForkDarkSvg,
  IconGearDarkSvg,
  IconHandDarkSvg,
  IconKeyDarkSvg,
  IconRightDarkSvg,
  IconRightWhiteSvg,
  ImgDoLoop,
} from './Assets';

const CardDo = ({ to, title, description, icon, classCard }) => {
  return (
    <a href={to} className="text-decoration-none">
      <div className={`card ${classCard}`}>
        <div className="card__body p-0">
          {icon}
          <h3>{title}</h3>
          <p>{description}</p>
          <div className="d-flex justify-content-end">
            <IconRightDarkSvg />
          </div>
        </div>
      </div>
    </a>
  );
};

const CardCfdis = ({ classCard }) => {
  return (
    <CardDo
      to="./cfdis"
      title={<FormattedMessage id="home.do-card-cfdis-title" defaultMessage="Recibo de Nóminas" />}
      description={
        <FormattedMessage
          id="home.do-card-cfdis-description"
          defaultMessage="certificación (cfdi), personalización y envío por e-mail"
        />
      }
      icon={<IconHandDarkSvg />}
      classCard={`card-cfdis ${classCard}`}
    />
  );
};

const CardMyCompany = ({ classCard }) => {
  return (
    <CardDo
      to="./my-company"
      title={<FormattedMessage id="home.do-card-mycompany-title" defaultMessage="My[Company]®" />}
      description={
        <FormattedMessage
          id="home.do-card-mycompany-description"
          defaultMessage="Servicio de atención personalizada a empleados, vía web"
        />
      }
      icon={<IconKeyDarkSvg />}
      classCard={`card-mycompany ${classCard}`}
    />
  );
};

const CardDicoma = ({ classCard }) => {
  return (
    <CardDo
      to="./dicoma"
      title={<FormattedMessage id="home.do-card-dicoma-title" defaultMessage="DICOMA®" />}
      description={
        <FormattedMessage
          id="home.do-card-dicoma-description"
          defaultMessage="Aplicación web para registro y gestión de consumos"
        />
      }
      icon={<IconForkDarkSvg />}
      classCard={`card-dicoma ${classCard}`}
    />
  );
};

const CardSoftware = ({ classCard }) => {
  return (
    <CardDo
      to="./software"
      title={<FormattedMessage id="home.do-card-software-title" defaultMessage="Desarrollo de Software a la medida" />}
      description={<FormattedMessage id="home.do-card-software-description" defaultMessage=" " />}
      icon={<IconGearDarkSvg />}
      classCard={`card-gear card-software ${classCard}`}
    />
  );
};

const Do = () => {
  return (
    <section className="home_do">
      <Container>
        <h2>
          <FormattedMessage id="home.do-title" defaultMessage="Lo que hacemos..." />
        </h2>
        <div className="grid-do">
          <a href="./payroll" className="home_do__imgdo card-payroll">
            <div class="imgbox"></div>
            <ImgDoLoop className="loop--img" />
            <p>
              <span>
                <FormattedMessage id="home.do-bigcard-bold" defaultMessage="Procesamiento de nóminas de Alto Rendimiento " />
              </span>
              <FormattedMessage id="home.do-bigcard" defaultMessage="que se entiende fácilmente con RRHH y Finanzas" />
            </p>
            <div className="text-end">
              <IconRightWhiteSvg />
            </div>
          </a>

          <CardCfdis />
          <CardMyCompany />
          <CardDicoma />
          <CardSoftware />
        </div>
      </Container>
    </section>
  );
};

export default Do;
