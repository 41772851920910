// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';
// Components Base.
import { Footer, Contact } from '../components-base';

// Components Dicoma.
import Hero from './Hero';
import Welcome from './Welcome';
import Manager from './Manager';
import Application from './Application';
import Benefits from './Benefits';
import Checkin from './Checkin';
import Consumption from './Consumption';
import General from './General';

const PageDicoma = () => {
  return (
    <>
      <Hero />
      <Welcome />
      <Manager />
      <Application />
      <Benefits />
      <Checkin />
      <Consumption />
      <General />
      <Contact
        title={
          <FormattedMessage
            id="dicoma.contact"
            defaultMessage="¿Desea hacer más eficiente la gestión de los consumos en su comedor industrial?"
          />
        }
      />
      <Footer />
    </>
  );
};

export default PageDicoma;
