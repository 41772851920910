import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
//Asset.
import application from './assets/img/application.png';
import calendar from './assets/img/calendar.svg';

const Application = () => {
  return (
    <section className="dicoma_application">
      <Container>
        <div className="dicoma_application__info">
          <h1 className="description">
            <FormattedMessage
              id="dicoma.application"
              defaultMessage="La solución integral para el registro de consumos en su comedor industrial está servida."
            />
          </h1>
          <a className="btn-dicoma" href="../contact/sales/">
            <img src={calendar} className="icon" alt="Calendar" />
            <FormattedMessage id="dicoma.application-btn" defaultMessage="Solicite una DEMO" />
          </a>
        </div>
      </Container>
      <div className="dicoma_application__bg"></div>
      <img src={application} alt="Application BG" className="dicoma_application__bg-img img-fluid" />
    </section>
  );
};
export default Application;
