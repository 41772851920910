// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { GradientBg, ReturnToServices } from '../components-base';

// Components Payrroll.
import { TitleIncidents } from './Titles';

// Assets.
// import validationImg from './assets/img/incidents.svg';
import incidentsPhoneImg from './assets/img/incidents-phone.svg';
import { IncidentsIntegrityImg } from './assets';
import iconTime from './assets/icons-incidents/time.svg';
import iconCup from './assets/icons-incidents/cup.svg';
import iconBag from './assets/icons-incidents/bag.svg';
import iconFork from './assets/icons-incidents/fork.svg';
import iconFix from './assets/icons-incidents/fix.svg';

// Hooks.
import useWindow from '../hooks/useWindow';

const IncidentsDesktop = () => {
  return (
    <>
      <div className="wrapper_incidents">
        <div className="assistance-control">
          <h2>
            <FormattedMessage id="payroll.incidents-payroll" defaultMessage="Incidencias de Nómina" />
          </h2>
          <div className="left-card">
            <p className="fw-bold">
              <FormattedMessage
                id="payroll.incidents-assistancecontrol-description"
                defaultMessage="Información registrada mediante el sistema de control de asistencia:"
              />
            </p>
            <p className="fw-bold">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p1" defaultMessage="- Tiempo regular" />
            </p>
            <p className="fw-bold">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p2" defaultMessage="+ Tiempo No Regular" />
            </p>
            <p className="ps-3">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p3" defaultMessage="- Tiempo Extra" />
            </p>
            <p className="ps-3">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p4" defaultMessage="- Descanso Trabajado" />
            </p>
            <p className="fw-bold">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p5" defaultMessage="+ Ausencias" />
            </p>
            <p className="ps-3">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p6" defaultMessage="+ Justificadas" />
            </p>
            <p className="ps-4">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p7" defaultMessage="- Con goce de sueldo" />
            </p>
            <p className="ps-4">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p8" defaultMessage="- Sin goce de sueldo" />
            </p>
            <p className="ps-4">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p9" defaultMessage="- Injustificadas" />
            </p>
            <p className="fw-bold">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p10" defaultMessage="- Incapacidades" />
            </p>
            <p className="fw-bold">
              <FormattedMessage id="payroll.incidents-assistancecontrol-p11" defaultMessage="- Vacaciones" />
            </p>
          </div>
        </div>
        <img src={incidentsPhoneImg} alt="Incidencias" width={540} height={538} className=" img-fluid img-incidents" />
        <div className="features">
          <div className="upper">
            <p className="fw-bold right-card2">
              <FormattedMessage
                id="payroll.incidents-bonus-description"
                defaultMessage="Pagos adicionales al salario regular (fuera de los requeridos por ley)"
              />
            </p>
            <p className="fw-bold right-card">
              <FormattedMessage
                id="payroll.incidents-credits-description"
                defaultMessage="INFONAVIT, FONACOT y/u otros préstamos deducidos vía nómina"
              />
            </p>
          </div>

          <div className="lower">
            <p className="fw-bold right-card">
              <FormattedMessage
                id="payroll.incidents-diningroom-description"
                defaultMessage="Consumos de empleados en el comedor deducidos vía nómina"
              />
            </p>
            <p className="fw-bold right-card2">
              <FormattedMessage
                id="payroll.incidents-corrections-description"
                defaultMessage="Ajustes de periodos anteriores que son hechos en el actual"
              />
            </p>
          </div>
        </div>
      </div>
      <div className="integrity-desktop">
        <div className="integrity-desktop__text">
          <h3>
            <FormattedMessage
              id="payroll.incidents-validation-description"
              defaultMessage="Gestionar todo tipo de incidencias es rápido y sencillo en el contenedor dinámico de iNTEGRITY…"
            />
          </h3>
          <p>
            <FormattedMessage
              id="payroll.incidents-validation-p"
              defaultMessage="Ya sea mediante una transferencia automatizada desde aplicaciones como control de asistencia, gestión de consumos de comedor u otras, la importación de un archivo xls/csv o incluso la captura manual… El módulo de Incidencias en nuestra aplicación iNTEGRITY permite a los miembros de RRHH/Nóminas concentrar, validar y editar la información antes de transferirla al sistema de nóminas para ejecutar el cálculo."
            />
          </p>
        </div>
        <IncidentsIntegrityImg className="img-fluid" />
      </div>
    </>
  );
};

const CardIncidents = ({ icon, title, description, children }) => {
  return (
    <div className="card">
      <div className="card__body">
        <div className="card__body--title">
          <img src={icon} alt="icon time" className="icon" />
          <h3>{title}</h3>
        </div>
        <p className="fw-bold">{description}</p>

        <div>{children}</div>
      </div>
    </div>
  );
};
const IncidentsPhone = () => {
  return (
    <div className="mt-4">
      <h2>
        <FormattedMessage id="payroll.incidents-payroll" defaultMessage="Incidencias de Nómina" />
      </h2>
      <div className="text-center mt-4">
        <img src={incidentsPhoneImg} alt="" width="280" height="280" />
      </div>
      <div className="cards-wrapper">
        <CardIncidents
          icon={iconTime}
          title={<FormattedMessage id="payroll.incidents-assistancecontrol" defaultMessage="CONTROL DE ASISTENCIA" />}
          description={
            <FormattedMessage
              id="payroll.incidents-assistancecontrol-description"
              defaultMessage="Información registrada mediante el sistema de control de asistencia:"
            />
          }
        >
          <p className="fw-bold">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p1" defaultMessage="- Tiempo regular" />
          </p>
          <p className="fw-bold">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p2" defaultMessage="+ Tiempo No Regular" />
          </p>
          <p className="ps-3">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p3" defaultMessage="- Tiempo Extra" />
          </p>
          <p className="ps-3">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p4" defaultMessage="- Descanso Trabajado" />
          </p>
          <p className="fw-bold">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p5" defaultMessage="+ Ausencias" />
          </p>
          <p className="ps-3">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p6" defaultMessage="+ Justificadas" />
          </p>
          <p className="ps-4">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p7" defaultMessage="- Con goce de sueldo" />
          </p>
          <p className="ps-4">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p8" defaultMessage="- Sin goce de sueldo" />
          </p>
          <p className="ps-4">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p9" defaultMessage="- Injustificadas" />
          </p>
          <p className="fw-bold">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p10" defaultMessage="- Incapacidades" />
          </p>
          <p className="fw-bold">
            <FormattedMessage id="payroll.incidents-assistancecontrol-p11" defaultMessage="- Vacaciones" />
          </p>
        </CardIncidents>
        <CardIncidents
          icon={iconCup}
          title={<FormattedMessage id="payroll.incidents-bonus" defaultMessage="BONOS" />}
          description={
            <FormattedMessage
              id="payroll.incidents-bonus-description"
              defaultMessage="Pagos adicionales al salario regular (fuera de los requeridos por ley)"
            />
          }
        />
        <CardIncidents
          icon={iconBag}
          title={<FormattedMessage id="payroll.incidents-credits" defaultMessage="CRÉDITOS" />}
          description={
            <FormattedMessage
              id="payroll.incidents-credits-description"
              defaultMessage="INFONAVIT, FONACOT y/u otros préstamos deducidos vía nómina"
            />
          }
        />
        <CardIncidents
          icon={iconFork}
          title={<FormattedMessage id="payroll.incidents-diningroom" defaultMessage="COMEDOR" />}
          description={
            <FormattedMessage
              id="payroll.incidents-diningroom-description"
              defaultMessage="Consumos de empleados en el comedor deducidos vía nómina"
            />
          }
        />
        <CardIncidents
          icon={iconFix}
          title={<FormattedMessage id="payroll.incidents-corrections" defaultMessage="CORRECCIONES" />}
          description={
            <FormattedMessage
              id="payroll.incidents-corrections-description"
              defaultMessage="Ajustes de periodos anteriores que son hechos en el actual"
            />
          }
        />
      </div>
      <div className="integrity">
        <h4>
          <FormattedMessage id="payroll.incidents-validation" defaultMessage="VALIDACIÓN Y CAMBIOS" />
        </h4>
        <IncidentsIntegrityImg />
        <h3>
          <FormattedMessage
            id="payroll.incidents-validation-description"
            defaultMessage="Gestionar todo tipo de incidencias es rápido y sencillo en el contenedor dinámico de iNTEGRITY…"
          />
        </h3>
        <p>
          <FormattedMessage
            id="payroll.incidents-validation-p"
            defaultMessage="Ya sea mediante una transferencia automatizada desde aplicaciones como control de asistencia, gestión de consumos de comedor u otras, la importación de un archivo xls/csv o incluso la captura manual… El módulo de Incidencias en nuestra aplicación iNTEGRITY permite a los miembros de RRHH/Nóminas concentrar, validar y editar la información antes de transferirla al sistema de nóminas para ejecutar el cálculo."
          />
        </p>
      </div>
    </div>
  );
};

const Incidents = () => {
  const windowSize = useWindow();
  return (
    <section className="payroll_incidents">
      <GradientBg className="gradient" direction="top left" gradientFrom="rgba(245, 245, 245, 1) 5%">
        <Container>
          <TitleIncidents />
          {windowSize.width > 991 ? <IncidentsDesktop /> : <IncidentsPhone />}
          <ReturnToServices />
        </Container>
      </GradientBg>
    </section>
  );
};
export default Incidents;
