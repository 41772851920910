// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Container } from 'react-bootstrap';

// Assets.
import { MexicoSmileSvg } from './Assets';

const OurMotivation = () => {
  return (
    <section className="the-company_our-motivation">
      <Container>
        <div className="info">
          <h2 className="info__title">
            <FormattedMessage id="thecompany.motivation-title" defaultMessage="Nuestra Motivación:" />
          </h2>

          <p className="info__text">
            <FormattedMessage
              id="thecompany.motivation-description"
              defaultMessage="Ayudar a todos los miembros de nuestro equipo a desarrollarse, primero como personas, para garantizar su crecimiento profesional y financiero."
            />
          </p>
        </div>

        <div className="illustration">
          <div className="illustration__circles">
            <strong className="illustration__circles--arrow">
              <FormattedMessage id="thecompany.motivation-circle-better" defaultMessage="Mejores" /> <br />
              <FormattedMessage id="thecompany.motivation-circle-people" defaultMessage="Personas" />
            </strong>

            <strong className="illustration__circles--arrow">
              <FormattedMessage id="thecompany.motivation-circle-better" defaultMessage="Mejores" /> <br />
              <FormattedMessage id="thecompany.motivation-circle-employees" defaultMessage="Empleados" />
            </strong>

            <strong>
              <FormattedMessage id="thecompany.motivation-circle-better" defaultMessage="Mejores" /> <br />
              <FormattedMessage id="thecompany.motivation-circle-companies" defaultMessage="Compañías" />
            </strong>

            <div className="illustration__circles--equal">
              <span></span>
            </div>

            <MexicoSmileSvg className="mexico" />
          </div>

          <strong className="illustration__better-country">
            <span className="illustration__better-country--desktop">
              <FormattedMessage id="thecompany.motivation-bettercountry-l1" defaultMessage="Un mejor" /> <br />
              <FormattedMessage id="thecompany.motivation-bettercountry-l2" defaultMessage="país" />
            </span>
            <span className="illustration__better-country--mobile">
              <FormattedMessage id="thecompany.motivation-bettercountry" defaultMessage="Un mejor país" />
            </span>
          </strong>
        </div>
      </Container>
      <div className="the-company_our-motivation__bg"></div>
    </section>
  );
};

export default OurMotivation;
