// Librerias.
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';

// Components Base.
import { Footer } from '../components-base';

// Components Contact.
import ReturnToForms from './ReturnToForms';
import Hero from './Hero';

// Assets.
import support from './assets/img/support.svg';

// Hooks.
import { useForm } from 'react-hook-form';
import { Support } from './ApiCalls';

const PageContactSupport = () => {
  registerLocale('es', es);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const history = useHistory();
  const [file, setFile] = useState({});

  const onSubmit = async (data, e) => {
    console.log(data);
    var formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('company', data.company);
    formData.append('app', data.app);
    formData.append('date', data.date);
    formData.append('file', file);
    formData.append('description', data.description);
    const res = await Support(formData);

    if (res.status === 200) {
      history.push('/contact/support/success');
      e.target.reset();
      setFile({});
    } else {
      history.push('/error');
    }
  };

  const handleFile = (e) => {
    const auxFile = e.target.files[0];
    setFile(auxFile);
  };

  return (
    <>
      <Hero />
      <section className="contact_support">
        <Container>
          <ReturnToForms to={'../contact'} />
          <div className="wrapper">
            <img src={support} alt="Support Icon" className="icon" />
            <h3 className="">
              <FormattedMessage id="contact.support" defaultMessage="¿Requiere Soporte Técnico?" />
            </h3>
            <p className="description">
              <FormattedMessage
                id="contact.support-description"
                defaultMessage="Favor de compartirnos información detallada para poder asistirle…"
              />
            </p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form_group">
                <label className="form_group--label" htmlFor="name">
                  <FormattedMessage id="contact.support-name" defaultMessage="Nombre Completo" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  {...register('name', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.support-name-required" defaultMessage="El nombre es obligatorio" />,
                    },
                  })}
                />
                <small className="text-danger">{errors.name && errors.name.message}</small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="company">
                  <FormattedMessage id="contact.support-company" defaultMessage="Empresa" />
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  {...register('company', {
                    required: {
                      value: true,
                      message: (
                        <FormattedMessage id="contact.support-company-required" defaultMessage="La empresa es obligatoria" />
                      ),
                    },
                  })}
                />
                <small className="text-danger">{errors.company && errors.company.message}</small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="email">
                  <FormattedMessage id="contact.support-email" defaultMessage="Correo electrónico" />
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register('email', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.support-email-required" defaultMessage="El email es obligatorio" />,
                    },
                  })}
                />
                <small className="text-danger">{errors.email && errors.email.message}</small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="date">
                  <FormattedMessage id="contact.support-date" defaultMessage="Fecha del problema" />
                </label>
                <Form.Control
                  type="date"
                  min="2020-01-01"
                  max="2025-12-31"
                  id="date"
                  name="date"
                  control={control}
                  defaultValue={null}
                  {...register('date', {
                    required: {
                      value: true,
                      message: <FormattedMessage id="contact.support-date-required" defaultMessage="La fecha es obligatoria" />,
                    },
                  })}
                />
                <small className="text-danger">{errors.date && errors.date.message}</small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="app">
                  <FormattedMessage id="contact.support-app" defaultMessage="Aplicación" />
                </label>
                <div className="form__dropdown-menu">
                  <div className="custom-select">
                    <select
                      id="app"
                      name="app"
                      {...register('app', {
                        required: (
                          <FormattedMessage id="contact.support-app-required" defaultMessage="La aplicación es obligatoria" />
                        ),
                      })}
                    >
                      <FormattedMessage id="contact.dropdown-placeholder" defaultMessage="Selecciona una opción">
                        {(message) => (
                          <option value="" disabled selected>
                            {message}
                          </option>
                        )}
                      </FormattedMessage>
                      <FormattedMessage id="contact.support-app-dropdown-01" defaultMessage="Maquila de Nómina">
                        {(message) => <option value="Maquila de Nomina">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="contact.support-app-dropdown-02" defaultMessage="Gestión de CFDI'S">
                        {(message) => <option value="Gestion de CFDIS">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="contact.support-app-dropdown-03" defaultMessage="Desarrollo de Software">
                        {(message) => <option value="Desarrollo de Software">{message}</option>}
                      </FormattedMessage>
                      <option value="iNTEGRITY">iNTEGRITY</option>
                      <option value="My [Company]">My [Company]</option>
                      <option value="DiCOMA">DiCOMA</option>
                    </select>
                    <span className="custom-arrow"></span>
                  </div>
                </div>
                <small className="text-danger">{errors.app && errors.app.message}</small>
              </div>
              <div className="form_group">
                <label className="form_group--label" htmlFor="real-file">
                  <FormattedMessage id="contact.support-file" defaultMessage="Evidencia (foto de pantalla)" />
                </label>
                <div className="upload-btn">
                  <input
                    type="file"
                    id="real-file"
                    hidden="hidden"
                    name="file"
                    {...register('file')}
                    onChange={(e) => handleFile(e)}
                  />
                  <label id="custom-button" htmlFor="real-file">
                    <FormattedMessage id="contact.support-file-examine" defaultMessage="Examinar..." />
                  </label>
                  <span id="custom-text">{file?.name}</span>
                </div>
              </div>
              <div className="form_group comments">
                <label className="form_group--label" htmlFor="description">
                  <FormattedMessage id="contact.support-comments" defaultMessage="Descripción" />
                </label>
                <FormattedMessage
                  id="contact.support-comments-placeholder"
                  defaultMessage="Favor de describir brevemente sus necesidades…"
                >
                  {(message) => (
                    <textarea
                      placeholder={message}
                      id="description"
                      name="description"
                      {...register('description', {
                        required: {
                          value: true,
                          message: (
                            <FormattedMessage id="contact.support-comments-required" defaultMessage="Este campo es obligatorio" />
                          ),
                        },
                      })}
                    ></textarea>
                  )}
                </FormattedMessage>

                <small className="text-danger">{errors.description && errors.description.message}</small>
              </div>

              <button type="submit" className="btn-form">
                <FormattedMessage id="contact.support-btn" defaultMessage="ENVIAR" />
              </button>
            </form>
          </div>
        </Container>
        <div className="wrapper__bg--mobile"></div>
      </section>
      <Footer />
    </>
  );
};
export default PageContactSupport;
