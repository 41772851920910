// Librerias.
import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import { LogoWhiteSvg, KatanaLogoPurpleSvg, IconLinkedInSvg } from './Assets';

// Context.
import { langContext } from './../context/langContext';

const Footer = () => {
  const lang = useContext(langContext);

  const language = localStorage.getItem('lang');
  const showContactForm = language === 'es-MX' ? '/contact' : '/contact/sales';

  return (
    <>
      <footer className="footer">
        <Container>
          <a href="/">
            <LogoWhiteSvg classAnchor="footer__logo" />
          </a>
          <div className="footer__address">
            {/* <p>KATANA &amp; NOMI, S.A. DE C.V.</p>
            <p className="fw-regular">Francisco de Montejo 5, Cd. Satélite, 53100 Naucalpan de Juárez, EDOMEX, México.</p> */}
            {/* <p className="telefono">Teléfono: +52 (55) 8970-3370</p> */}
          </div>
          <div className="footer__copyright">&copy; KATANA &amp; NOMI, S.A. de C.V.</div>
          <div className="footer__links col1">
            <div className="title">
              <FormattedMessage id="footer.us" defaultMessage="NOSOTROS" />
            </div>
            <a href="/the-company">
              <FormattedMessage id="footer.us-thecompany" defaultMessage="La Empresa" />
            </a>
            <a href="/approach">
              <FormattedMessage id="footer.us-approach" defaultMessage="Nuestro Approach" />
            </a>
          </div>

          <div className="footer__links col2">
            <div className="title">
              <FormattedMessage id="footer.services" defaultMessage="SERVICIOS" />
            </div>
            <a href="/payroll">
              <FormattedMessage id="footer.services-payroll" defaultMessage="Maquila de Nómina" />
            </a>
            <a href="/cfdis">
              <FormattedMessage id="footer.services-cfdis" defaultMessage="Gestión integral de CFDI’s" />
            </a>
            <a href="/software">
              <FormattedMessage id="footer.services-software" defaultMessage="Desarrollo de Software a la Medida" />
            </a>

            <div className="title">
              <FormattedMessage id="footer.products" defaultMessage="PRODUCTOS" />
            </div>
            <a href="/integrity">
              <FormattedMessage id="footer.products-integrity" defaultMessage="iNTEGRITY" />
            </a>
            <a href="/my-company">
              <FormattedMessage id="footer.products-mycompany" defaultMessage="My[COMPANY]" />
            </a>
            <a href="/dicoma">
              <FormattedMessage id="footer.products-dicoma" defaultMessage="DICOMA" />
            </a>
          </div>

          <div className="footer__links col3">
            <div className="title">
              <FormattedMessage id="footer.access" defaultMessage="ACCESO" />
            </div>
            <a href="https://integrity.nomi.mx/" target="_blank" rel="noreferrer">
              <FormattedMessage id="footer.access-integrity" defaultMessage="iNTEGRITY" />
            </a>

            <div className="title">
              <FormattedMessage id="footer.lang" defaultMessage="OTROS IDIOMAS" />
            </div>
            <div className="lang" onClick={() => lang.setLanguage('en-US')}>
              <FormattedMessage id="footer.lang-english" defaultMessage="English" />
            </div>
            <div className="lang" onClick={() => lang.setLanguage('es-MX')}>
              <FormattedMessage id="footer.lang-spanish" defaultMessage="Español" />
            </div>
            <div className="lang" onClick={() => lang.setLanguage('de-DE')}>
              <FormattedMessage id="footer.lang-german" defaultMessage="Deutsche" />
            </div>
            <div className="lang" onClick={() => lang.setLanguage('ja-JP')}>
              <FormattedMessage id="footer.lang-japanese" defaultMessage="日本語" />
            </div>
          </div>

          <div className="footer__links col4">
            <div className="title">
              <FormattedMessage id="footer.contact" defaultMessage="CONTACTO" />
            </div>
            <a href={showContactForm}>
              <FormattedMessage id="footer.contact-talk" defaultMessage="¡Hablemos!" />
            </a>

            <div className="title SM">
              <FormattedMessage id="footer.follow" defaultMessage="SÍGUENOS" />
            </div>
            <a
              href="https://www.linkedin.com/company/nomi-maquila-nomina/mycompany/"
              target="_blank"
              className="SM"
              rel="noreferrer"
            >
              <IconLinkedInSvg />
            </a>

            <div className="title">
              <FormattedMessage id="footer.legal" defaultMessage="LEGAL" />
            </div>
            <a href="/privacy">
              <FormattedMessage id="footer.legal-privacy" defaultMessage="Aviso de Privacidad" />
            </a>
          </div>
          <KatanaLogoPurpleSvg classAnchor="footer__katana-logo" />
        </Container>
      </footer>
      <div className="rectangle"></div>
    </>
  );
};

export default Footer;
