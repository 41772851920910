// Librerias.
import React from 'react';

// Images.
import designThinkingImg from './assets/img/design-thinking.svg';
import designThinkingPhoneImg from './assets/img/design-thinking-phone.svg';
import loopImg from './assets/img/loop.svg';
import optimizationImg from './assets/img/optimization.svg';
import personImg from './assets/img/person.png';
import watchImg from './assets/img/watch.png';
import womanImg from './assets/img/woman.png';
import circleImg from './assets/img/purple-circle.svg';
import arrowRight from './assets/img/arrow-right.svg';
import arrowDown from './assets/img/arrow-down.svg';
import researchArrowDesktop from './assets/img/research-arrow-desktop.svg';
import researchArrowMobile from './assets/img/research-arrow-mobile.svg';
import tryArrowDesktop from './assets/img/try-arrow-desktop.svg';
import tryArrowMobile from './assets/img/try-arrow-mobile.svg';
import learnFromPrototypeArrowDesktop from './assets/img/learn-from-prototype-arrow-desktop.svg';
import learnFromPrototypeArrowMobile from './assets/img/learn-from-prototype-arrow-mobile.svg';
import testingArrowDesktop from './assets/img/testing-arrow-desktop.svg';
import testingArrowMobile from './assets/img/testing-arrow-mobile.svg';
import learnFromUsersArrowDesktop from './assets/img/learn-from-users-arrow-desktop.svg';
import learnFromUsersArrowMobile from './assets/img/learn-from-users-arrow-mobile.svg';

export const DesignThinkingImg = ({ className }) => {
  return <img src={designThinkingImg} alt="design thinking" className={className} width="1080" height="686" />;
};
export const DesignThinkingPhoneImg = ({ className }) => {
  return <img src={designThinkingPhoneImg} alt="design thinking" className={className} width="300" height="1080" />;
};
export const LoopImg = ({ className }) => {
  return <img src={loopImg} alt="design thinking" className={className} width="446" height="208" />;
};
export const OptimizationImg = ({ className }) => {
  return <img src={optimizationImg} alt="design thinking" className={className} width="290" height="273" />;
};
export const PersonImg = ({ className }) => {
  return <img src={personImg} alt="person" className={className} width={280} height={280} />;
};
export const WatchImg = ({ className }) => {
  return <img src={watchImg} alt="person" className={className} width={280} height={280} />;
};
export const WomanImg = ({ className }) => {
  return <img src={womanImg} alt="person" className={className} width={280} height={280} />;
};
export const CircleImg = () => {
  return <img src={circleImg} alt="circle" className="img-fluid" />;
};
export const ArrowRight = () => {
  return <img src={arrowRight} alt="arrow right" className="img-fluid" />;
};
export const ArrowDown = () => {
  return <img src={arrowDown} alt="arrow down" className="img-fluid" />;
};
export const ResearchArrowDesktop = () => {
  return <img src={researchArrowDesktop} alt="Research Arrow" className="img-fluid" />;
};
export const ResearchArrowMobile = () => {
  return <img src={researchArrowMobile} alt="Research Arrow" className="img-fluid" />;
};
export const TryArrowDesktop = () => {
  return <img src={tryArrowDesktop} alt="Try Arrow Desktop" className="img-fluid" />;
};
export const TryArrowMobile = () => {
  return <img src={tryArrowMobile} alt="Try Arrow Desktop" className="img-fluid" />;
};
export const LearnFromPrototypeArrowDesktop = () => {
  return <img src={learnFromPrototypeArrowDesktop} alt="Learn From Prototype Arrow" className="img-fluid" />;
};
export const LearnFromPrototypeArrowMobile = () => {
  return <img src={learnFromPrototypeArrowMobile} alt="Learn From Prototype Arrow" className="img-fluid" />;
};
export const TestingArrowDesktop = () => {
  return <img src={testingArrowDesktop} alt="Testing Arrow" className="img-fluid" />;
};
export const TestingArrowMobile = () => {
  return <img src={testingArrowMobile} alt="Testing Arrow" className="img-fluid" />;
};
export const LearnFromUsersArrowDesktop = () => {
  return <img src={learnFromUsersArrowDesktop} alt="Learn From Users Arrow" className="img-fluid" />;
};
export const LearnFromUsersArrowMobile = () => {
  return <img src={learnFromUsersArrowMobile} alt="Learn From Users Arrow" className="img-fluid" />;
};
