// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import ReturnToServices from '../components-base/ReturnToServices';

// Components Software.
import { TitleUX } from './Titles';

// Assets.
import userIcon from './assets/ux/icon-user.svg';
import useCaseIcon from './assets/ux/icon-use-case.svg';
import flowIcon from './assets/ux/icon-flows.svg';
import lineIcon from './assets/ux/icon-line.svg';

const CardUx = ({ title, icon, after, description }) => {
  return (
    <div className="card">
      <img src={icon} alt="icons ux" className="img-fluid icon" />
      <div>
        <h3 className="card__title line-after">{title}</h3>
        <img src={lineIcon} alt="linea" className="card__line" />
        <p>{description}</p>
      </div>
    </div>
  );
};
const Ux = () => {
  return (
    <section className="software_ux">
      <Container>
        <TitleUX />
        <div className="cards-wrapper">
          <CardUx
            title={<FormattedMessage id="software.ux-user" defaultMessage="USUARIO" />}
            icon={userIcon}
            description={
              <FormattedMessage
                id="software.ux-user-description"
                defaultMessage="Analizamos y conocemos perfectamente a los usuarios de nuestras aplicaciones, pues están en nuestras instalaciones, son los ejecutivos de cuenta y los conocemos muy bien: sabemos cuáles son sus gustos y aversiones, sus miedos, bloqueos, preocupaciones y objetivos. Contamos con un buen análisis del usuario y esto nos permite crear productos cien por ciento hechos a la medida de sus necesidades."
              />
            }
          />
          <CardUx
            title={<FormattedMessage id="software.ux-cases" defaultMessage="CASOS DE USO" />}
            icon={useCaseIcon}
            description={
              <FormattedMessage
                id="software.ux-cases-description"
                defaultMessage="Nuestra experiencia sirviendo a empresas del más alto nivel nos proporciona el contacto con casos de uso: se trata de ambientes estresantes en el que se manejan tiempos de entrega ajustados y necesidades inmediatas, problemas específicos, fechas límite, cumplimientos con la legislación vigente, entre muchas otras presiones. Esto nos ha brindado una amplia experiencia a lo largo de muchos años."
              />
            }
          />
          <CardUx
            title={<FormattedMessage id="software.ux-flows" defaultMessage="FLUJOS" />}
            icon={flowIcon}
            description={
              <FormattedMessage
                id="software.ux-flows-description"
                defaultMessage="Los flujos representan el ahorro de pasos y ya se acercan más al diseño. Un flujo puede ser la navegación de las ventanas que se deben visitar para lograr el objetivo de la aplicación. Un diagrama de flujo incluye ventanas o secciones que a su vez se dividen en ventanas de la aplicación y son los diagramas de flujos de esta. Con base en los diagramas de flujo se empieza a diseñar cada una de estas ventanas."
              />
            }
          />
        </div>
        <ReturnToServices />
      </Container>
      <div className="line" />
    </section>
  );
};
export default Ux;
