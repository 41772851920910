// Librerias.
import React from "react";

// Icons.
import compassSvg from "./icons/compass.svg";
import experienceSvg from "./icons/experience.svg";
import interfaceSvg from "./icons/interface.svg";
import softwareSvg from "./icons/software.svg";
import strategySvg from "./icons/strategy.svg";
import gearSvg from "./icons/gear.svg";

// Numbers.
import num1Svg from "./numbers/num-1.svg";
import num2Svg from "./numbers/num-2.svg";
import num3Svg from "./numbers/num-3.svg";

// Production.
import deploymentSvg from "./production/deployment.svg";
import maintenanceSvg from "./production/maintenance.svg";
import supportSvg from "./production/support.svg";

// Software.
import dataBaseSvg from "./software/data-base.svg";
import imgDataBaseSvg from "./software/img-data-base.svg";
import integrationsSvg from "./software/integrations.svg";
import imgIntegrationsSvg from "./software/img-integrations.svg";
import webAppSvg from "./software/web-app.svg";
import imgWebAppSvg from "./software/img-web-app.svg";
import arrowsSvg from "./software/arrows.svg";
import arrowsMobileSvg from "./software/arrows-mobile.svg";

// Strategy.
import analysisSvg from "./strategy/analysis.svg";
import requestSvg from "./strategy/request.svg";
import solutionSvg from "./strategy/solution.svg";

// UI.
import prototypeSvg from "./ui/prototype.svg";
import screensSvg from "./ui/screens.svg";
import wireframesSvg from "./ui/wireframes.svg";

// Icons.
export const CompassSvg = () => {
  return <img src={compassSvg} alt="Compass Icon" />;
};
export const ExperienceSvg = () => {
  return <img src={experienceSvg} alt="Experience Icon" />;
};
export const InterfaceSvg = () => {
  return <img src={interfaceSvg} alt="Interface Icon" />;
};
export const SoftwareSvg = () => {
  return <img src={softwareSvg} alt="Software Icon"/>;
};
export const StrategySvg = () => {
  return <img src={strategySvg} alt="Software Icon" />;
};
export const GearSvg = () => {
  return <img src={gearSvg} alt="Software Icon" />;
};

// Numbers.
export const Num1Svg = () => {
  return <img src={num1Svg} alt="Num1 Icon" />;
};
export const Num2Svg = () => {
  return <img src={num2Svg} alt="Num2 Icon" />;
};
export const Num3Svg = () => {
  return <img src={num3Svg} alt="Num3 Icon" />;
};

// Production.
export const DeploymentSvg = () => {
  return <img src={deploymentSvg} alt="Deployment Icon" />;
};
export const MaintenanceSvg = () => {
  return <img src={maintenanceSvg} alt="Maintenance Icon" />;
};
export const SupportSvg = () => {
  return <img src={supportSvg} alt="Support Icon" />;
};

// Software.
export const DataBaseSvg = () => {
  return <img src={dataBaseSvg} alt="Data Base Icon" />;
};
export const ImgDataBaseSvg = () => {
  return <img src={imgDataBaseSvg} alt="Data Base" />;
};
export const IntegrationsSvg = () => {
  return <img src={integrationsSvg} alt="Integrations Icon" />;
};
export const ImgIntegrationsSvg = () => {
  return <img src={imgIntegrationsSvg} alt="Integrations" />;
};
export const WebAppSvg = () => {
  return <img src={webAppSvg} alt="Web App Icon" />;
};
export const ImgWebAppSvg = () => {
  return <img src={imgWebAppSvg} alt="Web App" />;
};
export const ArrowsSvg = () => {
  return <img src={arrowsSvg} alt="Arrows Icon" />;
};
export const ArrowsMobileSvg = () => {
  return <img src={arrowsMobileSvg} alt="Arrows Mobile Icon" />;
};

// Strategy.
export const AnalysisSvg = () => {
  return <img src={analysisSvg} alt="Analysis Icon" />;
};
export const RequestSvg = () => {
  return <img src={requestSvg} alt="Request Icon" />;
};
export const SolutionSvg = () => {
  return <img src={solutionSvg} alt="Solution Icon" />;
};

// UI.
export const PrototypeSvg = () => {
  return <img src={prototypeSvg} alt="Prototype Icon" />;
};
export const ScreensSvg = () => {
  return <img src={screensSvg} alt="Screens Icon" />;
};
export const WireframesSvg = () => {
  return <img src={wireframesSvg} alt="Wireframes Icon" />;
};
