import axios from "axios";

const configHeader = {
    headers: {
        Authorization: "Token 981a93694680ff32eecc75090827c34db5585861",
        "x-app-cocia": "NOMBA",
        "Content-Type": "application/x-www-form-urlencoded",
    },
};
const configHeaderFormData = {
    headers: {
        Authorization: "Token 981a93694680ff32eecc75090827c34db5585861",
        "x-app-cocia": "NOMBA",
        "Content-Type": "multipart/form-data",
    },
};

export const Sales = async(data) => {
    const answer = await axios.post(
        `${process.env.REACT_APP_ROUTE}/nomi/sales/`,
        data,
        configHeaderFormData
    );

    return answer;
};

export const Support = async(data) => {
    const answer = await axios.post(
        `${process.env.REACT_APP_ROUTE}/nomi/technical-support/`,
        data,
        configHeaderFormData
    );

    return answer;
};

export const Job = async(data) => {
    const answer = await axios.post(
        `${process.env.REACT_APP_ROUTE}/nomi/jobs/`,
        data,
        configHeaderFormData
    );

    return answer;
};