// Librerias.
import React from 'react';

// Assets.
import ReturnSvg from './assets/img/return.svg';

const ReturnToForms = ({ children, to, className }) => {
  return (
    <div className={`contact_return ${className}`}>
      <a href={to} className="">
        <img src={ReturnSvg} alt="Return Arrow" />
        {children}
      </a>
    </div>
  );
};

export default ReturnToForms;
