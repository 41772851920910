import React from 'react';
import { a } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
//Asset.
import consumptionPhone from './assets/img/consumption-phone.png';

// Hooks.
import useWindow from '../hooks/useWindow';

const Consumption = () => {
  const windowSize = useWindow();
  return (
    <section className="dicoma_consumption position-relative">
      <Container>
        <img src={consumptionPhone} alt="dicoma phone" width={840} height={770} className="hand img-fluid" />
        {windowSize.width < 600 && (
          <h2 className="kiosko-title">
            <FormattedMessage id="dicoma.consumption" defaultMessage="Mis Consumos" />
          </h2>
        )}
        <Row>
          <Col lg={{ span: 6, offset: 6 }} className="col-my-company">
            {windowSize.width > 600 && (
              <h2 className="mb-4">
                <FormattedMessage id="dicoma.consumption" defaultMessage="Mis Consumos" />
              </h2>
            )}
            <p className="description">
              <FormattedMessage id="dicoma.consumption-description" defaultMessage="A través de la aplicación My" />
              <span className="fw-bold">
                <FormattedMessage id="dicoma.consumption-description-company" defaultMessage="[Company]" />
              </span>
              <FormattedMessage
                id="dicoma.consumption-description2"
                defaultMessage=" las personas podrán consultar sus consumos en cafetería, así como cualquier otra información relacionada con el pago de su nómina y relación laboral…"
              />
            </p>
            <div className="my-company mt-4">
              <p>
                My<span className="fw-bold">[Company]®</span>
              </p>

              <p>
                <span className="text-white">
                  <FormattedMessage
                    id="dicoma.consumption-info"
                    defaultMessage="Para más información sobre nuestra app de atención personalizada a empleados:"
                  />
                </span>
                <br />
                <a href="./my-company" className="fw-bold">
                  <FormattedMessage id="dicoma.consumption-info-link" defaultMessage="PULSE AQUÍ" />
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Consumption;
