// Librerias.
import React from 'react';

// Icons.
import arrowRightSvg from './icons/arrow-right.svg';
import check2Svg from './icons/check-2.svg';
import csvSvg from './icons/csv.svg';
import pdfSvg from './icons/pdf.svg';
import txtSvg from './icons/txt.svg';
import xlsSvg from './icons/xls.svg';
import peaksSvg from './icons/peaks.svg';

// Images.
import calculationImg from './img/calculation.png';
import incidentsIntegrityImg from './img/incidents-integrity.jpg';

// Logos.
import logoIntegritySvg from './logos/logo-integrity.svg';
import logoDicomaSvg from './logos/logo-dicoma.svg';

// Numbers.
import num1Svg from './numbers/num-1.svg';
import num2Svg from './numbers/num-2.svg';
import num3Svg from './numbers/num-3.svg';
import num4Svg from './numbers/num-4.svg';
import num5Svg from './numbers/num-5.svg';
import num6Svg from './numbers/num-6.svg';
import num7Svg from './numbers/num-7.svg';

// Services.
import calculationSvg from './services/calculation.svg';
import communicationSvg from './services/communication.svg';
import dinnerRoomSvg from './services/dinner-room.svg';
import disabilitiesSvg from './services/disabilities.svg';
import incidentsSvg from './services/incidents.svg';
import interactionSvg from './services/interaction.svg';
import parametersSvg from './services/parameters.svg';
import retrospectiveSvg from './services/retrospective.svg';
import settlementsSvg from './services/settlements.svg';
import specialProcessesSvg from './services/special-processes.svg';
import vacationsSvg from './services/vacations.svg';

// Special Processes.
import accountingBgImg from './special-processes/accounting-bg.png';
import accountingSvg from './special-processes/accounting.svg';
import imssBgImg from './special-processes/imss-bg.png';
import logoImssSvg from './special-processes/logo-imss.svg';
import satBgImg from './special-processes/sat-bg.png';
import logoSatSvg from './special-processes/logo-sat.svg';
import settlementsBgImg from './special-processes/settlements-bg.png';
import settlementsPayrollSvg from './special-processes/settlements-payroll.svg';

// Icons.

export const ArrowRightSvg = () => {
  return <img src={arrowRightSvg} alt="Arrow Right Icon" />;
};
export const Check2Svg = () => {
  return <img src={check2Svg} alt="Check 2 Icon" />;
};
export const CsvSvg = () => {
  return <img src={csvSvg} alt="CSV Icon" className="card__icons--icon" />;
};
export const PdfSvg = () => {
  return <img src={pdfSvg} alt="PDF Icon" className="card__icons--icon" />;
};
export const TxtSvg = () => {
  return <img src={txtSvg} alt="Txt Icon" className="card__icons--icon" />;
};
export const XlsSvg = () => {
  return <img src={xlsSvg} alt="Xls Icon" className="card__icons--icon" />;
};
export const PeaksSvg = () => {
  return <img src={peaksSvg} alt="Peaks Icon" />;
};

// Images.
export const CalculationImg = () => {
  return <img src={calculationImg} className="img-fluid" alt="Calculation" />;
};
export const IncidentsIntegrityImg = () => {
  return <img src={incidentsIntegrityImg} className="img-fluid integrity--img" alt="Integrity" />;
};

// Logos.
export const LogoIntegritySvg = () => {
  return <img src={logoIntegritySvg} className="img-fluid description__img" alt="Integrity Logo" />;
};
export const LogoDicomaSvg = () => {
  return <img src={logoDicomaSvg} className="img-fluid" alt="Dicoma Logo" />;
};

// Numbers.
export const Num1Svg = () => {
  return <img src={num1Svg} alt="Num1 Icon" />;
};
export const Num2Svg = () => {
  return <img src={num2Svg} alt="Num2 Icon" />;
};
export const Num3Svg = () => {
  return <img src={num3Svg} alt="Num3 Icon" />;
};
export const Num4Svg = () => {
  return <img src={num4Svg} alt="Num4 Icon" />;
};
export const Num5Svg = () => {
  return <img src={num5Svg} alt="Num5 Icon" />;
};
export const Num6Svg = () => {
  return <img src={num6Svg} alt="Num6 Icon" />;
};
export const Num7Svg = () => {
  return <img src={num7Svg} alt="Num7 Icon" />;
};

// Services.
export const CalculationSvg = () => {
  return <img src={calculationSvg} alt="Calculation Icon" />;
};
export const CommunicationSvg = () => {
  return <img src={communicationSvg} alt="Communication Icon" />;
};
export const DinnerRoomSvg = () => {
  return <img src={dinnerRoomSvg} alt="Dinner Room Icon" />;
};
export const DisabilitiesSvg = () => {
  return <img src={disabilitiesSvg} alt="Disabilities Icon" />;
};
export const IncidentsSvg = () => {
  return <img src={incidentsSvg} alt="Incidents Icon" />;
};
export const InteractionSvg = () => {
  return <img src={interactionSvg} alt="Interaction Icon" />;
};
export const ParametersSvg = () => {
  return <img src={parametersSvg} alt="Parameters Icon" />;
};
export const RetrospectiveSvg = () => {
  return <img src={retrospectiveSvg} alt="Retrospective Icon" />;
};
export const SettlementsSvg = () => {
  return <img src={settlementsSvg} alt="Settlements Icon" />;
};
export const SpecialProcessesSvg = () => {
  return <img src={specialProcessesSvg} alt="Special Processes Icon" />;
};
export const VacationsSvg = () => {
  return <img src={vacationsSvg} alt="Vacations Icon" />;
};

// Special Processes.
export const AccountingBgImg = () => {
  return <img src={accountingBgImg} alt="Accounting Background" />;
};
export const AccountingSvg = () => {
  return <img src={accountingSvg} alt="Accounting Icon" />;
};
export const ImssBgImg = () => {
  return <img src={imssBgImg} alt="IMSS Background" />;
};
export const LogoImssSvg = () => {
  return <img src={logoImssSvg} alt="IMSS Icon" />;
};
export const SatBgImg = () => {
  return <img src={satBgImg} alt="SAT Background" />;
};
export const LogoSatSvg = () => {
  return <img src={logoSatSvg} alt="SAT Icon" />;
};
export const SettlementsBgImg = () => {
  return <img src={settlementsBgImg} alt="Settlements Background" />;
};
export const SettlementsPayrollSvg = () => {
  return <img src={settlementsPayrollSvg} alt="Settlements Payroll Icon" />;
};
