// Librerias.
import styled from "styled-components";

export const GradientBg = styled.div`
  position: relative;

  &.gradient::before {
    content: "";
    display: block;
    position: absolute;
    background: radial-gradient(
      circle at ${(props) => (props.direction ? props.direction : "top right")},
      ${(props) =>
        props.gradientFrom ? props.gradientFrom : "rgba(249, 227, 255, 1) 5%"},
      ${(props) =>
        props.gradientTo ? props.gradientTo : "rgba(255, 255, 255, 1) 25%"}
    );
    z-index: -10;
    height: 100%;
    width: 100%;
  }

  &.gradient_hover:hover {
    content: "";
    display: block;
    background: radial-gradient(
      circle at ${(props) => (props.direction ? props.direction : "top right")},
      ${(props) =>
        props.gradientFrom ? props.gradientFrom : "rgba(249, 227, 255, 1) 5%"},
      ${(props) =>
        props.gradientTo ? props.gradientTo : "rgba(255, 255, 255, 1) 25%"}
    );
    z-index: -10;
    height: 100%;
    width: 100%;
  }
`;
