// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Footer } from '../components-base';

// Components Contact.
import ReturnToForms from './ReturnToForms';
import Hero from './Hero';

// Assets.
import sales from './assets/img/sales.svg';

// Hooks.
import ScrollToTop from '../hooks/scrollToTop';

const PageContactSalesSuccess = () => {
  const language = localStorage.getItem('lang');
  const linkReturnSvg = language === 'es-MX' ? '../../contact' : '../sales';

  return (
    <>
      <ScrollToTop />
      <Hero />
      <section className="contact_success">
        <Container>
          <div className="wrapper">
            <img src={sales} alt="Sales Icon" className="icon img-fluid" />
            <p className="description">
              <span>
                <FormattedMessage
                  id="contact.salessuccess"
                  defaultMessage="¡Muchas gracias por contactar con nuestro equipo de ventas!"
                />
              </span>{' '}
              <br />
              <FormattedMessage id="contact.salessuccess-subtitle" defaultMessage="En breve nos comunicaremos con usted…" />
            </p>
            <ReturnToForms to={linkReturnSvg}>
              <FormattedMessage id="contact.salessuccess-btn" defaultMessage="Regresar" />
            </ReturnToForms>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default PageContactSalesSuccess;
