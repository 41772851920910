// Librerias.
import React from "react";
// Components Base.
import { Footer } from "../components-base";
// Components Aproach.
import Hero from "./Hero";
import Steps from "./Steps";
import What from "./What";
import Thinking from "./Thinking";
import Approach from "./Approach";

const PageAproach = () => {
  return (
    <>
      <Hero />
      <What />
      <Steps />
      <Thinking />
      <Approach />
      <Footer />
    </>
  );
};

export default PageAproach;
