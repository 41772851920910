// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import integrationImg from './assets/img/integration.png';
import line from './assets/img/line.svg';
const Rules = () => {
  return (
    <section className="integrity_integration">
      <Container>
        <h2>
          <FormattedMessage id="integrity.integration-title" defaultMessage="Integración completa de su " />
          <br />
          <FormattedMessage id="integrity.integration-title-2ndline" defaultMessage="estructura de cálculo" />
        </h2>

        <div className="img-line">
          <img src={line} alt="linea" />
        </div>
        <p className="description">
          <FormattedMessage
            id="integrity.integration-description"
            defaultMessage="iNTEGRITY es la nueva versión de nuestro anterior sistema de nóminas. Es un concepto más moderno basado en una aplicación web: fácil de usar, con una buena experiencia de usuario y básicamente se integra en un solo sistema en la nube, de ahí el nombre iNTEGRITY, además se comunica con "
          />
          <span>
            <FormattedMessage id="integrity.integration-description-bold" defaultMessage="DiCOMA" />
          </span>
          <FormattedMessage
            id="integrity.integration-description2"
            defaultMessage=", nuestra aplicación de Cafetería y con nuestra aplicación de auto-servicio al empleado My"
          />
          <span>
            <FormattedMessage id="integrity.integration-description2-bold" defaultMessage="[Company]" />
          </span>
          .
        </p>

        <img src={integrationImg} alt="Integration" className="img-fluid"></img>
      </Container>
    </section>
  );
};
export default Rules;
