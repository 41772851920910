// Librerias
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Componenes Payroll.
import { TitleParameters } from './Titles';
import { ReturnToServices } from '../components-base';

// Assets.
import parametersGearSvg from './assets/img/parameters-gear.svg';
import logoWorkday from './assets/logos/logo-workday.svg';
import logoSap from './assets/logos/logo-sap.svg';
import logoPeopleSoft from './assets/logos/logo-people-soft.svg';

const CardParameters = ({ title, children }) => {
  return (
    <div className="card">
      <div className="card__body">
        <p className="fw-bold">{title}</p>
        <ul className="list-unstyled">{children}</ul>
      </div>
    </div>
  );
};
const Parameters = () => {
  return (
    <section className="payroll_parameters">
      <Container>
        <TitleParameters />
      </Container>
      <div className="payroll_parameters__border" />
      <Container className=" payroll_parameters__implementation">
        <h3>
          <FormattedMessage id="payroll.parameters-implementation" defaultMessage="1.1 Implementación" />
        </h3>
        <div className="d-flex justify-content-between gap-1 flex-lg-nowrap flex-wrap">
          <div>
            <p>
              <FormattedMessage
                id="payroll.parameters-implementation-p1"
                defaultMessage="Al tomar la sabia decisión de trabajar con nomi, nos convertiremos en parte de su equipo. Y juntos, definiremos procesos específicos de trabajo, cálculo y los mejores métodos para el intercambio de información, de acuerdo a la operación y objetivos de su negocio, en busca de proveerle la mejor solución personalizada de procesamiento de nóminas."
              />
            </p>
            <p>
              <FormattedMessage
                id="payroll.parameters-implementation-p2"
                defaultMessage="De acuerdo a los alcances del servicio y otros factores como: frecuencia de pago, cantidad de empleados, tipo y periodicidad de reportes… esta fase puede tomar de uno a varios meses."
              />
              <span className="fw-bold">
                <FormattedMessage
                  id="payroll.parameters-implementation-p2-bold"
                  defaultMessage=" Sin embargo, cabe aclarar dos cosas:"
                />
              </span>
            </p>
            <p className="fw-bold">
              <FormattedMessage
                id="payroll.parameters-implementation-p3"
                defaultMessage="1.- Nuestro tiempo de implementación es el más corto del mercado."
              />
            </p>
            <p className="fw-bold">
              <FormattedMessage
                id="payroll.parameters-implementation-p4"
                defaultMessage="2.- NO calculamos mal el tiempo de implementación: garantizado."
              />
            </p>
          </div>
          <div className="img-gear">
            <img src={parametersGearSvg} alt="people gear" className=" " width={250} height={186} />
          </div>
        </div>
        <div className="cards-wrapper">
          <CardParameters
            title={<FormattedMessage id="payroll.parameters-projectplanning" defaultMessage="a) Project Planning" />}
          >
            <li>
              <FormattedMessage id="payroll.parameters-projectplanning-p1" defaultMessage="- Aprobación de Contrato" />
            </li>
            <li>
              <FormattedMessage id="payroll.parameters-projectplanning-p2" defaultMessage="- Kickoff" />
            </li>
            <li>
              <FormattedMessage
                id="payroll.parameters-projectplanning-p3"
                defaultMessage="- Monitoreo y control del Plan de Implementación"
              />
            </li>
          </CardParameters>

          <CardParameters
            title={<FormattedMessage id="payroll.parameters-systemconfig" defaultMessage="b) Configuración del Sistema" />}
          >
            <li>
              <FormattedMessage id="payroll.parameters-systemconfig-p1" defaultMessage="- Catálogo de conceptos" />
            </li>
            <li>
              <FormattedMessage id="payroll.parameters-systemconfig-p2" defaultMessage="- Carga de información de empleados" />
            </li>
            <li>
              <FormattedMessage
                id="payroll.parameters-systemconfig-p3"
                defaultMessage="- Carga de información histórica de nóminas"
              />
            </li>
            <li>
              <FormattedMessage
                id="payroll.parameters-systemconfig-p4"
                defaultMessage="- Interfaces de contabilidad, RRHH y otras"
              />
            </li>
          </CardParameters>

          <CardParameters title={<FormattedMessage id="payroll.parameters-testing" defaultMessage="c) Pruebas (QA)" />}>
            <li>
              <FormattedMessage
                id="payroll.parameters-testing-p1"
                defaultMessage="- Diseño/pruebas formatos de reportes y recibos"
              />
            </li>
            <li>
              <FormattedMessage id="payroll.parameters-testing-p2" defaultMessage="- Nómina en paralelo (simulación)" />
            </li>
            <li>
              <FormattedMessage
                id="payroll.parameters-testing-p3"
                defaultMessage="- Pruebas de auditoría de cálculo y funcionalidad de interfaces"
              />
            </li>
          </CardParameters>

          <CardParameters
            title={<FormattedMessage id="payroll.parameters-production" defaultMessage="d) Inicio de Producción" />}
          >
            <li>
              <FormattedMessage id="payroll.parameters-production-p1" defaultMessage="- Decisión de arranque sí/no" />
            </li>
            <li>
              <FormattedMessage id="payroll.parameters-production-p2" defaultMessage="- Arranque (monitoreo)" />
            </li>
            <li>
              <FormattedMessage id="payroll.parameters-production-p3" defaultMessage="- Soporte de producción" />
            </li>
          </CardParameters>
        </div>
      </Container>
      <div className="payroll_parameters__border" />
      <Container className="payroll_parameters__anual">
        <h3>
          <FormattedMessage id="payroll.parameters-annualupdate" defaultMessage="1.2 Actualización Anual" />
        </h3>
        <p>
          <FormattedMessage
            id="payroll.parameters-annualupdate-description"
            defaultMessage="Esta información debe actualizarse, por ley, cada año conforme al ajuste anual de impuestos y leyes de trabajo. Por ello, nuestra área de Cumplimiento legal está siempre actualizada y al tanto sobre cualquier cambio (incluso antes de ser aprobado) en temas de:"
          />
        </p>
        <div className="mt-4">
          <p>
            <span className="fw-bold">
              <FormattedMessage id="payroll.parameters-annualupdate-p1-title" defaultMessage="Tablas de Impuestos: " />
            </span>
            <FormattedMessage
              id="payroll.parameters-annualupdate-p1"
              defaultMessage="Impuesto Sobre la Renta (ISR), Impuesto Sobre la Nómina (ISN) y contribuciones al Seguro Social."
            />
          </p>
          <p>
            <span className="fw-bold">
              <FormattedMessage id="payroll.parameters-annualupdate-p2-title" defaultMessage="Prima de Riesgo de Trabajo: " />
            </span>
            <FormattedMessage
              id="payroll.parameters-annualupdate-p2"
              defaultMessage="Nuevo porcentaje de contribución, sobre el total de la nómina, conforme a los accidentes reportados durante el año."
            />
          </p>
          <p>
            <span className="fw-bold">
              <FormattedMessage id="payroll.parameters-annualupdate-p3-title" defaultMessage="Salario Mínimo: " />
            </span>
            <FormattedMessage
              id="payroll.parameters-annualupdate-p3"
              defaultMessage="Actualización de acuerdo al incremento anual."
            />
          </p>
          <p>
            <span className="fw-bold">
              <FormattedMessage
                id="payroll.parameters-annualupdate-p4-title"
                defaultMessage="Captura del UMA (Unidad de medida y Actualización): "
              />
            </span>
            <FormattedMessage
              id="payroll.parameters-annualupdate-p4"
              defaultMessage="Para cálculos de nómina y descuentos de INFONAVIT."
            />
          </p>
        </div>
      </Container>
      <Container className="payroll_parameters__continue">
        <div className="payroll_parameters__border2" />
      </Container>
      <div className="payroll_parameters__border2-mobile" />
      <Container className="payroll_parameters__continue">
        <h3>
          <FormattedMessage id="payroll.parameters-continuousupdate" defaultMessage="1.3 Actualización Continua" />
        </h3>
        <p className="mb-4">
          <FormattedMessage
            id="payroll.parameters-continuousupdate-p1"
            defaultMessage="Esta es una actualización de su plantilla en nuestro Sistema de Nóminas, que considera cualquier cambio en a su información personal, nuevas contrataciones, bajas, cambios de puesto, incrementos de salario…"
          />
        </p>

        <p>
          <FormattedMessage
            id="payroll.parameters-continuousupdate-p2"
            defaultMessage="La interfaz de entrada a nuestro sistema permite la transferencia automatizada de datos desde las plataformas más prestigiosas de RRHH. Sin embargo, estamos listos para desarrollar nuevas interfaces para cualquier otro sistema y/o recibir información como mejor convenga a su equipo."
          />
        </p>
        <div className="my-4">
          <img src={logoWorkday} alt="logo workday" className="logos" />
          <img src={logoPeopleSoft} alt="logo sap" className="logos" />
          <img src={logoSap} alt="logo people soft" />
        </div>
        <ReturnToServices />
      </Container>
    </section>
  );
};
export default Parameters;
