// Librerias.
import React from "react";

const Button = ({ to, className = "", children, width }) => {
  return (
    <div className={`button-nomi ${className}`}>
      <a href={to} className="btn btn-comp" style={{ width: `${width}px` }}>
        {" "}
        {children}{" "}
      </a>
      {/* {icon && <span>{icon}</span>} */}
    </div>
  );
};
export default Button;
