// Librerias.
import React from "react";
// Components Base.
import NavbarNomi from "./Navbar";
import { Header } from "./styles/HeaderStyles";
const HeaderHero = ({
  uri_bg_d,
  uri_bg_t,
  uri_bg_p,
  className,
  children,
  height,
}) => {
  return (
    <Header
      uri_bg_d={uri_bg_d}
      uri_bg_t={uri_bg_t}
      uri_bg_p={uri_bg_p}
      className={className}
      height={height}
    >
      <NavbarNomi />
      {children}
    </Header>
  );
};
export default HeaderHero;
