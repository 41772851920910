// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
// Componenes CFDI's.
import { TitlePersonalization } from './Titles';
//Asset.
import recibo from './assets/img/recibo.png';
import reciboMobile from './assets/img/recibo-mobile.png';

const Personalization = () => {
  return (
    <section className="cfdis_personalization">
      <Container>
        <TitlePersonalization />
      </Container>
      <div className="text-center">
        <img src={recibo} alt="" className="img-fluid img-desktop" width="1195" height="864" />
        <img src={reciboMobile} alt="" className="img-fluid img-mobile" width="1195" height="864" />
      </div>

      <Container>
        <div className="info-payroll-mobile">
          <div className="info">
            <div className="info__title">
              <FormattedMessage id="cfdis.personalization-ux" defaultMessage="Experiencia del Usuario" />
            </div>
            <div className="info__descrption">
              <FormattedMessage
                id="cfdis.personalization-ux-description"
                defaultMessage="Buscamos brindar al empleado una forma clara y agradable de comunicarle su información personal para evitar dudas y/o aclaraciones posteriores."
              />
            </div>
          </div>
          <div className="info">
            <div className="info__title">
              <FormattedMessage id="cfdis.personalization-commchannel" defaultMessage="Canal de Comunicación" />
            </div>
            <div className="info__descrption">
              <FormattedMessage
                id="cfdis.personalization-commchannel-description"
                defaultMessage="Brindamos una herramienta de comunicación, gestionada por RRHH para comunicarse con los empleados de forma general o individual."
              />
            </div>
          </div>
          <div className="info">
            <div className="info__title">
              <FormattedMessage id="cfdis.personalization-sat" defaultMessage="Cumplimiento SAT" />
            </div>
            <div className="info__descrption">
              <FormattedMessage
                id="cfdis.personalization-sat-description"
                defaultMessage="Cumplimos, conforme a la ley, con la emisión de certificados digitales por internet, así como el resguardo adecuado de los documentos emitidos."
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Personalization;
