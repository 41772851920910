// Librerias.
import React from "react";

// Assets.
import ReturnSvg from "./assets/svg/return.svg";

const ReturnToHome = ({ children }) => {
  return (
    <div className="return_home">
      <a href="/" className="">
        <img src={ReturnSvg} alt="asdfasdf" />
        {children}
      </a>
    </div>
  );
};

export default ReturnToHome;
