import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
//Asset.
import consumptionRecords from './assets/icons-benefits/consumption.png';
import guests from './assets/icons-benefits/guests.png';
import fasterLines from './assets/icons-benefits/faster.png';
import realTime from './assets/icons-benefits/real-time.png';
import clarifications from './assets/icons-benefits/clarifications.png';
import election from './assets/icons-benefits/election.png';

const Benefits = () => {
  return (
    <section className="dicoma_benefits">
      <Container>
        <h1 className="title">
          <FormattedMessage id="dicoma.benefits" defaultMessage="Estos son los beneficios que DICOMA ofrece" />
        </h1>
        <div className="wrapper">
          <div className="item">
            <div className="item__title">
              <img src={consumptionRecords} alt="consumption records icon" className="item__title--icon" />
              <h3>
                <FormattedMessage id="dicoma.benefits-consumptionrecords" defaultMessage="Registro propio de los consumos" />
              </h3>
            </div>
            <p>
              <FormattedMessage
                id="dicoma.benefits-consumptionrecords-description"
                defaultMessage="Cuando el proveedor de comida tiene su sistema de registro e informa a sus clientes cuanto consumieron, siempre quedará la duda de si la información es exacta o no; Con DiCOMA, el cliente indica al proveedor cuánto consumió."
              />
            </p>
          </div>
          <div className="item">
            <div className="item__title">
              <img src={guests} alt="" className="item__title--icon" />
              <h3>
                <FormattedMessage id="dicoma.benefits-guests" defaultMessage="Gestión de invitados y proveedores" />
              </h3>
            </div>
            <p>
              <FormattedMessage
                id="dicoma.benefits-guests-description"
                defaultMessage="Los invitados y contratistas que trabajan en su empresa y comen ahí también tienen cabida en el sistema. El reporte de consumos se filtra para obtener los consumos de los contratistas de determinada empresa, para notificarles los consumos de sus empleados."
              />
            </p>
          </div>
          <div className="item">
            <div className="item__title">
              <img src={fasterLines} alt="" className="item__title--icon" />
              <h3>
                <FormattedMessage id="dicoma.benefits-fasterlines" defaultMessage="Filas más rápidas" />F
              </h3>
            </div>
            <p>
              <FormattedMessage
                id="dicoma.benefits-fasterlines-description"
                defaultMessage="Los empleados disponen de un tiempo limitado para comer y es muy importante que este servicio sea rápido. Con las medidas sanitarias actuales los alimentos se sirven empaquetados, queremos que muchas empresas puedan ofrecer a sus empleados este beneficio."
              />
            </p>
          </div>
          <div className="item">
            <div className="item__title">
              <img src={realTime} alt="" className="item__title--icon" />
              <h3>
                <FormattedMessage id="dicoma.benefits-realtime" defaultMessage="Validación en tiempo real" />
              </h3>
            </div>
            <p>
              <FormattedMessage
                id="dicoma.benefits-realtime-description"
                defaultMessage="La validación que ofrece nuestra aplicación web, funciona en tiempo real y siempre está conectada. El reto fue que pudiera ser en línea y que nos diera el tiempo de respuesta adecuado; estas dos variables combinadas son sin duda una muy buena oferta."
              />
            </p>
          </div>
          <div className="item">
            <div className="item__title">
              <img src={clarifications} alt="" className="item__title--icon" />
              <h3>
                <FormattedMessage id="dicoma.benefits-clarifications" defaultMessage="Menos aclaraciones y/o quejas" />
              </h3>
            </div>
            <p>
              <FormattedMessage
                id="dicoma.benefits-clarifications-description"
                defaultMessage="DiCOMA contribuye a que haya menos quejas y aclaraciones de los empleados, que puedan refutar las fechas o tipos de consumos: pueden ver sus consumos en su aplicación y ya no tienen necesidad de hacer tantas aclaraciones."
              />
            </p>
          </div>
          <div className="item">
            <div className="item__title">
              <img src={election} alt="" className="item__title--icon" />
              <h3>
                <FormattedMessage id="dicoma.benefits-election" defaultMessage="Libre elección de hardware" />
              </h3>
            </div>
            <p>
              <FormattedMessage
                id="dicoma.benefits-election-description"
                defaultMessage="Las empresas pueden elegir el hardware de su preferencia: una computadora, un teléfono celular o una tableta: no están obligadas a adquirir un tipo de hardware que nosotros indiquemos. Un dispositivo con conexión a internet bastará para correr el sistema."
              />
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Benefits;
