// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import reportGeneratorImg from './assets/img/report-generator.jpg';

// Components Payroll.
import { ReturnToServices } from '../components-base';
const ReportGenerator = () => {
  return (
    <section className="payroll_report-generator">
      <Container>
        <h3 className="mb-4">
          <FormattedMessage id="payroll.reportgenerator" defaultMessage="Generador de Reportes" />
        </h3>
        <p className="mb-5">
          <FormattedMessage
            id="payroll.reportgenerator-description"
            defaultMessage="Los miembros de Nóminas / RRHH / Finanzas y/o cualquier otro usuario autorizado podrán extraer fácilmente cualquier información que necesiten de nuestro sistema de nómina, accediendo al módulo Generador de Reportes dentro de nuestra app iNTEGRITY."
          />
        </p>
        <img src={reportGeneratorImg} alt="report generator" className="img-fluid" width={1080} heigth={584} />
        <ReturnToServices />
      </Container>
    </section>
  );
};
export default ReportGenerator;
