// Librerias.
import React from "react";
// Svgs.
import logoWhiteSvg from "./assets/svg/logo-white.svg";
import logoDarkSvg from "./assets/svg/logo-dark.svg";
import katanaLogoPurpleSvg from "./assets/svg/katana-logo-purple.svg";
import iconDropdownSvg from "./assets/svg/icon-dropdown.svg";
import iconDropdownDarkSvg from "./assets/svg/icon-dropdown-dark.svg";
import iconLangSvg from "./assets/svg/icon-lang.svg";
import iconLangDarkSvg from "./assets/svg/icon-lang-dark.svg";
import iconLinkedInSvg from "./assets/svg/icon-linkedin.svg";
import arrowReturnSvg from "./assets/svg/arrow-return.svg";

export const ArrowReturnSvg = () => {
  return <img src={arrowReturnSvg} alt="Arrow Return Icon" />;
};
export const LogoWhiteSvg = ({ classAnchor }) => {
  return (
    <a className={classAnchor} href="/">
      <img src={logoWhiteSvg} alt="Logo de Nomi Blanco" />
    </a>
  );
};
export const LogoDarkSvg = ({ classAnchor }) => {
  return (
    <a className={classAnchor} href="/">
      <img src={logoDarkSvg} alt="Logo de Nomi Oscuro" />
    </a>
  );
};

export const KatanaLogoPurpleSvg = ({ classAnchor }) => {
  return (
    <a className={classAnchor} href="/">
      <img src={katanaLogoPurpleSvg} alt="Logo de Katana Morado" />
    </a>
  );
};

export const IconDropdownSvg = () => {
  return (
    <img width="14" height="8" src={iconDropdownSvg} alt="Icono Dropdown" />
  );
};

export const IconDropdownDarkSvg = () => {
  return (
    <img
      width="14"
      height="8"
      src={iconDropdownDarkSvg}
      alt="Icono Dropdown Dark"
    />
  );
};

export const IconLangSvg = () => {
  return <img width="20" height="20" src={iconLangSvg} alt="Icono Language" />;
};
export const IconLangDarkSvg = () => {
  return (
    <img
      width="20"
      height="20"
      src={iconLangDarkSvg}
      alt="Icono Language Dark"
    />
  );
};

export const IconLinkedInSvg = () => {
  return <img src={iconLinkedInSvg} alt="Icono LinkedIn" />;
};

export const ArrowRotate = ({
  active,
  classType = "icon_rotate_clic",
  showNav,
}) => {
  return (
    <div className={`${classType} d-flex`}>
      <div className="me-1 d-flex align-items-center">
        {showNav ? <IconLangDarkSvg /> : <IconLangSvg />}
      </div>
      <div
        className={`${
          active ? "active" : ""
        } d-flex align-items-center rotate-prueba`}
      >
        {showNav ? <IconDropdownDarkSvg /> : <IconDropdownSvg />}
      </div>
    </div>
  );
};
