// Librerias.
import React from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// Assets.
import usabilityImg from './assets/img/usability.jpg';
import line from './assets/img/line.svg';

const Rules = () => {
  return (
    <section className="integrity_usability">
      <Container>
        <h2>
          <FormattedMessage id="integrity.usability-title" defaultMessage="Usabilidad," />
          <br />
          <FormattedMessage id="integrity.usability-title2ndline" defaultMessage="nuestra prioridad." />
        </h2>

        <div className="img-line">
          <img src={line} alt="linea" />
        </div>
        <p className="description">
          <FormattedMessage
            id="integrity.usability-description"
            defaultMessage="Esta aplicación, como todas las que desarrollamos, busca distinguirse por la manera fácil, rápida y agradable en la que los usuarios pueden completar el proceso para el cual la están usando. Tomamos en cuenta al usuario, el contexto y el problema a resolver para ofrecer la mejor experiencia de uso."
          />
        </p>

        <div className="d-flex justify-content-center img-laptop">
          <img src={usabilityImg} alt="Usability" className="img-fluid"></img>
        </div>
      </Container>
    </section>
  );
};
export default Rules;
