// Librerias.
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Pages.
import PageHome from './page-home';
import PageTheCompany from './page-the-company';
import PageAproach from './page-approach';
import PagePayroll from './page-payroll';
import PageSoftware from './page-software';
import PageMyCompany from './page-my-company';
import PageDicoma from './page-dicoma';
import PageCfdis from './page-cfdis';
import PageIntegrity from './page-integrity';
import PageContact from './page-contact';
import PageContactSales from './page-contact/Sales';
import PageContactSalesSuccess from './page-contact/SalesSuccess';
import PageContactSupport from './page-contact/Support';
import PageContactSupportSuccess from './page-contact/SupportSuccess';
import PageContactTalent from './page-contact/Talent';
import PageContactTalentSuccess from './page-contact/TalentSuccess';
import PagePrivacy from './page-privacy';
import PageError from './page-error';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={PageHome} />
        <Route exact path="/the-company" component={PageTheCompany} />
        <Route exact path="/approach" component={PageAproach} />
        <Route exact path="/payroll" component={PagePayroll} />
        <Route exact path="/software" component={PageSoftware} />
        <Route exact path="/my-company" component={PageMyCompany} />
        <Route exact path="/dicoma" component={PageDicoma} />
        <Route exact path="/cfdis" component={PageCfdis} />
        <Route exact path="/integrity" component={PageIntegrity} />
        <Route exact path="/contact" component={PageContact} />
        <Route exact path="/contact/sales" component={PageContactSales} />
        <Route exact path="/contact/sales/success" component={PageContactSalesSuccess} />
        <Route exact path="/contact/support" component={PageContactSupport} />
        <Route exact path="/contact/support/success" component={PageContactSupportSuccess} />
        <Route exact path="/contact/talent" component={PageContactTalent} />
        <Route exact path="/contact/talent/success" component={PageContactTalentSuccess} />
        <Route exact path="/privacy" component={PagePrivacy} />
        <Route component={PageError} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
