// Librerias.
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';

// Components Base.
import { Header } from '../components-base';

// Assets.
import heroDesktop from './assets/img/hero-bg-d.jpg';
import heroPhone from './assets/img/hero-bg-p.jpg';
import heroArchictecture from './assets/img/hero-architecture.png';

const Hero = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const animation = useAnimation();

  useEffect(() => {
    console.log('use effect hook, inView = ', inView);
    if (inView) {
      animation.start({
        y: 280,
        scale: 0.62,
        transition: { duration: 0.7 },
      });
    }
    if (!inView) {
      animation.start({
        y: 0,
        scale: 1,
        transition: { duration: 0.7 },
      });
    }
  }, [inView]);
  return (
    <Header uri_bg_d={heroDesktop} uri_bg_p={heroPhone} className="software_hero  hero-img position-relative" height={1547}>
      <div className="hero-text">
        <h1 className="text-center software-title">
          <FormattedMessage id="software.hero" defaultMessage="Experiencia, creatividad y tecnología…" />
        </h1>
        <div className="d-flex justify-content-center">
          <h1 className="sub-title">
            <FormattedMessage id="software.hero-bold" defaultMessage="Desarrollo de Software a la Medida" />
          </h1>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <motion.img
          src={heroArchictecture}
          alt="architecture"
          width={590}
          height={580}
          className="img-fluid img-cycle"
          animate={animation}
        />
      </div>
      <div ref={ref} className="referenceAnimationBox"></div>
    </Header>
  );
};
export default Hero;
