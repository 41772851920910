// Librerias.
import React from "react";
// Components Base.
import { Footer } from "../components-base";

// Components Contact.
import Hero from "./Hero";
import Privacy from "./Privacy";

const PagePrivacy = () => {
  return (
    <>
      <Hero />
      <Privacy />
      <Footer />
    </>
  );
};

export default PagePrivacy;
