// Librerias.
import React from 'react';
import { FormattedMessage } from 'react-intl';
// Components Base.
import { Footer, Contact } from '../components-base';
// Components Payroll.
import Hero from './Hero';
import Services from './Services';
import Parameters from './Parameters';
import Interaction from './Interaction';
import Incidents from './Incidents';
import Calculation from './Calculation';
import SpecialProcesses from './SpecialProcesses';
import Communication from './Communication';
import ReportGenerator from './ReportGenerator';
import Restrospective from './Restrospective';
import MyCompany from './MyCompany';

const PagePayroll = () => {
  return (
    <>
      <div className="sections-payroll">
        <Hero />
        <Services />
        <Parameters />
        <Interaction />
        <Incidents />
        <Calculation />
        <SpecialProcesses />
        <Communication />
        <MyCompany />
        <ReportGenerator />
        <Restrospective />
        <Contact
          title={
            <FormattedMessage
              id="payroll.contact"
              defaultMessage="¿Desea hacer más eficiente el flujo de su proceso de nóminas?"
            />
          }
        />
      </div>
      <Footer />
    </>
  );
};

export default PagePayroll;
